import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAdmin } from 'app-models/UsersType'
import { useTranslation } from 'react-i18next'

const getListItems = (data, deleteCallBack) => {
  return data.map((value) => {
    return (
      <tr key={value._id} className='font-sm'>
        <td>{value.name}</td>
        <td>{value.surname}</td>
        <td>{value.email}</td>
        <td>
          <div className='d-flex justify-content-center'>
            <Link
              to={`/employee/edit/${value._id}`}
              className='btn btn-ghost-info'
            >
              <FontAwesomeIcon icon={faUserEdit}/>
            </Link>
            <button
              className='btn btn-ghost-danger'
              type='button'
              onClick={() => deleteCallBack && deleteCallBack(value._id)}
              disabled={isAdmin(value.role)}
            >
              <FontAwesomeIcon icon={faTrashAlt}/>
            </button>
          </div>
        </td>
      </tr>
    )
  })
}

const EmployeesList = ({ employees, deleteCallBack }) => {
  const { t } = useTranslation()

  return (
    <Table borderless responsive className='table-hover'>
      <thead>
        <tr>
          <th>{t('common.labels.employee_first_name')}</th>
          <th>{t('common.labels.employee_second_name')}</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        { getListItems(employees, deleteCallBack) }
      </tbody>
    </Table>
  )
}

export default EmployeesList
