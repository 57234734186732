import gql from 'graphql-tag'

export const setExamTaskAssignment = gql`
  mutation setExamTaskAssignment(
    $examTaskIds: [ID],
    $userId: ID
  ) {
    setExamTaskAssignment(
      examTaskIds: $examTaskIds,
      userId: $userId
    ) {
      status
      examTasksAssignment {
        examTaskIds
        userId
      }
    }
  }
`
