import React from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";

import { getAnswer } from "app-libs/helpers";
import { FormCheckboxField } from "app-components/forms/FormCheckboxField";
import { isAdmin, isEmployee } from "app-models/UsersType";
import { validators } from "app-libs";
import { FormRadioButton } from "app-components/forms/FormRadioButton";
import { useAuthorization } from "app-hooks";

import styles from "./QuestionItem.module.scss";
import AnswersType from "app-models/AnswersType";
import { Label } from "reactstrap";
import Badge from "reactstrap/es/Badge";

const AnswerItem = ({
  idx,
  answer,
  question,
  onRemoveAnswer,
  onAnswerClicked,
  onEditAnswer,
}) => {
  let { type } = question;
  const { user } = useAuthorization();

  type = type.toUpperCase();

  const isTypeOne = (type) => type === AnswersType.ONE_ANSWER;
  const isTypeMultiple = (type) => type === AnswersType.MULTIPLE_ANSWERS;

  const filterControl = (callback) => {
    return isAdmin(user.role) ? callback() : "";
  };

  const onAnswerInputClicked = (e) => {
    onAnswerClicked({
      questionId: question._id,
      answerId: answer._id,
      checked: e.currentTarget.checked,
    });
  };

  const drawCheckbox = (idx, answer) => {
    const label = getAnswer(answer);
    const name = "checkboxAnswer" + idx;
    return (
      <div>
        {isEmployee(user.role) ? (
          <div className="d-flex align-items-center ml-auto">
            <input id={idx} type="checkbox" onChange={onAnswerInputClicked} />
            {label && (
              <Label for={name} className={"ml-1 mt-2"}>
                {label}
              </Label>
            )}
          </div>
        ) : (
          <Field
            name={name}
            label={
              <span>
                {label}
                <small>
                  <Badge
                    className="ml-2 p-1"
                    color="secondary"
                  >{`${answer.type}`}</Badge>
                </small>
                <small>
                  <Badge
                    className="ml-2 p-1"
                    color="secondary"
                  >{`${answer.points}`}</Badge>
                </small>
                {/* {answer.defaultAnswer && (
                  <small>
                    <Badge className="ml-2 p-1" color="success">
                      Default
                    </Badge>
                  </small>
                )} */}
              </span>
            }
            component={FormCheckboxField}
            isEnabled={isEmployee(user.role)}
            validate={[validators.required]}
          />
        )}
      </div>
    );
  };

  const drawRadiobutton = (idx, answer) => {
    const label = getAnswer(answer);
    const name = "radioButtonAnswer";
    return (
      <div>
        {isEmployee(user.role) ? (
          <div className="d-flex align-items-center ml-auto">
            <input
              name={`${question._id}_${name}`}
              type="radio"
              onChange={onAnswerInputClicked}
            />
            {label && (
              <Label for={name} className={"ml-1 mt-2"}>
                {label}
              </Label>
            )}
          </div>
        ) : (
          <Field
            name={"radioButtonAnswer"}
            label={
              <span>
                {`${getAnswer(answer)}`}
                <small>
                  <Badge
                    className="ml-2 p-1"
                    color="secondary"
                  >{`${answer.type}`}</Badge>
                </small>
                <small>
                  <Badge
                    className="ml-2 p-1"
                    color="secondary"
                  >{`${answer.points}`}</Badge>
                </small>
              </span>
            }
            component={FormRadioButton}
            isEnabled={isEmployee(user.role)}
            value={idx}
            parse={Number}
            validate={[validators.required]}
          />
        )}
      </div>
    );
  };

  return (
    <li key={idx} className={styles.questionListItem}>
      <div className="d-flex align-items-center">
        <div>
          {isTypeMultiple(type) ? drawCheckbox(idx, answer) : null}
          {isTypeOne(type) ? drawRadiobutton(idx, answer) : null}
        </div>
        <div className="d-flex align-items-center ml-auto">
          <div className="edit-answer-button">
            {filterControl(() => {
              return (
                <button
                  className="btn btn-ghost-primary"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    onEditAnswer(question, answer);
                  }}
                >
                  <FontAwesomeIcon
                    style={{ pointerEvents: "none" }}
                    icon={faPencilAlt}
                    onClick={() => {}}
                  />
                </button>
              );
            })}
          </div>
          <div className="remove-answer-button">
            {filterControl(() => {
              return (
                <button
                  className="btn btn-ghost-danger"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    onRemoveAnswer(answer, question._id);
                  }}
                >
                  <FontAwesomeIcon
                    style={{ pointerEvents: "none" }}
                    icon={faWindowClose}
                    onClick={() => console.log("123")}
                  />
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
};

export default AnswerItem;
