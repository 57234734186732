import gql from "graphql-tag";

export const updateQuestion = gql`
  mutation updateQuestion(
    $id: ID
    $answers: [AnswerUpdateInputType]
    $companyId: String
    $description: [TextLangInputType]
    $resultExplanation: [TextLangInputType]
    $name: String
    $removeAnswers: RemoveAnswers
    $removeLang: RemoveLang
    $title: [TextLangInputType]
    $type: QuestionTypeEnum
    $calcType: String
  ) {
    updateQuestion(
      _id: $id
      answers: $answers
      companyId: $companyId
      description: $description
      resultExplanation: $resultExplanation
      name: $name
      removeAnswers: $removeAnswers
      removeLang: $removeLang
      title: $title
      type: $type
      calcType: $calcType
    ) {
      status
      question {
        _id
        title {
          lang
          text
        }
        resultExplanation {
          lang
          text
        }
        type
        calcType
        answers {
          _id
          points
          type
          description {
            lang
            text
          }
          resultExplanation {
            lang
            text
          }
          defaultAnswer
          sortOrder
        }
      }
    }
  }
`;
