import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbItem } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { faListAlt, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BasePage } from "app-layouts";
import BreadcrumbCommon from "app-components/BreadcrumbCommon";
import HandlerComponent from "app-components/HandlerComponent";
import TestsList from "app-screens/tests/TestsList";
import { GQL } from "app-gql";
import useDeleteTest from "app-hooks/gql/useDeleteTest";
import { useAuthorization } from "app-hooks";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  isCompanyAdmin,
  isClientCompanyAdmin,
  isEmployee,
  isSuperAdmin,
} from "app-models/UsersType";
import { i18n, restApi } from "app-core";
import { saveAs } from "file-saver";
import { getTestTitle } from "app-libs/helpers";

const Tests = () => {
  const { t } = useTranslation();
  const { user } = useAuthorization();
  const isAdmin = isSuperAdmin(user.role);
  const isCompAdmin = isCompanyAdmin(user.role);
  const isClientCompAdmin = isClientCompanyAdmin(user.role);
  const { loading, error, data } = useQuery(
    isAdmin ? GQL.Test.queries.adminTests : GQL.Test.queries.tests
  );
  const history = useHistory();

  /* DELETE TEST MUTATION */
  const [deleteTest] = useDeleteTest({
    refetchQueries: isAdmin
      ? GQL.Test.queries.adminTests
      : GQL.Test.queries.tests,
  });

  const { data: userData } = useQuery(GQL.User.queries.me);

  const onTestDelete = useCallback(
    async (id) => {
      await deleteTest({ variables: { id, companyId: user.companyId } });
    },
    [deleteTest, user]
  );

  const [updateTest] = useMutation(GQL.Test.mutations.updateTest, {
    onCompleted: ({ updateExamTask }) => {
      if (updateExamTask) {
        setAssignCompanyPopupVisible(false);
      }
    },
    refetchQueries: [
      { query: isAdmin ? GQL.Test.queries.adminTests : GQL.Test.queries.tests },
    ],
  });

  const [assignCompanyPopupVisible, setAssignCompanyPopupVisible] =
    useState(false);
  const [clickedTestId, setClickedTestId] = useState(0);
  const [testToShowInModal, setTestToShowInModal] = useState(null);

  const assignCompanyPopupToggle = () => {
    setAssignCompanyPopupVisible(!assignCompanyPopupVisible);
  };

  const assignCompanyBtnClicked = (testId) => {
    if (isCompanyAdmin(user.role)) {
      history.push("/admin/employees");
      return;
    }
    const preTestsData = isAdmin ? data.examTaskAdminList : data.examTaskList;
    const testData = preTestsData.find((test) => test._id === testId);
    setTestToShowInModal(testData);

    setClickedTestId(testId);
    setAssignCompanyPopupVisible(true);
  };

  const assignCompanySubmitClicked = async (values) => {
    if (updateTest) {
      const infinite = values.repeatType === "infinite";
      const maxCount = infinite ? 1 : parseInt(values.maxCount);

      await updateTest({
        variables: {
          id: clickedTestId,
          companyIds: values.companyIds.map((v) => v.value),
          infinite: infinite,
          maxCount: maxCount,
        },
      });
    }
  };

  const onStartTest = async (testId) => {
    history.push(`/test/start/${testId}`);
  };

  const onSeeResults = async (testId) => {
    history.push(`/test/result/${testId}`);
  };

  const onSeeAllResults = async (testId) => {
    history.push(`/test/results/${testId}`);
  };

  const downloadCsvClicked = (testId) => {
    restApi.ExamTaskRestApiService.downloadExamTaskResultAsCSV(
      testId,
      i18n.instance.language
    ).then((response) => {
      const list = data.examTaskList || data.examTaskAdminList;
      const test = list.find((t) => t._id === testId);

      saveAs(response.data, getTestTitle(test));
    });
  };

  const getShareLinkClicked = (testId) => {
    const shareLink = `${window.location.origin}/test/start/${testId}?c=${userData.me.companyId}`;

    const el = document.createElement("textarea");
    el.value = shareLink;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  let testsData =
    data && (isAdmin ? data.examTaskAdminList : data.examTaskList);
  if (userData && isEmployee(user.role)) {
    testsData = userData.me.assignedExamTasks;
  }

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t("common.page.tests")}</BreadcrumbItem>
        {isAdmin && (
          <li className="breadcrumb-menu ml-auto mr-0">
            <div className="btn-group" role="group">
              <Link to="/tests/create" className="btn btn-transparent">
                <FontAwesomeIcon className="nav-icon" icon={faListAlt} />
                <span className="ml-1">{t("common.create")}</span>
              </Link>
            </div>
          </li>
        )}
        {(isCompAdmin || isClientCompAdmin) && (
          <li className="breadcrumb-menu ml-auto mr-0">
            <div className="btn-group" role="group">
              <Link to="/tests/aggregate_data" className="btn btn-transparent">
                <FontAwesomeIcon className="nav-icon" icon={faUserPlus} />
                <span className="ml-1">{t("tests.create_aggregate_data")}</span>
              </Link>
            </div>
          </li>
        )}
      </BreadcrumbCommon>
      <BasePage title={t("common.page.tests")}>
        <HandlerComponent loading={loading} error={error} />
        {userData && data && (
          <TestsList
            tests={testsData}
            testToShowInModal={testToShowInModal}
            clickedTestId={clickedTestId}
            deleteCallBack={onTestDelete}
            assignCompanyBtnClicked={assignCompanyBtnClicked}
            assignCompanyPopupVisible={assignCompanyPopupVisible}
            assignCompanyPopupToggle={assignCompanyPopupToggle}
            assignCompanySubmitClicked={assignCompanySubmitClicked}
            downloadCsvClicked={downloadCsvClicked}
            getShareLinkClicked={getShareLinkClicked}
            onStartTest={onStartTest}
            onSeeResults={onSeeResults}
            onSeeAllResults={onSeeAllResults}
            questionnaires={userData.me.questionnaires}
          />
        )}
      </BasePage>
    </>
  );
};

export default Tests;
