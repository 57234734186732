import gql from 'graphql-tag'

export const changeUserLocalization = gql`
  mutation ($localization: String!) {
    changeUserLocalization(localization: $localization) {
      status
      data {
        localization
      }
    }
  }
`
