import axios from 'axios'
import { getAuthorizationToken, dispatch } from 'app-core/storeProxy'
import { AuthorizationActions } from 'app-store/modules/authorization'

axios.interceptors.request.use(function (config) {
  const token = getAuthorizationToken()

  if (token) {
    config.headers['Content-Type'] = 'application/json'
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    const { data } = error.response
    if (data.message === 'jwt_expired') {
      dispatch(AuthorizationActions.authorization.signOut())
    }
  } else if (error.request) {
    // server is down
  } else {
    // unknow error
  }
  return Promise.reject(error)
})

export default axios
