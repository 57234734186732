import { SortableContainer } from 'react-sortable-hoc'
import { i18n } from 'app-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import QuestionItem from 'app-screens/tests/components/Questions/QuestionItem'

const SortableQuestions = SortableContainer((
  {
    questions,
    onExportToPdfClick,
    onPrintClick,
    userRole,
    onRemoveAnswer,
    onAddAnswerClick,
    onEditClick,
    onRemoveQuestion,
    onAnswersSortEnd,
    canDownloadAsPDF,
    canPrint,
    onAnswerClicked,
    onEditAnswer
  }) => {
  return (
    <ul className='list-group'>
      <li className='list-group-item list-group-item-secondary'>
        <div className="d-flex align-items-center">
          <div>{i18n.t('tests.question')}</div>
          <div className="d-flex align-items-center ml-auto">
            <div id={'controlsLinks'}>
              {
                canPrint ? (
                  <button
                    style={{ textDecoration: 'none' }}
                    className='text-dark btn btn-link text-nowrap btn-sm' type='button'
                    onClick={onPrintClick}
                  >
                    {i18n.t('tests.printTest')}
                    <FontAwesomeIcon className={'ml-1'} icon={faPrint}/>
                  </button>
                ) : null
              }
              {
                canDownloadAsPDF ? (
                  <button
                    style={{ textDecoration: 'none' }}
                    className='text-dark btn btn-link text-nowrap btn-sm' type='button'
                    onClick={onExportToPdfClick}
                  >
                    {i18n.t('tests.downloadAsPdf')}
                    <FontAwesomeIcon className={'ml-1'} icon={faDownload}/>
                  </button>
                ) : null
              }
            </div>
          </div>
        </div>
      </li>
      {questions.map((question, questionIdx) => <QuestionItem key={question._id} {...{
        questionIdx,
        question,
        userRole,
        onRemoveAnswer,
        onEditClick,
        onAddAnswerClick,
        onRemoveQuestion,
        onSortEnd: onAnswersSortEnd,
        onAnswerClicked,
        onEditAnswer
      }} />)}

    </ul>
  )
})

export default SortableQuestions
