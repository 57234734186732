import React from 'react'
import { FormFeedback, FormGroup, Input, InputGroup, Label } from 'reactstrap'

export const FormGroupTextField = ({
  input,
  placeholder,
  label,
  icon,
  type,
  min,
  max,
  disabled,
  meta: { touched, error }
}) => {
  const invalid = error && touched
  const { name } = input

  const restProps = {
    min,
    max
  }

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <InputGroup>
        {icon && (
          <div className='input-group-prepend'>
            <span className='input-group-text'>
              <i className={`icon-${icon}`} />
            </span>
          </div>
        )}
        <Input className='form-control'
          {...input}
          id={name}
          type={type}
          invalid={invalid}
          placeholder={placeholder}
          disabled={disabled}
          {...restProps}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </InputGroup>
    </FormGroup>
  )
}

export default FormGroupTextField
