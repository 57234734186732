import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { FormGroupTextField } from 'app-components/forms'
import { validators } from 'app-libs'

const ChangePasswordForm = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  error
}) => {
  const { t } = useTranslation()

  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>

      {error && <Alert color='danger'>{error.message}</Alert>}
      <Card className='card-accent-danger'>
        <CardHeader>
          {t('common.labels.change_password')}
        </CardHeader>
        <CardBody>
          <Row className='mt-2'>
            <Col>
              <Field
                name='oldPassword'
                id='oldPassword'
                type='password'
                label={t('common.labels.old_password')}
                labelClasses='text-muted'
                component={FormGroupTextField}
                validate={[validators.required]}
                autoComplete='current-password'
              />
              <Field
                name='password'
                id='password'
                type='password'
                label={t('common.labels.new_password')}
                labelClasses='text-muted'
                component={FormGroupTextField}
                validate={[validators.required]}
                autoComplete='new-password'
              />
              <Field
                name='confirmPassword'
                id='confirmPassword'
                type='password'
                label={t('common.labels.password_confirm')}
                labelClasses='text-muted'
                component={FormGroupTextField}
                validate={[validators.required, validators.passwordsMustMatch]}
                autoComplete='new-password-confirm'
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <FormGroup className='d-flex align-items-center justify-content-between m-0'>
            <Button type='submit' color='danger' disabled={submitting || pristine || invalid}>
              {t('common.labels.change')}
            </Button>
          </FormGroup>
        </CardFooter>
      </Card>
    </Form>
  )
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.object
}

export default reduxForm({
  form: 'ChangePasswordForm'
})(ChangePasswordForm)
