import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Row, Table } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import { validators } from 'app-libs'
import { FormGroupTextField } from 'app-components/forms'

const EditEmployeeForm = (
  {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    error,
    initialValues,
    employee,
    values,
    setFieldValue,
    dirty,
    ...rest
  }
) => {
  const { t } = useTranslation()

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Row form>
          <Col>
            <Card className='card-accent-warning'>
              <CardHeader>{t('common.labels.change_employee_information')}</CardHeader>
              <CardBody>
                <Field
                  name='name'
                  autoComplete='new-name-name'
                  type='text'
                  label={t('common.labels.employee_first_name')}
                  labelClasses='text-muted'
                  component={FormGroupTextField}
                  validate={[validators.required]}
                />
                <Field
                  name='surname'
                  autoComplete='new-surname-name'
                  type='text'
                  label={t('common.labels.employee_second_name')}
                  labelClasses='text-muted'
                  component={FormGroupTextField}
                  validate={[validators.required]}
                />
                <Field
                  name='email'
                  autoComplete='new-email'
                  type='email'
                  label={t('common.labels.email')}
                  labelClasses='text-muted'
                  component={FormGroupTextField}
                  validate={[validators.email, validators.required]}
                />
              </CardBody>
              <CardFooter>
                <FormGroup className='d-flex align-items-center justify-content-between m-0'>
                  <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
                    {t('common.labels.update')}
                  </Button>
                </FormGroup>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

EditEmployeeForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  employee: PropTypes.object
}

export default reduxForm({
  form: 'EditEmployeeForm'
})(EditEmployeeForm)
