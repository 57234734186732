import React from 'react'

import Sidebar from 'app-components/sidebar'

import BaseFooter from './BaseFooter'
import AdminHeader from './BaseHeader'
import SidebarBackdrop from 'app-layouts/SidebarBackdrop'

document.body.classList.add('c-app')

const BaseLayout = ({ children }) => {
  return (
    <div id='root' className='app'>
      <div className='app-body'>
        <Sidebar />
        <div className='c-wrapper'>
          <AdminHeader />
          {children}
        </div>
      </div>
      <SidebarBackdrop />
      <BaseFooter />
    </div>
  )
}

export default BaseLayout
