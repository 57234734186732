export default {
  common: {
    labels: {
      edit_test: "Edit test",
      delete_test: "Delete test",
      check_results: "Check results",
      generate_report: "Generate report",
      donwload_csv_file: "Download CSV file",
      assign_companies: "Assign companies",
      edit_test_questions: "Edit questions",
      companies: "Companies",
      algorithm: "Algorithm",
      addressees: "Addressees",
      max_repeat_count: "Max repeat count",
      attempts: "Attempts",
      actions: "Actions",
      email: "E-mail",
      id: "ID",
      type: "Type",
      status: "Status",
      name: "Name",
      account_type: "Account type",
      account_status: "Account status",
      account_details: "Account Details",
      account_name: "Account name",
      password: "Password",
      old_password: "Old password",
      new_password: "New Password",
      password_confirm: "Confirm password",
      password_confirm_new: "Confirm your new password",
      signin: "Sign In to your account",
      signup: "Sign Up",
      back_to_home: "Back to home",
      logout: "Logout",
      authorize: "Authorize",
      save_changes: "Save changes",
      update: "Update",
      generatePdf: "Generate PDF",
      savePdf: "Save PDF?",
      savePdfOnDisk: "Save PDF",
      edit_user: "Edit User",
      user_details: "User details",
      role: "Role",
      create_user: "CreateCompany user",
      create: "Create",
      delete: "Delete",
      reset_password: "Reset your password",
      set_new_password: "Set new password",
      account_name_placeholder: "Enter an Account name...",
      email_placeholder: "Enter an E-mail...",
      user_name: "User Name",
      user_second_name: "User Second Name",
      employee_first_name: "First Name",
      employee_second_name: "Second Name",
      close: "Close",
      change: "Change",
      change_password: "Change Password",
      change_user_information: "Change User Information",
      change_employee_information: "Change Employee Information",
      filter: "Filter",
      reset: "Reset",
      admin: "Admin",
      employee: "Employee",
      company_admin: "Company admin",
      fileSelect: "Select file",
      value: "Value",
      command: "Command",
      language: "Profile language",
      country: "Country",
      region: "Region",
      province: "Province",
      zip: "ZIP code",
      address: "Address",
      title: "Title",
      description: "Description",
      resultExplanation: "Result explanation",
      locale: {
        en: "English",
        it: "Italian",
      },
      empty: "Empty",
      passwordChangedSuccess: "Password successfully changed!",
      company: {
        text: "Company",
        companyToAdministrate: "Company to administrate",
        selectCompany: "Select a Company",
        name: "Company Name",
        address: "Company Address",
        email: "Email",
        phone: "Phone",
        contact_name: "Contact Name",
        admins: "Company admins",
        companyLogo: "Company Logo",
        permissions: {
          name: "Permissions",
          login: "Login permitted",
        },
        my_company: "My company",
      },
      assign: "Assign",
      questionObjective: {
        concezione_dell_obiettivo: "Concezione del ruolo",
        comp_anticipazione: "Competenza: Anticipazione degli aspetti critici",
        comp_pianificazione: "Competenza: Pianificazione",
        comp_gest_asp_crit_error: "Competenza: Analisi e gestione degli errori",
        comp_interattiva_comunicativa: "Competenza: Interattiva Comunicativa",
        comp_gestione_lavoro_di_squadra:
          "Competenza: Gestione del lavoro di squadra",
        comp_conc_svilu: "Competenza: Concezione sviluppo professionale",
        comp_gestione_collocazione_dei_ruoli:
          "Competenza: Collocazione dei ruoli dell’organizzazione in relazione agli obiettivi",
        comp_generazione_della_cultura_di_squadra:
          "Competenza: Generazione di una cultura di squadra",
        competenza_di_analisi_del_contesto: "Competenza: Analisi del contesto",
        competenza_di_valutazione:
          "Competenza: Valutazione per il decision making",
      },
      responseOptions: {
        punto_di_forza: "Strenght point",
        punto_potenziale: "Potential point",
        punto_debole: "Weak point",
        ob_proc_resp_alta: "OB-PROC/RESP.ALTA",
        funzionale_resp_alta: "FUNZIONALE/RESP ALTA",
        funzionale_resp_bassa: "FUNZIONALE(RESP BASSA)",
        burocratico_resp_alta: "BUROCRATICO (RESP ALTA)",
        burocratico_resp_bassa: "BUROCRATICO (RESP BASSA)",
      },
      responseOptionsLabels: {
        punto_di_forza: "Strenght point",
        punto_potenziale: "Potential point",
        punto_debole: "Weak point",
        ob_proc_resp_alta: "BLUE",
        funzionale_resp_alta: "GREEN",
        funzionale_resp_bassa: "ORANGE",
        burocratico_resp_alta: "YELLOW",
        burocratico_resp_bassa: "RED",
      },
      responseOptionsColors: {
        ob_proc_resp_alta: "rgba(0, 0, 255, 0.2)",
        funzionale_resp_alta: "rgba(128, 255, 0, 0.2)",
        funzionale_resp_bassa: "rgba(255, 128, 0, 0.2)",
        burocratico_resp_alta: "rgba(255, 255, 0, 0.2)",
        burocratico_resp_bassa: "rgba(255, 0, 0, 0.2)",
      },
      strength: "Strength",
      loading: "Loading",
      totalPoints: "Total points",
      testResults: "Test results",
      employerResults: {
        title: "test title",
        performedAt: "Performed At",
        performedBy: "Performed By",
        status: "status",
        result: "result",
        view: "view",
      },
    },
    create: "Create",
    submit: "Submit",
    page: {
      admin: {
        dashboard: "Dashboard",
        users: "Users List",
        employees: "Employees List",
        add_new_user: "Add New User",
        add_new_employee: "Add New Employee",
        update_user: "Edit User",
        update_employee: "Edit Employee",
        edit_user: "Edit User",
        companies_list: "Companies list",
        create: "Create",
        update: "Update",
        edit: "Edit",
        create_company: "Create Company",
        edit_company: "Edit Company",
      },
      tests: "Tests",
      addNewTest: "Add new test",
    },
  },
  tests: {
    aggregateData: {
      labels: {
        download: "Download",
        dates: "Dates",
        examsList: "Exam list",
        userGroups: "User groups",
        userTests: "User tests",
        sections: "Sections",
        from: "From",
        to: "To",
        hideDescriptions: "Nascondi descrizioni",
      },
      sections: {
        concezione_dell_obiettivo: "Concezione dell'obiettivo",
        comp_anticipazione: "Comp. Anticipazione",
        comp_pianificazione: "Comp. Pianificazione",
        comp_gest_asp_crit_error: "Comp. Gest Asp Crit Error",
        comp_interattiva_comunicativa: "Comp. Interattiva Comunicativa",
        comp_gestione_lavoro_di_squadra: "Comp Gestione lavoro di squadra",
        comp_conc_svilu: "Comp. Conc Svilu",
        comp_gestione_collocazione_dei_ruoli:
          "Comp. Gestione collocazione dei ruoli",
        comp_generazione_della_cultura_di_squadra:
          "Comp. Generazione della cultura di squadra",
        competenza_di_analisi_del_contesto:
          "Competenza di analisi del contesto",
        competenza_di_valutazione: "Competenza di valutazione",
      },
    },
    form: {
      info: "Specify locales for title and description",
    },
    addQuestion: "Add question",
    addAnswer: "Add answer",
    editAnswer: "Edit answer",
    editQuestion: "Edit",
    deleteQuestion: "Delete question",
    create_aggregate_data: "Crea aggregate data",
    question: "Questions",
    startTest: "Start test",
    createFirstQuestion: "No questions. Create your first question",
    shuffleTestQuestions: "Shuffle test questions and answers",
    doNotShowResults: "Do not show results to employees",
    type: {
      one: "One",
      multiple: "Multiple",
      text: "Text",
    },
    selectQuestionType: "Select question type",
    selectQuestionObjective: "Select question objective",
    answerType: "Answer type",
    selectQuestionAlgorithms: "Select question algorithm",
    selectAlgorithm: "Select algorithm",
    algorithmUserGroup: "Select users group",
    downloadAsPdf: "Download as PDF",
    printTest: "Print test",
    assignCompaniesToTest: "Select companies to be assigned to the Test",
    assignEmployeesToGroup: "Select employees to be assigned to the Group",
    assignTestsToGroup: "Select Tests to be assigned to the Group",
    groups: "Groups",
    assignTests: "Assign Tests",
    assignTo: "Assign To",
    copyShareLink: "Copy share link",
    repeatTypeInfinite: "Infinite",
    repeatTypeMaxCount: "Max Count",
    selectTestRepeatType: "Select Test repeat type",
    companyTests: "Company Tests",
    checkResults: "Check results",
    testResults: "Test results",
    aggregatedData: "Aggregated data",
    status: {
      init: "Init",
      inProgress: "In progress",
      finished: "Finished",
    },
    editTest: "Edit test",
    createTestGroup: "Create Test Group",
    selectEmployees: "Select Employees",
    selectTests: "Select Tests",
    noCompaniesAssign: "No companies",
    resetCount: "Reset count",
    whatWeMean: "What we mean?",
    yourEvaluation: "Your evaluation",
    inherited: "Inherithed by group",
    assignedDirectly: "Directly assigned",
    notAssigned: "Not assigned",
    assignDirectly: "Assign directly",
    remove: "Remove",
  },
  sidebar: {
    menu: {
      dashboard: "Dashboard",
      users: "Users",
      usersList: "Users List",
      employees: "Employees",
      employeesList: "Employees List",
      company: "Company",
      companies_list: "Companies List",
      company_edit: "Edit Company",
      tests: "Tests",
      testsList: "Tests List",
      testsGroups: "Tests Groups",
    },
  },
  auth: {
    congratulations: "Congratulations!",
    forgot_password: "Forgot password?",
    not_a_member: "Not a member?",
    already_member: "Already has an account?",
    sign_in: "Sign in",
    reset_password_text: "You can reset your password here",
    activation_success:
      "Your account has been activate. Now you can sign in to the app",
    password_reset_sent_text_1:
      "You will receive an email with instructions about how to reset your password.",
    password_reset_sent_text_2: "Follow them to create a new one",
    password_choose_new: "Choose new password",
    change_password: "Change password",
    change_password_gratz: "Congratulations!",
    password_was_changed_text:
      "Now you can login to the app using your new password",
    activateNewPassword: "You can set your new password here",
    activationSuccess:
      "You have successfully set you new password. Now you can login",
  },
  dialog: {
    warning_title: "Warning",
    delete_confirm: "Do you really want to delete this?",
    confirm_text: "Confirm",
    cancel_text: "Cancel",
    change_locale_title: "Change localization",
  },
  screens: {
    login: {
      title: "Login",
      text: "Sign In to your account",
      no_account: "No account?",
      form: {
        email: {
          label: "Email",
          placeholder: "Email",
        },
        password: {
          label: "Password",
          placeholder: "Password",
        },
        button: {
          signIn: "Login",
          forgotPassword: "Forgot Password?",
        },
      },
    },
    signup: {
      text: "Sign Up",
    },
    employee: {
      import_csv: "Import from xlsx",
      import_csv_alert_title: "Import Employees from xlsx file",
    },
  },
  alerts: {
    ok: "Success",
    error: "Error",
    errors: "Error",
    warning: "Warning",
    invalid_questionnaire:
      "You have selected incompatible answers. Please repeat the test by reading questions carefully.",
    cannot_edit_questionnaire: "Cannot edit this questionnaire",
  },
};
