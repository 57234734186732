import gql from 'graphql-tag'
import { Apollo } from 'app-core'

export const meQuery = gql`
  query me {
    me {
      _id
      name
      surname
      role
      status
      email
      companyId
      company {
        name
        companyLogo
      }
      privacy {
        users
        companies
        tests
        examtask
        questions
      }
      localization
    }
  }
`

export default async () => {
  return await Apollo.query({ query: meQuery })
}
