import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BasePage } from 'app-layouts'
import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import HandlerComponent from 'app-components/HandlerComponent'

import UsersList from './UsersList'
import useFetchUsers from 'app-hooks/gql/useFetchUsers'
import useDeleteUser from 'app-hooks/gql/useDeleteUser'
import { GQL } from 'app-gql'
import UsersType from 'app-models/UsersType'

const Users = () => {
  const { t } = useTranslation()
  const { loading, error, data } = useFetchUsers()

  const [deleteUser] = useDeleteUser({
    refetchQueries: [
      {
        query: GQL.User.queries.usersList,
        variables: { filterRole: [UsersType.ADMIN, UsersType.COMPANY_ADMIN] }
      }]
  })

  const onUserDelete = useCallback(async (userID) => {
    await deleteUser({ variables: { id: userID } })
  }, [deleteUser])

  return (
    <React.Fragment>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.users')}</BreadcrumbItem>
        <li className='breadcrumb-menu ml-auto mr-0'>
          <div className='btn-group' role='group'>
            <Link to='/user/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faUserPlus} />
              <span className='ml-1'>{t('common.create')}</span>
            </Link>
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.users')}>
        <HandlerComponent loading={loading} error={error} />
        {data && <UsersList users={data.usersList} deleteCallBack={onUserDelete} />}
      </BasePage>
    </React.Fragment>
  )
}

export default Users
