import gql from 'graphql-tag'

export const deleteUser = gql`
  mutation deleteUser($id: String! ) {
    deleteUser(id: $id) {
      status
    }
  }
`

