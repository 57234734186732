export default {
  common: {
    labels: {
      edit_test: "Modifica test",
      delete_test: "Cancella test",
      check_results: "Controlla risultati",
      generate_report: "Genera report",
      donwload_csv_file: "Scarica file CSV",
      assign_companies: "Assegna aziende",
      edit_test_questions: "Modifica domande",
      companies: "Aziende",
      algorithm: "Algoritmo",
      addressees: "Destinatari",
      max_repeat_count: "Massime ripetizioni",
      attempts: "Tentativi",
      actions: "Azioni",
      email: "E-mail",
      id: "ID",
      type: "Genere",
      status: "Stato",
      name: "Nome",
      account_type: "Tipo di account",
      account_status: "Stato dell'account",
      account_details: "Dettagli account",
      account_name: "Nome utente",
      password: "Password",
      old_password: "Vecchia password",
      new_password: "Nuova password",
      password_confirm: "Conferma password",
      password_confirm_new: "Conferma la tua nuova password",
      signin: "Accedi al tuo account",
      signup: "Iscriviti",
      back_to_home: "Indietro",
      logout: "Disconnetti",
      authorize: "Autorizzare",
      save_changes: "Salvare le modifiche",
      update: "Aggiorna",
      generatePdf: "Genera PDF",
      savePdf: "Salva PDF?",
      savePdfOnDisk: "Salva PDF",
      edit_user: "Modifica utente",
      user_details: "Dettagli dell'utente",
      role: "Ruolo",
      create_user: "Crea un utente",
      create: "Crea",
      delete: "Elimina",
      reset_password: "Reimposta la tua password",
      set_new_password: "Imposta nuova password",
      account_name_placeholder: "Inserisci un nome account...",
      email_placeholder: "Inserisci un'e-mail...",
      user_name: "Nome",
      user_second_name: "Cognome",
      employee_first_name: "Nome",
      employee_second_name: "Cognome",
      close: "Chiudi",
      change: "Modifica",
      change_password: "Cambia la password",
      change_user_information: "Modifica le informazioni dell'utente",
      change_employee_information: "Modifica le informazioni del dipendente",
      filter: "Filtro",
      reset: "Ripristina",
      admin: "Admin",
      employee: "Dipendente",
      company_admin: "Amministratore dell'azienda",
      fileSelect: "Seleziona il file",
      value: "Valore",
      command: "Comando",
      language: "Lingua del profilo",
      country: "Nazione",
      region: "Regione",
      province: "Provincia",
      zip: "Cap",
      address: "Indirizzo",
      title: "Titolo",
      description: "Descrizione",
      resultExplanation: "Result explanation",
      locale: {
        en: "Inglese",
        it: "Italiano",
      },
      empty: "Vuoto",
      passwordChangedSuccess: "Password cambiata con successo!",
      company: {
        text: "Azienda",
        companyToAdministrate: "Azienda da amministrare",
        selectCompany: "Seleziona una società",
        name: "Nome dell'azienda",
        address: "Indirizzo azienda",
        email: "Email",
        phone: "Telefono",
        contact_name: "Nome del contatto",
        admins: "Amministratori dell'azienda",
        companyLogo: "Logo dell'azienda",
        permissions: {
          name: "Permessi",
          login: "Accesso consentito",
        },
        my_company: "La mia azienda",
      },
      assign: "Assegnare",
      questionObjective: {
        concezione_dell_obiettivo: "Concezione del ruolo",
        comp_anticipazione: "Competenza: Anticipazione degli aspetti critici",
        comp_pianificazione: "Competenza: Pianificazione",
        comp_gest_asp_crit_error: "Competenza: Analisi e gestione degli errori",
        comp_interattiva_comunicativa: "Competenza: Interattiva Comunicativa",
        comp_gestione_lavoro_di_squadra:
          "Competenza: Gestione del lavoro di squadra",
        comp_conc_svilu: "Competenza: Concezione sviluppo professionale",
        comp_gestione_collocazione_dei_ruoli:
          "Competenza: Collocazione dei ruoli dell’organizzazione in relazione agli obiettivi",
        comp_generazione_della_cultura_di_squadra:
          "Competenza: Generazione di una cultura di squadra",
        competenza_di_analisi_del_contesto: "Competenza: Analisi del contesto",
        competenza_di_valutazione:
          "Competenza: Valutazione per il decision making",
      },
      responseOptions: {
        punto_di_forza: "Punto di forza",
        punto_potenziale: "Punto potenziale",
        punto_debole: "Punto debole",
        ob_proc_resp_alta: "OB-PROC/RESP.ALTA",
        funzionale_resp_alta: "FUNZIONALE/RESP ALTA",
        funzionale_resp_bassa: "FUNZIONALE(RESP BASSA)",
        burocratico_resp_alta: "BUROCRATICO (RESP ALTA)",
        burocratico_resp_bassa: "BUROCRATICO (RESP BASSA)",
      },
      responseOptionsLabels: {
        punto_di_forza: "Punto di forza",
        punto_potenziale: "Punto potenziale",
        punto_debole: "Punto debole",
        ob_proc_resp_alta: "BLU",
        funzionale_resp_alta: "VERDE",
        funzionale_resp_bassa: "ARANCIONE",
        burocratico_resp_alta: "GIALLO",
        burocratico_resp_bassa: "ROSSO",
      },
      responseOptionsColors: {
        ob_proc_resp_alta: "rgba(0, 0, 255, 0.2)",
        funzionale_resp_alta: "rgba(128, 255, 0, 0.2)",
        funzionale_resp_bassa: "rgba(255, 128, 0, 0.2)",
        burocratico_resp_alta: "rgba(255, 255, 0, 0.2)",
        burocratico_resp_bassa: "rgba(255, 0, 0, 0.2)",
      },
      strength: "Forza",
      loading: "Loading",
      totalPoints: "Punteggio",
      print: "Stampa",
      testResults: "Risultato test",
      employerResults: {
        title: "Titolo",
        performedAt: "Compilato il",
        performedBy: "Compilato da",
        status: "Stato",
        result: "Risultato",
        view: "Vedi",
      },
    },
    create: "Crea",
    submit: "Invia",
    page: {
      admin: {
        dashboard: "Pannello di controllo",
        users: "Elenco utenti",
        employees: "Elenco dipendenti",
        add_new_user: "Aggiungi nuovo utente",
        add_new_employee: "Aggiungi nuovo dipendente",
        update_user: "Modifica utente",
        update_employee: "Modifica dipendente",
        edit_user: "Modifica utente",
        companies_list: "Elenco delle aziende",
        create: "Crea",
        update: "Aggiorna",
        edit: "Modifica",
        create_company: "Crea azienda",
        edit_company: "Modifica azienda",
      },
      tests: "Test",
      addNewTest: "Aggiungi nuovo test",
    },
  },
  tests: {
    aggregateData: {
      labels: {
        download: "Scarica",
        dates: "Date",
        examsList: "Lista test",
        userGroups: "Gruppi utenti",
        userTests: "Test utenti",
        sections: "Sezioni",
        from: "Data inizio",
        to: "Data fine",
        hideDescriptions: "Nascondi descrizioni",
      },
      sections: {
        concezione_dell_obiettivo: "Concezione dell'obiettivo",
        comp_anticipazione: "Comp. Anticipazione",
        comp_pianificazione: "Comp. Pianificazione",
        comp_gest_asp_crit_error: "Comp. Gest Asp Crit Error",
        comp_interattiva_comunicativa: "Comp. Interattiva Comunicativa",
        comp_gestione_lavoro_di_squadra: "Comp Gestione lavoro di squadra",
        comp_conc_svilu: "Comp. Conc Svilu",
        comp_gestione_collocazione_dei_ruoli:
          "Comp. Gestione collocazione dei ruoli",
        comp_generazione_della_cultura_di_squadra:
          "Comp. Generazione della cultura di squadra",
        competenza_di_analisi_del_contesto:
          "Competenza di analisi del contesto",
        competenza_di_valutazione: "Competenza di valutazione",
      },
    },
    form: {
      info: "Imposta il titolo e la descrizione",
    },
    addQuestion: "Aggiungi una domanda",
    addAnswer: "Aggiungi una risposta",
    editAnswer: "Modifica risposta",
    editQuestion: "Modificare",
    deleteQuestion: "Rimuovi la domanda",
    question: "Domande",
    startTest: "Inizia il test",
    create_aggregate_data: "Crea dato aggregato",
    createFirstQuestion: "Nessuna domanda, crea la prima.",
    shuffleTestQuestions: "Mescola le domande",
    doNotShowResults: "Non mostrare i risultati ai dipendenti",
    type: {
      one: "Una riposta",
      multiple: "Multipla",
      text: "Testo",
    },
    selectQuestionType: "Seleziona un tipo di domanda",
    selectQuestionObjective: "Seleziona l'obiettivo della domanda",
    answerType: "Tipo di risposta",
    selectQuestionAlgorithms: "Seleziona l'algoritmo della domanda",
    selectAlgorithm: "Seleziona algoritmo",
    algorithmUserGroup: "Seleziona il gruppo di utenti",
    downloadAsPdf: "Scarica come as PDF",
    printTest: "Prova di stampa",
    assignCompaniesToTest: "Seleziona aziende da assegnare al test",
    assignEmployeesToGroup: "Seleziona dipendenti da assegnare al gruppo",
    assignTestsToGroup: "Seleziona test da assegnare al gruppo",
    groups: "Gruppi",
    assignTests: "Assegna test",
    assignTo: "Assegna a",
    copyShareLink: "Copia link al test",
    repeatTypeInfinite: "Infiniti",
    repeatTypeMaxCount: "Numero massimo",
    selectTestRepeatType: "Seleziona ripetizione test",
    companyTests: "Test aziendali",
    checkResults: "Controlla risultati",
    aggregatedData: "Dati aggregati",
    testResults: "risultati dei test",
    status: {
      init: "Inizio",
      inProgress: "In lavorazione",
      finished: "Finito",
    },
    editTest: "Modifica test",
    createTestGroup: "Crea un gruppo dipendenti",
    selectEmployees: "Seleziona dipendenti",
    selectTests: "Seleziona test",
    noCompaniesAssign: "Nessuna azienda",
    resetCount: "Reimposta conteggio",
    whatWeMean: "Cosa intendiamo?",
    yourEvaluation: "La Sua valutazione",
    inherited: "Ereditato dal gruppo",
    assignedDirectly: "Assegnato direttamente",
    notAssigned: "Non assegnato",
    assignDirectly: "Assegna direttamente",
    remove: "Rimuovi",
  },
  sidebar: {
    menu: {
      dashboard: "Pannello di controllo",
      users: "Utenti",
      usersList: "Elenco utenti",
      employees: "Dipendenti",
      employeesList: "Elenco dipendenti",
      company: "Azienda",
      companies_list: "Elenco delle aziende",
      company_edit: "Modifica azienda",
      tests: "Test",
      testsList: "Elenco dei test",
      testsGroups: "Gruppi di utenti",
    },
  },
  auth: {
    congratulations: "Congratulazioni!",
    forgot_password: "Ha dimenticato la password?",
    not_a_member: "Non sei registrato?",
    already_member: "Hai già un account?",
    sign_in: "Registrati",
    reset_password_text: "Puoi reimpostare la password qui",
    activation_success:
      "Il tuo account è stato attivato. Ora puoi accedere all'app",
    password_reset_sent_text_1:
      "Riceverai un'email con le istruzioni su come reimpostare la password.",
    password_reset_sent_text_2: "Segui per crearne uno nuovo",
    password_choose_new: "Scegli una nuova password",
    change_password: "Cambia la password",
    change_password_gratz: "Congratulazioni!",
    password_was_changed_text:
      "Ora puoi accedere all'app usando la tua nuova password",
    activateNewPassword: "Puoi impostare la tua nuova password qui",
    activationSuccess:
      "Hai impostato con successo la nuova password. Adesso puoi effettuare il login",
  },
  dialog: {
    warning_title: "Avviso",
    delete_confirm: "Vuoi veramente cancellarlo?",
    confirm_text: "Conferma",
    cancel_text: "Annulla",
    change_locale_title: "Cambia localizzazione",
  },
  screens: {
    login: {
      title: "Accesso",
      text: "Accedi al tuo account",
      no_account: "Nessun account?",
      form: {
        email: {
          label: "Email",
          placeholder: "Email",
        },
        password: {
          label: "Password",
          placeholder: "Password",
        },
        button: {
          signIn: "Accedi",
          forgotPassword: "Ha dimenticato la password?",
        },
      },
    },
    signup: {
      text: "Iscriviti",
    },
    employee: {
      import_csv: "Importa da xlsx",
      import_csv_alert_title: "Importa dipendenti da file xlsx",
    },
  },
  alerts: {
    ok: "Successo",
    error: "Errore",
    errors: "Errore",
    warning: "Attenzione",
    invalid_questionnaire:
      "Hai selezionato delle risposte incompatibili. Ripeti la prova leggendo attentamente le domande.",
    cannot_edit_questionnaire: "Non puoi compilare questo questionario",
  },
};
