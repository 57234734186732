import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Button, Col, Form, FormGroup, Row } from 'reactstrap'

import { i18n } from 'app-core'
import { Heading } from 'app-components'
import { FormTextField } from 'app-components/forms'
import { required } from 'app-libs/validators'

const ResetForm = (
  {
    handleSubmit,
    pristine,
    invalid,
    submitting
  }
) => {
  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
      <Heading size={3}>{i18n.t('auth.reset_password_text')}</Heading>
      {/*{error && <Alert color='danger'>{error.message}</Alert>}*/}

      <Row className='mt-2'>
        <Col>
          <Field
            name='password'
            id='password'
            type='password'
            icon='lock'
            placeholder={i18n.t('common.labels.new_password')}
            labelClasses='text-muted'
            component={FormTextField}
            validate={[required]}
          />
          <Field
            name='confirmPassword'
            id='confirmPassword'
            type='password'
            icon='lock'
            placeholder={i18n.t('common.labels.password_confirm')}
            labelClasses='text-muted'
            component={FormTextField}
            validate={[required]}
          />

          <FormGroup className='d-flex align-items-center justify-content-between m-0'>
            <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
              {i18n.t('common.labels.reset_password')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default reduxForm({
  form: 'ResetForm'
})(ResetForm)
