import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import { AVAILABLE_LOCALIZATIONS } from 'app-models/localizations'
import FormSelectField from 'app-components/forms/FormSelectField'
import { validators } from 'app-libs'
import { FormGroupTextField } from 'app-components/forms'

const EditUserForm = (
  {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    error,
    initialValues,
    user,
    values,
    setFieldValue,
    dirty,
    companiesList,
    ...rest
  }
) => {
  const { t } = useTranslation()

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Card className='card-accent-warning'>
          <CardHeader>{t('common.labels.change_user_information')}</CardHeader>
          <CardBody>
            <Field
              name='name'
              autoComplete='new-name'
              type='text'
              label={t('common.labels.user_name')}
              labelClasses='text-muted'
              component={FormGroupTextField}
              validate={[validators.required]}
            />
            <Field
              name='surname'
              autoComplete='new-surname'
              type='text'
              label={t('common.labels.user_second_name')}
              labelClasses='text-muted'
              component={FormGroupTextField}
              validate={[validators.required]}
            />
            {companiesList &&
            <Field
              name='companyId'
              label={t('common.labels.company.companyToAdministrate')}
              labelClasses='text-muted'
              mapping={companiesList.map(company => {
                return {
                  label: company.name,
                  value: company._id
                }
              })}
              component={FormSelectField}
              isMulti={false}
              validate={[validators.required]}
            />
            }
            <Field
              name='email'
              autoComplete='new-email'
              type='email'
              label={t('common.labels.email')}
              labelClasses='text-muted'
              component={FormGroupTextField}
              validate={[validators.email, validators.required]}
            />
            <Field
              name='localization'
              label={t('common.labels.language')}
              labelClasses='text-muted'
              mapping={AVAILABLE_LOCALIZATIONS.map(locale => {
                return {
                  label: String(t(`common.labels.locale.${locale}`)),
                  value: locale
                }
              })}
              component={FormSelectField}
              isMulti={false}
              validate={[validators.required]}
            />
          </CardBody>
          <CardFooter>
            <FormGroup className='d-flex align-items-center justify-content-between m-0'>
              <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
                {t('common.labels.update')}
              </Button>
            </FormGroup>
          </CardFooter>
        </Card>
      </Form>
    </>
  )
}

EditUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.object,
  initialValues: PropTypes.object,
  user: PropTypes.object
}

export default reduxForm({
  form: 'EditUserForm'
})(EditUserForm)
