import gql from 'graphql-tag'

export const usersList = gql`
  query usersList(
    $filterRole: [RoleEnum],
    $assignedExamTasks: AssignedExamTasksInUserInputType
  ){
    usersList(
      assignedExamTasks: $assignedExamTasks,
      filterRole: $filterRole
    ) {
      _id
      name
      surname
      email
      status
      role
      companyId
      company {
        name
      }
      questionnaires {
        _id
        title {
            lang
            text
        }
        completedAt
        parentId
        resultPoints
        status
      }
    }
  }
`
