import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem, CardBody } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'

import { BasePage } from 'app-layouts'
import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import HandlerComponent from 'app-components/HandlerComponent'
import { GQL } from 'app-gql'
import { useMutation, useQuery } from '@apollo/react-hooks'
import useFetchEmployees from 'app-hooks/gql/useFetchEmployees'
import TestGroupForm from 'app-screens/tests/groups/TestGroupForm'
import { buildTextLangInputType, getTestTitle } from 'app-libs/helpers'

const EditTestGroup = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()

  let groupToEdit = null

  const { loading: loadingGroup, error: groupError, data: groups } = useQuery(
    GQL.Test.queries.examTaskGroupAssignmentList,
    {
      variables: {
        ids: [id]
      }
    })
  const { loading: loadingEmployee, error: employeeError, data: employees } = useFetchEmployees()
  const { loading: loadingTests, error: testsError, data: tests } = useQuery(GQL.Test.queries.tests)

  const [updateTestGroup] = useMutation(GQL.Test.mutations.updateExamTaskGroupAssignment, {
    onCompleted: ({ updateExamTaskGroupAssignment }) => {
      if (updateExamTaskGroupAssignment) {
        const { status } = updateExamTaskGroupAssignment

        status === 'ok' && history.push('/test/groups')
      }
    },
    refetchQueries: [
      {
        query: GQL.Test.queries.examTaskGroupAssignmentList,
        variables: {
          ids: [id]
        }
      }
    ]
  })

  const handleSubmit = useCallback(async (values) => {
    if (updateTestGroup) {
      await updateTestGroup({
        variables: {
          id: id,
          description: {
            addOrUpdate: buildTextLangInputType(values.description)
          },
          examTaskIds: values.testsList.map(t => t.value),
          title: {
            addOrUpdate: buildTextLangInputType(values.title)
          },
          userIds: values.employeesList.map(t => t.value)
        }
      })
    }
  }, [updateTestGroup])

  if (groups) {
    groupToEdit = groups.examTaskGroupAssignmentList.find(g => g._id === id)
  }

  const employeesOptions = useMemo(() => {
    if (employees) {
      return employees.usersList.map(({ _id, name, surname }) => ({ value: _id, label: name + ' ' + surname }))
    }
    return []
  }, [employees])

  const testsOptions = useMemo(() => {
    if (tests) {
      return tests.examTaskList.map(test => ({ value: test._id, label: getTestTitle(test) }))
    }
    return []
  }, [tests])

  const formInitialProps = useMemo(() => {
    if (!groupToEdit || !employees || !tests) {
      return null
    }

    const employeesList = []
    const testsList = []

    employeesOptions.forEach(e => {
      if (groupToEdit.users.find(u => u._id === e.value)) {
        employeesList.push(e)
      }
    })

    testsOptions.forEach(v => {
      if (groupToEdit.examTasks.find(t => t._id === v.value)) {
        testsList.push(v)
      }
    })

    return {
      employeesList: employeesList,
      testsList: testsList,
      title: groupToEdit.title.reduce((memo, { _id, lang, text }) => {
        memo[lang] = {
          _id,
          text
        }
        return memo
      }, {}),
      description: groupToEdit.description.reduce((memo, { _id, lang, text }) => {
        memo[lang] = {
          _id,
          text
        }
        return memo
      }, {})
    }
  }, [groupToEdit, employees, tests])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.tests')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('tests.groups')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('common.labels.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('tests.createTestGroup')}>
        <HandlerComponent
          loading={loadingEmployee || loadingTests || loadingGroup}
          error={employeeError || testsError || groupError}
        />
        <CardBody>
          {
            groupToEdit && employees && tests && <TestGroupForm
              employees={employees}
              tests={tests}
              onSubmit={handleSubmit}
              initialValues={formInitialProps}
              employeesOptions={employeesOptions}
              testsOptions={testsOptions}
              editState
            />
          }
        </CardBody>
      </BasePage>
    </>
  )
}

export default EditTestGroup
