import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { i18n } from 'app-core'
import { IMAGES } from 'app-images'

const SetPasswordScreen = () => {
  const history = useHistory()

  const onLogin = () => {
    history.push('/')
  }

  return (
    <div className='c-app flex-row align-items-center'>
      <Container>
        <Row className='justify-content-center'>
          <Col md='6' className='text-center'>
            <img src={IMAGES.loginPageLogo} height='240px' alt='' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='p-2'>
              <CardBody>
                <div>
                  <h2>{i18n.t('auth.congratulations')}</h2>
                </div>
                <div className='mt-3'>
                  {i18n.t('auth.activationSuccess')}
                </div>
                <div className='mt-3'>
                  <Button type='submit' color='primary' onClick={onLogin}>
                    {i18n.t('screens.login.title')}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SetPasswordScreen
