import React from 'react'
import { useTranslation } from 'react-i18next'
import { CardBody } from 'reactstrap'

const HandlerComponent = ({ loading, error }) => {
  const { t } = useTranslation()
  return (
    <>
      {loading && <CardBody>{t('common.labels.loading')}</CardBody>}
      {error && <CardBody>{t('errors.unknown_error')}</CardBody>}
    </>
  )
}

export default HandlerComponent
