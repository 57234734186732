class Configuration {
  constructor (environment) {
    // Server API URL
    this._apiURL = environment.REACT_APP_API
  }

  get apiUrl () {
    return this._apiURL
  }
}

export default Configuration
