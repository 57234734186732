import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'

import {
  authorizationReducer,
  sessionReducer,
  sidebarReducer
} from '../modules'

export default (history, asyncReducers) => {
  return combineReducers({
    ...authorizationReducer,
    ...sessionReducer,
    ...sidebarReducer,
    form: formReducer,
    router: connectRouter(history),
    ...asyncReducers
  })
}
