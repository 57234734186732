import React from 'react'
import { FormGroup } from 'reactstrap'
import Input from 'reactstrap/es/Input'

export const FormTextAreaField = ({
  input,
  disabled
}) => {
  return (
    <FormGroup className='d-flex align-items-center mb-0'>
      <Input {...input} style={{ resize: 'none' }} type="textarea" disabled={disabled} />
    </FormGroup>
  )
}

export default FormTextAreaField
