import { Apollo } from 'app-core'
import { GQL } from 'app-gql'

export default async ({ email, password }) => {
  const result = await Apollo.mutation({
    query: GQL.Authorization.mutations.signIn,
    variables: { email, password }
  })

  return result
}
