import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbItem, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { faFileImport, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BasePage } from "app-layouts";
import BreadcrumbCommon from "app-components/BreadcrumbCommon";
import HandlerComponent from "app-components/HandlerComponent";

import { GQL } from "app-gql";
import EmployeesList from "app-screens/employees/EmployeesList";
import { ImportEmployeeFromCSV } from "app-components/alerts/ImportEmployeesFromCSV";
import { useMutation, useQuery } from "@apollo/react-hooks";
import UsersType from "app-models/UsersType";
import useDeleteEmployee from "app-hooks/gql/useDeleteEmployee";
import { i18n } from "app-core";
import { Select } from "app-components";
import { mapValuesToSelectOptions } from "app-libs/helpers";

const Employees = () => {
  const reduceEmptyKeys = (data) => {
    let count = 0;
    Object.entries(data).forEach(([key, value]) => {
      count += data.length;
    });
    return count > 0 ? data : null;
  };

  const [filterValue, setFilterValue] = useState({
    algorithms: null,
    tests: null,
    assignedExamTasks: { examTaskIds: [], algorithms: [] },
  });

  const assignedExamTasks = filterValue.assignedExamTasks;
  const refetchVariables = {
    filterRole: [UsersType.EMPLOYEE],
    assignedExamTasks: reduceEmptyKeys(assignedExamTasks),
  };

  const { t } = useTranslation();
  const { loading, error, data, refetch } = useQuery(
    GQL.User.queries.usersList,
    {
      variables: refetchVariables,
    }
  );

  const [deleteEmployee] = useDeleteEmployee({
    refetchQueries: GQL.User.queries.usersList,
    variables: refetchVariables,
  });

  const onEmployeeDelete = useCallback(
    async (employeeID) => {
      await deleteEmployee({ variables: { id: employeeID } });
    },
    [deleteEmployee]
  );

  const [importFromCSVMutation] = useMutation(
    GQL.Employees.mutations.uploadCSV,
    {
      onCompleted(e) {},
      refetchQueries: [
        {
          query: GQL.User.queries.usersList,
          variables: refetchVariables,
        },
      ],
    }
  );

  const importFromCSV = useCallback(() => {
    ImportEmployeeFromCSV(t, async (file) => {
      if (importFromCSVMutation) {
        await importFromCSVMutation({
          variables: {
            file: file,
          },
        });
      }
    });
  }, [importFromCSVMutation, t]);

  const { loading: taskAlgorithmLoading, data: taskAlgorithmData } = useQuery(
    GQL.Test.queries.examTaskAlgorithms
  );
  const selectAlgorithms = useMemo(() => {
    if (taskAlgorithmData) {
      return mapValuesToSelectOptions(taskAlgorithmData.examTaskAlgorithms);
    }
  }, [taskAlgorithmData]);

  const { loading: testsLoading, data: testsData } = useQuery(
    GQL.Test.queries.tests
  );
  const selectTests = useMemo(() => {
    if (testsData) {
      return mapValuesToSelectOptions(testsData.examTaskList, (v) => ({
        label: v.title[0].text,
        value: v._id,
      }));
    }
  }, [testsData]);

  const onSelectFilterAlgorithmsChanged = (store, key, e) => {
    let options = e;
    if (options && options.length <= 0) {
      options = null;
    }
    store.length = 0;
    options && options.forEach((i) => store.push(i.value));
    const currValue = { ...filterValue };
    currValue[key] = options;
    setFilterValue(currValue);
    refetch();
  };

  return (
    <React.Fragment>
      <BreadcrumbCommon>
        <BreadcrumbItem active>
          {t("common.page.admin.employees")}
        </BreadcrumbItem>
        <li className="breadcrumb-menu ml-auto mr-0">
          <div className="btn-group" role="group">
            <Link to="/employee/create" className="btn btn-transparent">
              <FontAwesomeIcon className="nav-icon" icon={faUserPlus} />
              <span className="ml-1">{t("common.create")}</span>
            </Link>
          </div>
          <div className="btn-group" role="group">
            <button className="btn" onClick={() => importFromCSV()}>
              <FontAwesomeIcon className="nav-icon" icon={faFileImport} />
              <span className="ml-1">{t("screens.employee.import_csv")}</span>
            </button>
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t("common.page.admin.employees")}>
        <HandlerComponent loading={loading} error={error} />
        <Row className="pl-2 pr-2 pt-2">
          <Col>
            {i18n.t("tests.selectAlgorithm")}
            <Select
              id={"filterAlgorithms"}
              isLoading={taskAlgorithmLoading}
              isMulti={true}
              options={selectAlgorithms || []}
              onChange={(e) =>
                onSelectFilterAlgorithmsChanged(
                  assignedExamTasks.algorithms,
                  "algorithms",
                  e
                )
              }
              value={
                filterValue && filterValue.algorithms
                  ? filterValue.algorithms
                  : null
              }
            />
          </Col>
          <Col>
            {i18n.t("tests.assignTests")}
            <Select
              id={"filterTests"}
              isLoading={testsLoading}
              isMulti={true}
              options={selectTests || []}
              onChange={(e) =>
                onSelectFilterAlgorithmsChanged(
                  assignedExamTasks.examTaskIds,
                  "tests",
                  e
                )
              }
              value={
                filterValue && filterValue.tests ? filterValue.tests : null
              }
            />
          </Col>
        </Row>
        {data && (
          <EmployeesList
            employees={data.usersList}
            deleteCallBack={onEmployeeDelete}
          />
        )}
      </BasePage>
    </React.Fragment>
  );
};

export default Employees;
