import React from 'react'
import { FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'

export default function FormTextField ({
  input,
  placeholder,
  label,
  icon,
  type,
  defaultValue,
  disabled,
  meta: { touched, error }
}) {
  const invalid = error && touched
  const { name } = input

  return (
    <InputGroup className='mb-3'>
      {label && <Label htmlFor={name}>{label}</Label>}
      {icon && (
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={`icon-${icon}`} />
          </InputGroupText>
        </InputGroupAddon>
      )}
      <Input className='form-control'
        {...input}
        id={name}
        type={type}
        invalid={invalid}
        placeholder={placeholder}
        disabled={disabled}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </InputGroup>
  )
}
