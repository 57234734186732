import createReducer from 'app-store/libs/createReducer'
import { ActionTypes } from './actions'

const initialState = {
  initialized: false
}

export default createReducer(initialState, {
  [ActionTypes.INITIALIZE] () {
    return {
      ...initialState,
      initialized: true
    }
  },
  [ActionTypes.RESET] () {
    return {
      ...initialState
    }
  }
})
