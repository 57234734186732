import React, { useCallback, useMemo, useState } from 'react'
import { AVAILABLE_LOCALIZATIONS } from 'app-models/localizations'
import { Button, Col, Collapse, Form, FormGroup, Row } from 'reactstrap'
import { i18n } from 'app-core'
import Card from 'reactstrap/lib/Card'
import CardHeader from 'reactstrap/lib/CardHeader'
import { Field, reduxForm } from 'redux-form'
import { FormGroupTextField, FormSelectField, FormTextAreaField } from 'app-components/forms'
import { validators } from 'app-libs'
import AnswersType from 'app-models/AnswersType'
import { useQuery } from '@apollo/react-hooks'
import { GQL } from 'app-gql'
import { mapValuesToSelectOptions } from 'app-libs/helpers'
import { CONCEZIONE_DELL_OBIETTIVO } from 'app-core/constants'

const QuestionForm = (props) => {
  const {
    editState,
    submitting,
    pristine,
    invalid,
    handleSubmit,
    addressees
  } = props

  const [expandedLocales, setExpandedLocales] = useState(AVAILABLE_LOCALIZATIONS)

  const handleCollapseClick = useCallback(
    (locale) => {
      if (!expandedLocales.includes(locale)) {
        setExpandedLocales([...expandedLocales, locale])
      } else {
        setExpandedLocales(expandedLocales.filter(l => l !== locale))
      }
    },
    [expandedLocales]
  )

  const questionType = useMemo(() => [
    AnswersType.ONE_ANSWER,
    AnswersType.MULTIPLE_ANSWERS,
    AnswersType.TEXT_ANSWER
  ], [])

  const questionTypeMapping = useMemo(() => questionType.map((type) => {
    return {
      value: type,
      label: i18n.t('tests.type.' + type.toLowerCase())
    }
  }), [questionType])

  /* config */
  const { loading: objectiveLoading, data: objectiveData } = useQuery(GQL.Test.queries.QueriesDataTypes)
  const objectiveOptions = useMemo(() => {
    if (objectiveData) {
      const { queriesDataTypesAdmin: { calcTypes } } = objectiveData
      return mapValuesToSelectOptions(
        calcTypes.find(v => v.name === String(addressees).toLocaleLowerCase()).list,
        (v) => ({
          value: v,
          label: i18n.t(`common.labels.questionObjective.${v}`)
        }))
    }
  }, [objectiveData, addressees])

  // const [currentObjective, setCurrentObjective] = useState(props.initialValues && props.initialValues.objective.value !== CONCEZIONE_DELL_OBIETTIVO)
  const [currentObjective, setCurrentObjective] = useState(true)

  const onSelectObjectiveChanged = (e) => {
    //setCurrentObjective(e.value !== CONCEZIONE_DELL_OBIETTIVO)
    setCurrentObjective(true)
  }

  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>

      <Row form>
        <Col>
          <div>
            <Field
              name='objective'
              label={i18n.t('tests.selectQuestionObjective')}
              labelClasses='text-muted'
              mapping={objectiveOptions || []}
              component={FormSelectField}
              isLoading={objectiveLoading}
              disabled={false}
              onChange={(e) => onSelectObjectiveChanged(e)}
              validate={[validators.required]}
            />
          </div>
          <div>
            <Field
              name='questionType'
              label={i18n.t('tests.selectQuestionType')}
              labelClasses='text-muted'
              mapping={questionTypeMapping}
              value={'One'}
              component={FormSelectField}
              disabled={false}
              validate={[validators.required]}
            />
          </div>
          <br />
          {AVAILABLE_LOCALIZATIONS.map((locale) => {
            return (
              <Card key={locale} className='mb-1'>
                <CardHeader
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleCollapseClick(locale)
                  }}
                >
                  {locale}
                </CardHeader>
                <Collapse isOpen={expandedLocales.includes(locale)} className='p-3'>
                  <Field
                    name={`title.${locale}.text`}
                    type='text'
                    label={i18n.t('common.labels.title')}
                    labelClasses='text-muted'
                    component={FormGroupTextField}
                    validate={[validators.required]}
                  />
                </Collapse>
                {currentObjective && <Collapse isOpen={expandedLocales.includes(locale)} className='p-3'>
                  <Field
                    name={`resultExplanation.${locale}.text`}
                    type='text'
                    label={i18n.t('common.labels.resultExplanation')}
                    labelClasses='text-muted'
                    component={FormTextAreaField}
                    validate={[validators.required]}
                  />
                </Collapse>}
              </Card>
            )
          })}
        </Col>
      </Row>
      <FormGroup className='d-flex align-items-center justify-content-between m-0'>
        <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
          {editState ? i18n.t('common.labels.update') : i18n.t('common.labels.create')}
        </Button>
      </FormGroup>
    </Form>
  )
}

export default reduxForm({
  form: 'QuestionForm'
})(QuestionForm)
