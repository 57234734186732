import axios from "../../axios";
import { configuration } from "../../index";

class ExamAggregateDataApiService {
  async generateAndDownloadTestReport(data, language) {
    return axios.post(
      `${configuration.apiUrl}/generate_and_download_tests_report_pdf/${language}`,
      { data: data },
      { responseType: "blob" }
    );
  }

  async downloadTestReport(fileName) {
    return axios.post(
      `${configuration.apiUrl}/download_tests_report_pdf`,
      { fileName: fileName },
      { responseType: "blob" }
    );
  }
}

export default new ExamAggregateDataApiService();
