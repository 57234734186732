import React, { useMemo } from 'react'
import { SortableContainer } from 'react-sortable-hoc'

import AnswerItem from 'app-screens/tests/components/Questions/AnswerItem'
import SortableItem from 'app-screens/tests/components/Questions/SortableItem'
import { isEmployee } from 'app-models/UsersType'

const SortableAnswers = SortableContainer(({
  question,
  onRemoveAnswer,
  onAnswerClicked,
  onEditAnswer,
  userRole
}) => {
  const elements = useMemo(() => {
    return question.answers && question.answers.map((answer, idx) => {
      return <SortableItem disabled={isEmployee(userRole)} key={`item-${idx}`} index={idx} value={
        <AnswerItem
          idx={idx}
          question={question}
          answer={answer}
          onRemoveAnswer={onRemoveAnswer}
          onAnswerClicked={onAnswerClicked}
          onEditAnswer={onEditAnswer}
        />
      } />
    })
  }, [question, onRemoveAnswer, onEditAnswer])

  return (
    <ul style={{ listStyleType: 'none', paddingInlineStart: '2px', marginTop: '6px' }}>
      { elements }
    </ul>
  )
})

export default SortableAnswers
