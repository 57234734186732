import React from 'react'
import { FormFeedback, Input, InputGroup, Label } from 'reactstrap'
import ReactNumberFormat from 'react-number-format'
import cx from 'classnames'

export default function FormTextField ({
  input,
  label,
  disabled,
  meta: { touched, error }
}) {
  const invalid = error && touched
  const { name } = input

  return (
    <InputGroup className='mb-3'>
      {label && <Label htmlFor={name}>{label}</Label>}
      <ReactNumberFormat
        className={cx('form-control', { 'is-invalid': invalid })}
        {...input}
        customInput={Input}
        disabled={disabled}
        allowNegative={false}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
    </InputGroup>
  )
}
