import gql from 'graphql-tag'

export const employee = gql`
  query employee($id:String) {
    employee(_id:$id) {
      _id
      firstName
      lastName
      email,
      companyId
    }
  }
`
