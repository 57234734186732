import I18n from 'i18next'
import { initReactI18next } from 'react-i18next'

class I18Next {
  constructor (locales) {
    I18n
      .use(initReactI18next)
      .init({
        resources: {
          ...locales
        },

        lng: localStorage.getItem('localization') || 'en',
        fallbackLng: localStorage.getItem('localization') || 'en',

        interpolation: {
          escapeValue: false
        }

      })

    this._i18n = I18n
  }

  get instance () {
    return this._i18n
  }

  t (key, options) {
    return this._i18n.t(key, options)
  }
}

export default I18Next
