import gql from 'graphql-tag'

export const startQuestionnaire = gql`
  mutation startQuestionnaire(
    $id: ID!
  ) {
    startQuestionnaire(
      _id: $id
    ) {
      status
      questionnaire {
        _id
        answerType
        description {
          lang
          text
        }
        questions {
          _id
          answers {
            _id
            description {
              lang
              text
            }
          }
          description {
            lang
            text
          }
          title {
            lang
            text
          }
          type
        }
        title {
          lang
          text
        }
      }
    }
  }
`
