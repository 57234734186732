import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Modal
} from 'reactstrap'
import AnswerForm from 'app-screens/tests/components/AnswerForm'

const AnswerFormModal = (props) => {
  const {
    title,
    isOpen,
    toggle,
    onSubmit,
    onCancel,
    initialValues,
    addressees,
    objectives
  } = props

  const onClose = () => {
    onCancel && onCancel()
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} style={{ minWidth: '800px' }}>
      <Card className="mb-0">
        <CardHeader className='d-flex align-items-center'>
          <i className='fa fa-align-justify' />
          <span>{title}</span>
          <button className='close ml-auto' onClick={onClose}>
            <span aria-hidden>×</span>
          </button>
        </CardHeader>
        <CardBody>
          <AnswerForm
            editState={initialValues && initialValues._id}
            initialValues={initialValues}
            addressees={addressees}
            objectives={objectives}
            onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </Modal>
  )
}

export default AnswerFormModal
