import React, { Component } from "react";
import { BreadcrumbCommon, Heading } from "app-components";
import { BreadcrumbItem, CardBody } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";

import BasePage from "app-layouts/BasePage";
import TestEditorForm from "app-screens/tests/components/TestEditorForm";
import { Apollo, i18n, restApi } from "app-core";
import { GQL } from "app-gql";
import {
  buildTextLangInputType,
  extractValuesFromSelect,
  getTestDescription,
  getTestTitle,
  langReducer,
} from "app-libs/helpers";
import HandlerComponent from "app-components/HandlerComponent";
import QuestionFormModal from "app-screens/tests/components/QuestionFormModal";
import AnswerFormModal from "app-screens/tests/components/AnswerFormModal";
import arrayMove from "array-move";
import { connect } from "react-redux";
import { change } from "redux-form";
import Badge from "reactstrap/lib/Badge";

class TestEditorScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      test: null,
      questionModalIsOpened: false,
      editQuestionModalOpened: false,
      questionToEdit: null,
      answerModalIsOpened: false,
      editAnswerModalOpened: false,
      editAnswer: null,
      editAnswerQuestionId: null,
      history: props.history,
      answerObjectives: null,
      setFieldValue: props.setFieldValue,
    };
  }

  async fetchTest() {
    const { match } = this.props;
    const {
      params: { id },
    } = match;

    const [{ examTaskAdmin }] = await Apollo.query({
      query: GQL.Test.queries.test,
      variables: {
        id: id,
      },
    });

    this.setState({ test: examTaskAdmin });
  }

  componentDidMount() {
    this.fetchTest();
  }

  get testDescription() {
    const { test } = this.state;

    return getTestDescription(test);
  }

  get testTitle() {
    const { test } = this.state;

    return getTestTitle(test);
  }

  handleSubmit = () => {
    this.state.history.push("/test/finish");
  };

  onReorderQuestions = async (oldIndex, newIndex) => {
    const { test } = this.state;

    test.questions = arrayMove(test.questions, oldIndex, newIndex);
    const ids = test.questions.map((q) => q._id);

    this.setState({ test: { ...test } });

    const { data } = await Apollo.mutation({
      query: GQL.Test.mutations.updateTest,
      variables: {
        id: test._id,
        questions: ids,
        algorithmAdresses: this.state.test.algorithmAdresses,
      },
    });

    if (
      data &&
      data.updateExamTask.status === "ok" &&
      data.updateExamTask.examTask
    ) {
      this.setState({ test: { ...data.updateExamTask.examTask } });
    }
  };

  onReorderAnswers = async ({ questionId, oldIndex, newIndex }) => {
    const { test, setFieldValue } = this.state;

    const question = test.questions.find((q) => q._id === questionId);

    question.answers = arrayMove(question.answers, oldIndex, newIndex);

    question.answers.forEach((answer, index) => {
      answer.sortOrder = index;
    });
    setFieldValue("TestEditorForm", "questions", [...test.questions]);

    const answers = question.answers.map(({ _id, sortOrder, type }) => ({
      _id,
      sortOrder,
      type,
    }));

    const result = await Apollo.mutation({
      query: GQL.Test.mutations.updateQuestion,
      variables: {
        id: questionId,
        examTaskIds: [this.state.test._id],
        answers: answers,
      },
    });

    if (
      result &&
      result.data &&
      result.data.updateQuestion &&
      result.data.updateQuestion.status === "ok"
    ) {
      this.setState({ test: { ...test } });
    }
  };

  renderTestEditorForm() {
    const { test } = this.state;

    if (!test) {
      return <HandlerComponent loading={true} />;
    }

    return (
      <React.Fragment>
        <Heading className="mb-1">
          <div className="d-flex flex-row align-items-center justify-content-start">
            <h2>{this.testTitle}</h2>
            <Badge className="ml-2 p-1" color="secondary">
              {String(test.algorithm).toLocaleLowerCase()}
            </Badge>
            <Badge className="ml-2 p-1" color="secondary">
              {String(test.algorithmAdresses).toLocaleLowerCase()}
            </Badge>
          </div>
          <small>{this.testDescription}</small>
        </Heading>

        <TestEditorForm
          initialValues={{
            testId: test._id,
            questions: test.questions,
            shuffle: test.shuffle,
            hideResults: test.hideResults,
          }}
          addQuestion={this.showQuestionModalForm}
          editQuestion={this.toggleQuestionEditModal}
          addAnswer={this.onAddAnswerClick}
          editAnswer={this.editAnswerClick}
          onAnswerRemoved={() => this.fetchTest()}
          enableReinitialize
          exportToPdf={this.downloadExamTask}
          onSubmit={this.handleSubmit}
          onReorderQuestions={this.onReorderQuestions}
          onReorderAnswers={this.onReorderAnswers}
          onPrintClick={this.onPrintClick}
        />
      </React.Fragment>
    );
  }

  onAddAnswerClick = (question) => {
    this.setState({
      answerForQuestionId: question._id,
      answerObjectives: question.calcType,
    });

    this.toggleAnswerModal();
  };

  toggleEditAnswerClick = () => {
    this.setState((prev) => ({
      ...prev,
      editAnswerModalOpened: !this.state.editAnswerModalOpened,
    }));
  };

  editAnswerClick = (question, answer) => {
    this.setState((prev) => ({
      ...prev,
      editAnswer: answer,
      editAnswerQuestionId: question._id,
      answerObjectives: question.calcType,
    }));
    this.toggleEditAnswerClick();
  };

  editedAnswerUpdate = async ({
    title,
    resultExplanation,
    objective,
    strength,
    questionId,
    // defaultAnswer,
    _id,
  }) => {
    const { data } = await Apollo.mutation({
      query: GQL.Test.mutations.updateQuestion,
      variables: {
        id: questionId,
        answers: [
          {
            _id: _id,
            points: parseFloat(strength),
            type: objective.value,
            description: buildTextLangInputType(title),
            resultExplanation: buildTextLangInputType(resultExplanation),
            // defaultAnswer: defaultAnswer,
          },
        ],
      },
    });

    const { test } = this.state;
    const question = test.questions.find((q) => q._id === questionId);
    const answer = question.answers.find((q) => q._id === _id);

    if (data && data.updateQuestion && data.updateQuestion.status === "ok") {
      const remoteAnswer = data.updateQuestion.question.answers.find(
        (q) => q._id === _id
      );
      answer.points = remoteAnswer.points;
      answer.type = remoteAnswer.type;
      answer.description = remoteAnswer.description;
      answer.resultExplanation = remoteAnswer.resultExplanation;

      this.toggleEditAnswerClick();
      this.setState({ test: { ...test } });
    }
  };

  showQuestionModalForm = async () => {
    this.toggleQuestionModal();
  };

  toggleQuestionModal = () => {
    this.setState({ questionModalIsOpened: !this.state.questionModalIsOpened });
  };

  toggleQuestionEditModal = (questionToEdit) => {
    this.setState((prev) => ({
      ...prev,
      editQuestionModalOpened: !this.state.editQuestionModalOpened,
      questionToEdit,
    }));
  };

  toggleAnswerModal = () => {
    this.setState({ answerModalIsOpened: !this.state.answerModalIsOpened });
  };

  createQuestion = async (values) => {
    const { data } = await Apollo.mutation({
      query: GQL.Test.mutations.createQuestion,
      variables: {
        title: buildTextLangInputType(values.title),
        resultExplanation: buildTextLangInputType(values.resultExplanation),
        type: values.questionType.value,
        calcType: extractValuesFromSelect(values.objective),
        /* algorithm: values.questionAlgorithm.value, */
        examTaskIds: [this.state.test._id],
      },
    });

    if (data && data.createQuestion.status === "ok") {
      const { test } = this.state;
      const { question } = data.createQuestion;
      const questionId = question._id;

      this.toggleQuestionModal();

      const result = await Apollo.mutation({
        query: GQL.Test.mutations.updateTest,
        variables: {
          id: test._id,
          questions: [...test.questions.map(({ _id }) => _id), questionId],
          algorithmAdresses: this.state.test.algorithmAdresses,
        },
      });

      if (result && result.data.updateExamTask.status === "ok") {
        const { examTask } = result.data.updateExamTask;

        // TODO
        this.setState({
          test: {
            ...examTask,
          },
        });
      }
    }
  };

  updateQuestion = async (values) => {
    const { test } = this.state;
    const question = test.questions.find((q) => q._id === values._id);

    const { data } = await Apollo.mutation({
      query: GQL.Test.mutations.updateQuestion,
      variables: {
        id: values._id,
        title: buildTextLangInputType(values.title),
        resultExplanation: buildTextLangInputType(values.resultExplanation),
        type: values.questionType.value,
        calcType: extractValuesFromSelect(values.objective),
      },
    });

    if (data && data.updateQuestion && data.updateQuestion.status === "ok") {
      question.title = data.updateQuestion.question.title;
      question.resultExplanation =
        data.updateQuestion.question.resultExplanation;
      question.type = data.updateQuestion.question.type;
      question.calcType = data.updateQuestion.question.calcType;

      this.toggleQuestionEditModal();

      this.setState({ test: { ...test } });
    }
  };

  createAnswer = async ({ title, resultExplanation, ...rest }) => {
    const description = buildTextLangInputType(title);
    const resultExp = buildTextLangInputType(resultExplanation);

    const { test, setFieldValue } = this.state;
    const questionId = this.state.answerForQuestionId;

    const question = test.questions.find((q) => q._id === questionId);

    const answer = {
      points: parseFloat(rest.strength),
      description: description,
      resultExplanation: resultExp,
      sortOrder: question.answers.length,
      type: extractValuesFromSelect(rest.objective),
    };
    question.answers.push(answer);

    setFieldValue("TestEditorForm", "questions", [...test.questions]);

    const result = await Apollo.mutation({
      query: GQL.Test.mutations.updateQuestion,
      variables: {
        id: questionId,
        answers: [answer],
      },
    });

    if (
      result &&
      result.data &&
      result.data.updateQuestion &&
      result.data.updateQuestion.status === "ok"
    ) {
      if (result.data.updateQuestion.question) {
        this.fetchTest();
      }
    }
    this.toggleAnswerModal();
  };

  downloadExamTask = () => {
    const { test } = this.state;

    restApi.ExamTaskRestApiService.downloadExamTask(
      test._id,
      i18n.instance.language
    ).then((response) => {
      saveAs(response.data, getTestTitle(test));
    });
  };

  async onPrintClick() {
    const printableArea = document.getElementById("printableArea");
    const doc = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=640,toolbar=0,scrollbars=1,status=0"
    );
    doc.document.write(
      '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">'
    );
    doc.document.write('<div class="m-3">');
    doc.document.write(printableArea.innerHTML);
    doc.document.write("</div>");

    const removeElementsByClass = (className) => {
      const elements = doc.document.getElementsByClassName(className);
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    };
    const removeElementById = (id) => {
      doc.document.getElementById(id).remove();
    };

    removeElementsByClass("edit-answer-button");
    removeElementsByClass("remove-answer-button");
    removeElementsByClass("question-controls-buttons");
    removeElementById("add-question-button");
    removeElementById("controlsLinks");

    // wait to css finished
    await new Promise((resolve) => setTimeout(resolve, 1000));

    doc.focus();
    doc.print();
    doc.close();
  }

  render() {
    const { questionModalIsOpened, editQuestionModalOpened, questionToEdit } =
      this.state;
    const { answerModalIsOpened, editAnswerModalOpened } = this.state;
    const { editAnswer, editAnswerQuestionId, answerObjectives } = this.state;

    return (
      <>
        <BreadcrumbCommon>
          <BreadcrumbItem active>
            <Link to="/admin/users">Editor</Link>
          </BreadcrumbItem>
        </BreadcrumbCommon>

        <BasePage title="Test Editor">
          <CardBody id="printableArea">{this.renderTestEditorForm()}</CardBody>

          {this.state.test && (
            <>
              <QuestionFormModal
                title={"Add question"}
                addressees={this.state.test.algorithmAdresses}
                isOpen={questionModalIsOpened}
                toggle={this.toggleQuestionModal}
                onSubmit={this.createQuestion}
              />
              <QuestionFormModal
                title={"Edit question"}
                addressees={this.state.test.algorithmAdresses}
                isOpen={editQuestionModalOpened}
                toggle={this.toggleQuestionEditModal}
                initialValues={questionToEdit}
                onSubmit={this.updateQuestion}
              />
              <AnswerFormModal
                addressees={this.state.test.algorithmAdresses}
                objectives={answerObjectives}
                isOpen={answerModalIsOpened}
                toggle={this.toggleAnswerModal}
                onSubmit={this.createAnswer}
              />
              <AnswerFormModal
                addressees={this.state.test.algorithmAdresses}
                objectives={answerObjectives}
                isOpen={editAnswerModalOpened}
                toggle={this.toggleEditAnswerClick}
                initialValues={
                  editAnswer
                    ? {
                        _id: editAnswer._id,
                        objective: { value: editAnswer.type.toLowerCase() },
                        strength: editAnswer.points,
                        title: langReducer(editAnswer.description),
                        resultExplanation: langReducer(
                          editAnswer.resultExplanation
                        ),
                        questionId: editAnswerQuestionId,
                        // defaultAnswer: editAnswer.defaultAnswer,
                      }
                    : {}
                }
                onSubmit={this.editedAnswerUpdate}
              />
            </>
          )}
        </BasePage>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue: (form, field, value) => {
      return dispatch(change(form, field, value));
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(TestEditorScreen));
