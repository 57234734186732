import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SessionActions } from 'app-store'

import ApplicationRouter from './ApplicationRouter'

class Application extends Component {
  componentDidMount () {
    if (this.props.start) {
      this.props.start()
    }
  }

  get isAuthenticated () {
    const { authorization } = this.props
    return authorization.isAuthenticated
  }

  render () {
    const { session } = this.props

    if (!session.initialized) {
      return null
    }

    return <ApplicationRouter />
  }
}

const mapStateToProps = (state) => {
  return {
    authorization: state.authorization,
    session: state.session
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    start: async () => dispatch(SessionActions.session.start())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application)
