import { Company } from './company'
import { User } from './user'
import { Employees } from './employees'
import { Authorization } from './authorization'
import { Test } from './test'

export const GQL = {
  Authorization,
  Company,
  User,
  Employees,
  Test
}
