import gql from 'graphql-tag'

export const me = gql`
  query me {
    me {
      _id
      name
      surname
      role
      status
      email
      companyId
      company {
        name
        companyLogo
      }
      privacy {
        users
        companies
        tests
        examtask
        questions
      }
      localization
      questionnaires {
        _id
        completedAt
        parentId
        resultPoints
        status
        passCounting
      }
      assignedExamTasks {
        _id
        companyIds {
          _id
          name
        }
        title {
          lang
          text
        }
        description {
          lang
          text
        }
        maxCount
        infinite
        hideResults
      }
    }
  }
`
