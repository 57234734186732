import gql from 'graphql-tag'

export const createExamTaskGroupAssignment = gql`
  mutation createExamTaskGroupAssignment(
    $description: [TextLangInputType], 
    $examTaskIds: [ID], 
    $title: [TextLangInputType], 
    $userIds: [ID]
  ) {
    createExamTaskGroupAssignment(
      description: $description,
      examTaskIds: $examTaskIds,
      title: $title,
      userIds: $userIds
    ) {
      status
      groupAssignment {
        _id
      }
    }
  }
`
