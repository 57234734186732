import gql from 'graphql-tag'
import { Fragments } from 'app-gql/fragments'

export const updateTest = gql`
  mutation updateExamTask(
    $id: ID!,
    $algorithm: String,
    $algorithmAdresses: String
    $description: UpdateLangInputType,
    $name: String,
    $questions: [ID],
    $title: UpdateLangInputType,
    $shuffle: Boolean,
    $companyIds: [ID],
    $infinite: Boolean,
    $hideResults: Boolean,
    $maxCount: Int
  ) {
    updateExamTask(
      _id: $id,
      algorithm: $algorithm,
      algorithmAdresses: $algorithmAdresses
      description: $description,
      name: $name,
      questions: $questions,
      title: $title,
      shuffle: $shuffle,
      companyIds: $companyIds,
      infinite: $infinite,
      hideResults: $hideResults,
      maxCount: $maxCount
    ) {
      status
      examTask {
        _id
        algorithmAdresses
        name
        title {
          lang
          text
        }
        description {
          lang
          text
        }
        questions {
          ...question
        }
        shuffle
        maxCount
        infinite
        hideResults
      }
    }
  }
  ${Fragments.question}
`
