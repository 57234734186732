import gql from 'graphql-tag'

export const assignExamTaskById = gql`
  mutation assignExamTaskById(
    $examTaskId: ID
  ) {
    assignExamTaskById(
      examTaskId: $examTaskId
    ) {
      status
    }
  }
`
