import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem, CardBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import { BasePage } from 'app-layouts'
import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import HandlerComponent from 'app-components/HandlerComponent'
import { GQL } from 'app-gql'
import { useMutation, useQuery } from '@apollo/react-hooks'
import useFetchEmployees from 'app-hooks/gql/useFetchEmployees'
import TestGroupForm from 'app-screens/tests/groups/TestGroupForm'
import { buildTextLangInputType, getTestTitle } from 'app-libs/helpers'

const CreateTestGroup = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { loading: loadingEmployee, error: employeeError, data: employees } = useFetchEmployees()
  const { loading: loadingTests, error: testsError, data: tests } = useQuery(GQL.Test.queries.tests)

  const [createTestGroup] = useMutation(GQL.Test.mutations.createExamTaskGroupAssignment, {
    onCompleted: ({ createExamTaskGroupAssignment }) => {
      if (createExamTaskGroupAssignment) {
        const { status } = createExamTaskGroupAssignment

        status === 'ok' && history.push('/test/groups')
      }
    },
    refetchQueries: [
      { query: GQL.Test.queries.examTaskGroupAssignmentList }
    ]
  })

  const employeesOptions = useMemo(() => {
    if (employees) {
      return employees.usersList.map(({ _id, name, surname }) => ({ value: _id, label: name + ' ' + surname }))
    }
    return []
  }, [employees])

  const testsOptions = useMemo(() => {
    if (tests) {
      return tests.examTaskList.map(test => ({ value: test._id, label: getTestTitle(test) }))
    }
    return []
  }, [tests])

  const handleSubmit = useCallback(async (values) => {
    if (createTestGroup) {
      await createTestGroup({
        variables: {
          description: buildTextLangInputType(values.description),
          examTaskIds: values.testsList.map(t => t.value),
          title: buildTextLangInputType(values.title),
          userIds: values.employeesList.map(t => t.value)
        }
      })
    }
  }, [createTestGroup])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.tests')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('tests.groups')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('common.labels.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('tests.createTestGroup')}>
        <HandlerComponent loading={loadingEmployee || loadingTests} error={employeeError || testsError} />
        <CardBody>
          {
            employees && tests && <TestGroupForm
              employeesOptions={employeesOptions}
              testsOptions={testsOptions}
              onSubmit={handleSubmit}
              editState={false}
                                  />
          }
        </CardBody>
      </BasePage>
    </>
  )
}

export default CreateTestGroup
