import gql from 'graphql-tag'

export const deleteExamTaskGroupAssignment = gql`
  mutation deleteExamTaskGroupAssignment(
    $_id: ID
  ) {
    deleteExamTaskGroupAssignment(
      _id: $_id
    ) {
      status
    }
  }
`
