import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form, FormGroup } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'

import { FormGroupTextField } from 'app-components/forms'
import { validators } from 'app-libs'
import { AVAILABLE_LOCALIZATIONS } from 'app-models/localizations'
import FormSelectField from 'app-components/forms/FormSelectField'

const EmployeeForm = (
  {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    error,
    disabledFields,
    initialValues,
    setFieldValue,
    values,
    ...rest
  }
) => {
  const { t } = useTranslation()

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Field
          name='name'
          autoComplete='new-name-name'
          type='text'
          label={t('common.labels.employee_first_name')}
          labelClasses='text-muted'
          component={FormGroupTextField}
          validate={[validators.required]}
        />
        {/* USER SECOND NAME */}
        <Field
          name='surname'
          autoComplete='new-surname-name'
          type='text'
          label={t('common.labels.employee_second_name')}
          labelClasses='text-muted'
          component={FormGroupTextField}
          validate={[validators.required]}
        />
        {/* USER EMAIL */}
        <Field
          name='email'
          autoComplete='new-email'
          type='email'
          label={t('common.labels.email')}
          labelClasses='text-muted'
          component={FormGroupTextField}
          validate={[validators.email, validators.required]}
        />
        {/* USER LOCALE */}
        <Field
          name='localization'
          label={t('common.labels.language')}
          labelClasses='text-muted'
          mapping={AVAILABLE_LOCALIZATIONS.map(value => {
            return {
              label: String(t(`common.labels.locale.${value}`)),
              value: value
            }
          })}
          component={FormSelectField}
          isMulti={false}
          validate={[validators.required]}
        />

        <FormGroup className='d-flex align-items-center justify-content-between m-0'>
          <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
            {t('common.create')}
          </Button>
        </FormGroup>
      </Form>
    </>
  )
}

export default reduxForm({
  form: 'EmployeeForm'
})(EmployeeForm)
