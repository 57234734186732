import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { PrivateRoute } from 'app-routes'
import { PRIVACY_ACTIONS, PRIVACY_TYPE } from 'app-models/Privacy'
import { LoginScreen, RecoverScreen, ResetPasswordScreen, UserScreen, EmployeesScreen, SignUp } from 'app-screens'
import { BaseLayout } from 'app-layouts'
import UsersType from 'app-models/UsersType'
import OnlyPublic from 'app-routes/OnlyPublic'
import { CreateUser, EditUser } from 'app-screens/users'
import Companies from 'app-screens/company'
import CreateCompany from 'app-screens/company/CreateCompany'
import EditCompany from 'app-screens/company/EditCompany'
import Tests from 'app-screens/tests'
import CreateTest from 'app-screens/tests/CreateTest'
import Dashboard from 'app-screens/dashboard/DashBoard'
import EditTest from 'app-screens/tests/EditTest'
import CreateEmployee from 'app-screens/employees/CreateEmployee'
import EditEmployee from 'app-screens/employees/EditEmployee'
import TestEditorScreen from 'app-screens/tests/TestEditorScreen'
import TestFinish from 'app-screens/tests/TestFinish'
import TestGroups from 'app-screens/tests/groups/TestGroups'
import TestsPassing from 'app-screens/tests/passing/TestsPassing'
import TestAggregatedData from 'app-screens/tests/TestAggregatedData'
import CreateTestAggregatedData from 'app-screens/tests/CreateTestAggregatedData'
import TestResults from 'app-screens/tests/TestResults'
import CreateTestGroup from 'app-screens/tests/groups/CreateTestGroup'
import EditTestGroup from 'app-screens/tests/groups/EditTestGroup'
import SetPasswordScreen from 'app-screens/activation/SetPasswordScreen'
import { SuccessActivationScreen } from 'app-screens/activation'

export default function ApplicationRouter() {
  return (
    <Switch>
      <OnlyPublic exact path='/signup' component={SignUp} />
      <OnlyPublic exact path='/' component={LoginScreen} />
      <OnlyPublic exact path='/recover' component={RecoverScreen} />
      <OnlyPublic exact path='/recover/:token' component={ResetPasswordScreen} />
      <OnlyPublic exact path='/resume-activation/:token' component={SetPasswordScreen} />
      <OnlyPublic exact path='/success-activation' component={SuccessActivationScreen} />

      {/* DASHBOARD */}
      <PrivateRoute
        exact path='/dashboard'
        layout={BaseLayout}
        component={Dashboard}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN, UsersType.EMPLOYEE]}
      />

      {/* USERS */}
      <PrivateRoute
        exact path='/admin/users'
        layout={BaseLayout}
        component={UserScreen}
        allowedRoles={[UsersType.ADMIN]}
      />
      <PrivateRoute
        exact path='/user/create' layout={BaseLayout} component={CreateUser}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/user/edit/:id' layout={BaseLayout} component={EditUser}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />

      {/* EMPLOYEES */}
      <PrivateRoute
        exact path='/admin/employees'
        layout={BaseLayout}
        component={EmployeesScreen}
        allowedRoles={[UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/employee/create' layout={BaseLayout} component={CreateEmployee}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/employee/edit/:id' layout={BaseLayout} component={EditEmployee}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />

      {/* COMPANIES */}
      <PrivateRoute
        exact path='/companies' layout={BaseLayout} component={Companies}
        allowedRoles={[UsersType.ADMIN]}
      />
      <PrivateRoute
        exact path='/company/create' layout={BaseLayout} component={CreateCompany}
        allowedRoles={[UsersType.ADMIN]}
      />
      <PrivateRoute
        exact path='/company/edit/:id' layout={BaseLayout} component={EditCompany}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/company/info/' layout={BaseLayout} component={EditCompany}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />

      {/* TESTS */}
      <PrivateRoute
        exact path='/tests' layout={BaseLayout} component={Tests}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/tests/create' layout={BaseLayout} component={CreateTest}
        allowedRoles={[UsersType.ADMIN]}
      />
      <PrivateRoute
        exact path='/tests/edit/:id' layout={BaseLayout} component={EditTest}
        allowedRoles={[UsersType.ADMIN]}
      />
      <PrivateRoute
        exact path='/tests/editor/:id' layout={BaseLayout} component={TestEditorScreen}
        allowedRoles={[UsersType.ADMIN]}
      />
      <PrivateRoute
        exact path='/tests/aggregate_data' layout={BaseLayout} component={TestAggregatedData}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/tests/aggregate_data/new' layout={BaseLayout} component={CreateTestAggregatedData}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/test/start/:id' layout={BaseLayout} component={TestsPassing}
        allowedRoles={[UsersType.EMPLOYEE]}
      />
      <PrivateRoute
        exact path='/test/result/:id' layout={BaseLayout} component={TestFinish}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN, UsersType.EMPLOYEE]}
      />
      <PrivateRoute
        exact path='/test/groups' layout={BaseLayout} component={TestGroups}
        allowedRoles={[UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/test/groups/create' layout={BaseLayout} component={CreateTestGroup}
        allowedRoles={[UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/test/groups/edit/:id' layout={BaseLayout} component={EditTestGroup}
        allowedRoles={[UsersType.COMPANY_ADMIN]}
      />
      <PrivateRoute
        exact path='/test/results/:id' layout={BaseLayout} component={TestResults}
        allowedRoles={[UsersType.ADMIN, UsersType.COMPANY_ADMIN]}
      />

      <Redirect to={{ pathname: '/dashboard' }} />

    </Switch>
  )
}
