import * as mutations from './mutations'
import * as queries from './queries'

export const Test = {
  mutations: {
    ...mutations
  },
  queries: {
    ...queries
  }
}
