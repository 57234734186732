import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import { useMutation } from '@apollo/react-hooks'
import { GQL } from 'app-gql'
import ActivationForm from 'app-screens/activation/ActivationForm'
import { IMAGES } from 'app-images'

const SetPasswordScreen = () => {
  const history = useHistory()
  const { token } = useParams()

  const [resetPasswordConfirm] = useMutation(GQL.Authorization.mutations.resetPasswordConfirm, {
    onCompleted({ resetPasswordConfirm }) {
      if (resetPasswordConfirm) {
        const { status } = resetPasswordConfirm
        if (status === 'ok') {
          history.push('/success-activation')
        }
      }
    }
  })

  const onSubmit = (values) => {
    return resetPasswordConfirm({ variables: { ...values } })
  }

  return (
    <div className='c-app flex-row align-items-center'>
      <Container>
        <Row className='justify-content-center'>
          <Col md='6' className='text-center'>
            <img src={IMAGES.loginPageLogo} height='240px' alt='' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='p-4'>
              <CardBody>
                <ActivationForm
                  onSubmit={onSubmit}
                  initialValues={{
                    token: token,
                    password: '',
                    confirmPassword: ''
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SetPasswordScreen
