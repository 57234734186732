import React, { useCallback, useMemo, useState } from "react";
import { AVAILABLE_LOCALIZATIONS } from "app-models/localizations";
import { Button, Col, Collapse, Form, FormGroup, Row } from "reactstrap";
import { i18n } from "app-core";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import { Field, reduxForm } from "redux-form";
import {
  FormGroupTextField,
  FormSelectField,
  FormTextAreaField,
} from "app-components/forms";
import { validators } from "app-libs";
import { useQuery } from "@apollo/react-hooks";
import { GQL } from "app-gql";
import { mapValuesToSelectOptions } from "app-libs/helpers";
import { CONCEZIONE_DELL_OBIETTIVO } from "app-core/constants";
import { FormCheckboxField } from "app-components/forms/FormCheckboxField";

const AnswerForm = (props) => {
  const {
    editState,
    submitting,
    pristine,
    invalid,
    handleSubmit,
    addressees,
    objectives,
  } = props;

  const [expandedLocales, setExpandedLocales] = useState(
    AVAILABLE_LOCALIZATIONS
  );

  const handleCollapseClick = useCallback(
    (locale) => {
      if (!expandedLocales.includes(locale)) {
        setExpandedLocales([...expandedLocales, locale]);
      } else {
        setExpandedLocales(expandedLocales.filter((l) => l !== locale));
      }
    },
    [expandedLocales]
  );

  /* config */
  const { loading: typesLoading, data: typesData } = useQuery(
    GQL.Test.queries.QueriesDataTypes
  );
  const typesOptions = useMemo(() => {
    if (typesData) {
      const {
        queriesDataTypesAdmin: { answerTypes },
      } = typesData;

      const answerTypesList = answerTypes.find(
        (item) =>
          item.addressees === addressees.toLowerCase() &&
          item.objectives === objectives
      );

      return mapValuesToSelectOptions(answerTypesList.responseOptions, (v) => ({
        label: i18n.t(`common.labels.responseOptions.${v}`),
        value: v,
      }));
    }
  }, [typesData]);

  return (
    <Form
      className="d-flex flex-column justify-content-between"
      onSubmit={handleSubmit}
    >
      <Row form>
        <Col>
          <h4 className="mb-4">
            {editState ? i18n.t("tests.editAnswer") : i18n.t("tests.addAnswer")}
          </h4>
          <Row form>
            <Col>
              <div>
                <Field
                  name="objective"
                  label={i18n.t("tests.answerType")}
                  labelClasses="text-muted"
                  mapping={typesOptions || []}
                  component={FormSelectField}
                  disabled={false}
                  validate={[validators.required]}
                  isLoading={typesLoading}
                />
              </div>
            </Col>
            <Col md={4}>
              <Field
                name={"strength"}
                type="number"
                label={i18n.t("common.labels.strength")}
                labelClasses="text-muted"
                component={FormGroupTextField}
                validate={[validators.required]}
                min={1}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Field
                name="defaultAnswer"
                type="checkbox"
                label={i18n.t("common.labels.is_default_answer")}
                isEnabled
                labelClasses="text-muted"
                component={FormCheckboxField}
              />
            </Col>
          </Row> */}

          {AVAILABLE_LOCALIZATIONS.map((locale) => {
            return (
              <Card key={locale} className="mb-1">
                <CardHeader
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleCollapseClick(locale);
                  }}
                >
                  {locale}
                </CardHeader>
                <Collapse
                  isOpen={expandedLocales.includes(locale)}
                  className="p-3"
                >
                  <Field
                    name={`title.${locale}.text`}
                    type="text"
                    label={i18n.t("common.labels.title")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required]}
                  />
                </Collapse>
                <Collapse
                  isOpen={expandedLocales.includes(locale)}
                  className="p-3"
                >
                  <Field
                    name={`resultExplanation.${locale}.text`}
                    type="text"
                    label={i18n.t("common.labels.resultExplanation")}
                    labelClasses="text-muted"
                    component={FormTextAreaField}
                    validate={[validators.required]}
                  />
                </Collapse>
              </Card>
            );
          })}
        </Col>
      </Row>
      <FormGroup className="d-flex align-items-center justify-content-between m-0">
        <Button
          type="submit"
          color="primary"
          disabled={submitting || pristine || invalid}
        >
          {editState
            ? i18n.t("common.labels.update")
            : i18n.t("common.labels.create")}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default reduxForm({
  form: "AnswerForm",
})(AnswerForm);
