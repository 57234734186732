import {
  AuthorizationActions,
  SessionActions,
  SidebarActions
} from './modules'

import configureStore from './configureStore'

export default configureStore

export {
  AuthorizationActions,
  SessionActions,
  SidebarActions
}
