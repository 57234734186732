import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useAuthorization } from 'app-hooks'
import UsersType from 'app-models/UsersType'

const OnlyPublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { user, isAuthenticated } = useAuthorization()

  return (
    <Route
      {...rest}
      render={(props) => (
        // eslint-disable-next-line no-nested-ternary
        !isAuthenticated ? (
          Layout === undefined ? (
            <Component {...props} />
          ) : (
            <Layout><Component {...props} /></Layout>
          )
        ) : (user.role === UsersType.ADMIN || user.role === UsersType.COMPANY_ADMIN) ? (
          <Redirect to={{ pathname: '/admin/' }} />
        ) : (
          <Redirect to={{ pathname: '/client/dashboard' }} />
        )
      )}
    />
  )
}

export default OnlyPublicRoute
