import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, BreadcrumbItem, Button, CardBody, CardFooter, Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import BasePage from 'app-layouts/BasePage'
import HandlerComponent from 'app-components/HandlerComponent'
import EditUserForm from 'app-screens/users/components/EditUserForm'
import ChangePasswordForm from './components/ChangePasswordForm'
import withLocalization from '../../../hoc/withLocalization'
import useChangePassword from 'app-hooks/gql/useChangePassword'
import { GQL } from 'app-gql'
import GenericGraphqlError from 'app-components/errors/GenericGraphqlError'
import { connect } from 'react-redux'
import UsersType from 'app-models/UsersType'

const mapUserToFormProps = (data, t) => {
  const memo = { ...data.user }

  memo.role = {
    value: memo.role,
    label: String(t(`labels.${memo.role}`))
      .toUpperCase()
  }

  memo.localization = {
    value: memo.localization,
    label: String(t(`labels.locale.${memo.localization}`))
  }

  memo.companyId = data.user.company ? {
    value: memo.company._id,
    label: memo.company.name
  } : null

  return memo
}

const EditUser = ({ user }) => {
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()
  const { id } = params

  const { loading, error, data } = useQuery(GQL.User.queries.user, {
    variables: { id: id },
    fetchPolicy: 'no-cache'
  })
  // const isAdminProfile = data && (data.user.role === UsersType.ADMIN || data.user.role === UsersType.COMPANY_ADMIN)
  const isAdmin = user.role === UsersType.ADMIN
  const companiesData = useQuery(GQL.Company.queries.companies, { skip: !isAdmin })

  const [formInitialProps, setFormInitialProps] = useState()
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false)

  /* EDIT USER INFO */
  const [updateUser, { error: gqlError }] = useMutation(GQL.User.mutations.editUser, {
    onCompleted ({ updateUser }) {
      if (updateUser && updateUser.status === 'ok') {
        if (user._id === id) {
          window.location.reload()
        } else {
          history.goBack()
        }
      }
    },
    refetchQueries: [{ query: GQL.Company.queries.companies }]
  })

  const handleUserInfoSubmit = useCallback(async (values) => {
    if (updateUser) {
      if (user._id === id) {
        localStorage.setItem('localization', values.localization.value)
      }
      const companyLogo = typeof values.companyLogo !== 'object' ? null : values.companyLogo

      await updateUser({
        variables:
          {
            ...values,
            role: values.role.value,
            companyId: values.companyId ? values.companyId.value : null,
            customerCompanyId: values.customerCompanyId ? values.customerCompanyId.value : null,
            localization: values.localization.value,
            companyLogo
          }
      })
    }
  }, [updateUser, id, user._id])

  /* CHANGE USER PASSWORD */
  const [chaneUserPassword, { error: changePasswordError }] = useChangePassword({
    onComplete: ({ changeUserPassword }) => {
      if (changeUserPassword && changeUserPassword.status === 'ok') {
        setPasswordChangeSuccess(true)

        setTimeout(() => {
          setPasswordChangeSuccess(false)
        }, 2000)
      }
    }
  })

  const handleChangePassword = useCallback(async (values) => {
    if (chaneUserPassword) {
      await chaneUserPassword({
        variables: {
          ...values,
          id
        }
      })
    }
  }, [chaneUserPassword, id])

  /* patch user model */
  useEffect(() => data && setFormInitialProps(mapUserToFormProps(data, t)), [data, setFormInitialProps])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.update_user')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.update_user')}>
        <CardBody>
          <HandlerComponent loading={loading}/>
          {(error || gqlError) && (
            <GenericGraphqlError className='b-radius-0' error={error || gqlError}/>
          )}
          {(formInitialProps && (!isAdmin || !companiesData.loading)) && (
            <>
              {/* BASE INFORMATION */}
              <Row className='mb-1'>
                <Col>
                  <EditUserForm
                    initialValues={{ ...formInitialProps }}
                    companiesList={companiesData.data && data.user.role.value === UsersType.COMPANY_ADMIN
                      ? companiesData.data.companiesList : null}
                    onSubmit={handleUserInfoSubmit}
                  />
                </Col>
              </Row>

              {/* CHANGE PASSWORD */}
              {(user._id === id) && (
                <Row>
                  <Col>
                    {(changePasswordError) && (
                      <GenericGraphqlError className='b-radius-0' error={changePasswordError}/>
                    )}
                    {
                      passwordChangeSuccess ? (
                        <Alert color="success">{t('common.labels.passwordChangedSuccess')}</Alert>) : null
                    }
                    <ChangePasswordForm
                      initialValues={{
                        oldPassword: '',
                        password: '',
                        newPassword: ''
                      }}
                      onSubmit={handleChangePassword}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </CardBody>
        <CardFooter className='float-right'>
          <Button color='primary' onClick={() => history.goBack()}>
            {t('common.labels.close')}
          </Button>
        </CardFooter>
      </BasePage>
    </>
  )
}

EditUser.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    user: state.authorization.user
  }
}

export default connect(mapStateToProps)(withLocalization(EditUser))
