import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container, Card, CardBody, Row, Col } from 'reactstrap'
import { useMutation } from '@apollo/react-hooks'
import { IMAGES } from 'app-images'

import { Heading, Text } from 'app-components'

import RecoverForm from './RecoverForm'
import { GQL } from 'app-gql'

const body = ({ reseted, onSubmit, t }) => {
  if (reseted) {
    return (
      <div className='d-flex flex-column'>
        <Heading className='text-left' size={2}>{t('auth.password_reset_sent_text_1')}</Heading>
        <Text className='ml-auto' tag={Link} to='/' fontWeight='bold'>
          &larr;&nbsp;
          {t('common.labels.back_to_home')}
        </Text>
      </div>
    )
  }
  return <RecoverForm onSubmit={onSubmit} initialValues={{ email: '' }} />
}

const RecoverScreen = () => {
  const { t } = useTranslation()

  const [reseted, setPasswordReseted] = useState(false)
  const [resetPassword] = useMutation(GQL.Authorization.mutations.resetPassword, {
    onCompleted () {
      setPasswordReseted(true)
    }
  })

  const onSubmit = (values) => {
    return resetPassword({ variables: { ...values } })
  }

  return (
    <div className='c-app flex-row align-items-center'>
      <Container>
        <Row className='justify-content-center'>
          <Col md='6' className='text-center'>
            <img src={IMAGES.loginPageLogo} height='240px' alt='' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='p-4'>
              <CardBody>
                {body({ reseted: reseted, onSubmit: onSubmit, t: t })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RecoverScreen
