import gql from 'graphql-tag'

export const generateQuestionnaire = gql`
  mutation generateQuestionnaire(
    $examTaskId: ID!
  ) {
    generateQuestionnaire(
      examTaskId: $examTaskId
    ) {
      questionnaireId
      status
    }
  }
`
