import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

class ApplicationProvider extends Component {
  render () {
    const { store } = this.props

    return (
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          {this.props.children}
        </PersistGate>
      </Provider>
    )
  }
}

export default ApplicationProvider
