import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { BreadcrumbItem, CardBody } from 'reactstrap'
import { useMutation } from '@apollo/react-hooks'

import { BreadcrumbCommon } from 'app-components'
import BasePage from 'app-layouts/BasePage'
import { GQL } from 'app-gql'
import EmployeeForm from 'app-screens/employees/components/EmployeeForm'
import GenericGraphqlError from 'app-components/errors/GenericGraphqlError'
import UsersType from 'app-models/UsersType'
import useAuthorization from 'app-hooks/useAuthorization'

const CreateEmployee = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { user } = useAuthorization()

  const [createUser, { error }] = useMutation(GQL.User.mutations.createUser, {
    onCompleted: ({ createUser }) => {
      if (createUser) {
        const { status } = createUser
        status === 'ok' && history.push('/admin/employees')
      }
    },
    refetchQueries: [
      {
        query: GQL.User.queries.usersList,
        variables: { filterRole: [UsersType.EMPLOYEE], assignedExamTasks: null }
      }
    ]
  })

  const handleSubmit = useCallback(async (values) => {
    if (createUser) {
      await createUser({
        variables: {
          ...values,
          role: UsersType.EMPLOYEE,
          companies: [user.companyId],
          localization: values.localization.value
        }
      })
    }
  }, [createUser])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>
          <Link to='/admin/employees'>{t('common.page.admin.employees')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('common.page.admin.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.add_new_employee')}>
        <CardBody>
          {(error) && (
            <GenericGraphqlError className='b-radius-0' error={error}/>
          )}
          <EmployeeForm
            onSubmit={handleSubmit}
            initialValues={{
              name: '',
              surname: '',
              email: '',
              password: '',
              localization: ''
            }}
          />
        </CardBody>
      </BasePage>
    </>
  )
}

export default CreateEmployee
