import React from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";

const FormMultiSelectField = ({
  meta: { touched, error },
  input,
  options,
  placeholder,
  ...rest
}) => {
  const className = ` form-group mb-3 ${touched && error ? "has-danger" : ""}`;
  return (
    <FormGroup className="d-flex align-items-center mb-0">
      <Select
        {...rest}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        placeholder={placeholder}
        isMulti
        className="basic-multi-select w-100"
        classNamePrefix="select"
      />
      <div className={className}>
        <div className="text-help">{touched ? error : ""}</div>
      </div>
    </FormGroup>
  );
};

export default FormMultiSelectField;
