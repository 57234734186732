import React from "react";
import { FormGroup, Label } from "reactstrap";

export const FormCheckboxField = ({ input, label, name, value, isEnabled }) => {
  return (
    <FormGroup className="d-flex align-items-center mb-0">
      <input
        {...input}
        name={name}
        type="checkbox"
        value={value}
        disabled={!isEnabled}
      />
      {label && (
        <Label for={name} className={"ml-1 mt-2"}>
          {label}
        </Label>
      )}
    </FormGroup>
  );
};
