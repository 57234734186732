import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import { isSuperAdmin } from 'app-models/UsersType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BasePage from 'app-layouts/BasePage'
import HandlerComponent from 'app-components/HandlerComponent'
import CompaniesList from 'app-screens/company/components/CompaniesList'
import useDeleteCompany from 'app-hooks/gql/useDeleteCompany'
import { useAuthorization } from 'app-hooks'
import { GQL } from 'app-gql'

const Companies = () => {
  const { t } = useTranslation()
  const { user } = useAuthorization()

  const { loading, error, data } = useQuery(GQL.Company.queries.companies)

  const [deleteCompany] = useDeleteCompany({ refetchQueries: GQL.Company.queries.companies })

  const [updateCompany] = useMutation(GQL.Company.mutations.updateCompany, {
    refetchQueries: [{ query: GQL.Company.queries.companies }]
  })

  const onDeleteCompanyClicked = useCallback(async (id) => {
    await deleteCompany({ variables: { id: id } })
  }, [deleteCompany])

  const onLoginPermittedClicked = useCallback(async data => {
    if (updateCompany) {
      await updateCompany({
        variables: {
          _id: data._id,
          email: data.email,
          loginPermitted: !data.loginPermitted
        }
      })
    }
  }, [updateCompany])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.admin.companies_list')}</BreadcrumbItem>
        {/* ADD NEW COMPANY BUTTON */}
        <li className='breadcrumb-menu d-md-down-none ml-auto mr-0'>
          <div className='btn-group' role='group'>
            {isSuperAdmin(user.role) && (
              <Link to='/company/create' className='btn btn-transparent'>
                <FontAwesomeIcon className='nav-icon' icon={faBuilding} />
                <span className='ml-1'>{t('common.labels.create')}</span>
              </Link>
            )}
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.companies_list')}>
        <HandlerComponent loading={loading} error={error} />
        {
          data && <CompaniesList
            companiesList={data.companiesList}
            deleteCompanyClicked={onDeleteCompanyClicked}
            loginPermittedClicked={onLoginPermittedClicked}
          />
        }
      </BasePage>
    </>
  )
}

export default Companies
