import gql from 'graphql-tag'

export const QueriesDataTypes = gql`
  query queriesDataTypesAdmin {
    queriesDataTypesAdmin {
      answerTypes {
        addressees
        objectives
        responseOptions
      }
      calcTypes {
        list
        name
      }
    }
  }
`
