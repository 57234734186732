import { useMutation } from '@apollo/react-hooks'
import { deleteUser } from 'app-gql/user/mutations'

const useDeleteEmployee = ({ onComplete, refetchQueries, variables }) => {
  return useMutation(deleteUser, {
    onCompleted (data) {
      onComplete && onComplete(data)
    },
    refetchQueries: refetchQueries ? [{ query: refetchQueries, variables }] : null
  })
}

export default useDeleteEmployee
