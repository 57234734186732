import { useQuery } from '@apollo/react-hooks'
import { GQL } from 'app-gql'
import UsersType from 'app-models/UsersType'

const useFetchUsers = () => {
  return useQuery(GQL.User.queries.usersList, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { filterRole: [UsersType.ADMIN, UsersType.COMPANY_ADMIN] }
  })
}
export default useFetchUsers
