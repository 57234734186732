import gql from 'graphql-tag'

export const uploadCSV = gql`
  mutation uploadCSV(
    $file: Upload!
  ) {
    uploadCSV(
      file: $file
    ) {
      status
    }
  }
`
