import { api, Apollo } from 'app-core'
import { push } from 'connected-react-router'
import UsersType from 'app-models/UsersType'
import { GQL } from 'app-gql'

export const ActionTypes = {
  START: 'authorization.start',
  SET: 'authorization.set',
  UPDATE: 'authorization.update',
  COMPLETE: 'authorization.complete',
  LOGOUT: 'authorization.logout'
}

const start = () => ({ type: ActionTypes.START })
const complete = () => ({ type: ActionTypes.COMPLETE })
const set = (payload) => ({ type: ActionTypes.SET, payload })
const reset = () => ({ type: ActionTypes.LOGOUT })

const signIn = ({ email, password, queryParams }) => {
  return async (dispatch) => {
    dispatch(start())

    try {
      const { data: { signIn }, errors } = await api.authorization.signIn({ email, password })

      if (signIn) {
        const { token, refreshToken } = signIn
        await dispatch(set({ token, refreshToken }))

        const [{ me }] = await api.authorization.me()
        await dispatch(set({ user: me }))

        if (queryParams.t) {
          await Apollo.mutation({
            query: GQL.Employees.mutations.assignExamTaskById,
            variables: {
              examTaskId: queryParams.t
            }
          })
        }

        await dispatch(set({ isAuthenticated: true }))

        if (me.role === UsersType.ADMIN) {
          await dispatch(push('/admin/users'))
        }
      } else if (errors && errors.length > 0) {
        return { errors }
      }
    } catch (e) {
      // no op
    } finally {
      dispatch(complete())
    }
  }
}

const signUp = (values, assignTestId) => {
  return async (dispatch) => {
    dispatch(start())

    try {
      const { data: { signUp }, errors } = await api.authorization.signUp(values)

      if (signUp) {
        const { token, refreshToken } = signUp
        await dispatch(set({ token, refreshToken }))

        const [{ me }] = await api.authorization.me()
        await dispatch(set({ user: me }))

        if (assignTestId) {
          await Apollo.mutation({
            query: GQL.Employees.mutations.assignExamTaskById,
            variables: {
              examTaskId: assignTestId
            }
          })
        }

        await dispatch(set({ isAuthenticated: true }))
      } else if (errors && errors.length > 0) {
        return { errors }
      }
    } catch (e) {
      // no op
    } finally {
      dispatch(complete())
    }
  }
}

const signOut = () => {
  return async (dispatch, state) => {
    await dispatch(reset())
    await dispatch(push('/'))
  }
}

export const Actions = {
  signIn,
  signUp,
  signOut
}
