import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BasePage } from 'app-layouts'
import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import HandlerComponent from 'app-components/HandlerComponent'
import { GQL } from 'app-gql'
import { useMutation, useQuery } from '@apollo/react-hooks'
import TestGroupsList from 'app-screens/tests/groups/TestGroupsList'

const TestGroups = () => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GQL.Test.queries.examTaskGroupAssignmentList)

  const [deleteGroup] = useMutation(GQL.Test.mutations.deleteExamTaskGroupAssignment, {
    onCompleted: (result) => {
    },
    refetchQueries: [{ query: GQL.Test.queries.examTaskGroupAssignmentList }]
  })

  const [resetGroup] = useMutation(GQL.Test.mutations.resetQuestionnaireCounter)

  const onDeleteClicked = useCallback(
    async groupId => {
      if (deleteGroup) {
        await deleteGroup({
          variables: {
            _id: groupId
          }
        })
      }
    },
    [data]
  )

  const onResetClicked = useCallback(
    async groupId => {
      if (resetGroup) {
        await resetGroup({
          variables: {
            groupAssignmentIds: groupId
          }
        })
      }
    },
    [data]
  )

  return (
    <React.Fragment>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t('common.page.tests')}</BreadcrumbItem>
        <BreadcrumbItem active>{t('tests.groups')}</BreadcrumbItem>
        <li className='breadcrumb-menu ml-auto mr-0'>
          <div className='btn-group' role='group'>
            <Link to='/test/groups/create' className='btn btn-transparent'>
              <FontAwesomeIcon className='nav-icon' icon={faListAlt}/>
              <span className='ml-1'>{t('common.create')}</span>
            </Link>
          </div>
        </li>
      </BreadcrumbCommon>
      <BasePage title={t('tests.groups')}>
        <HandlerComponent loading={loading} error={error}/>
        {
          data && <TestGroupsList
            groups={data.examTaskGroupAssignmentList}
            onDeleteClicked={onDeleteClicked}
            onResetClicked={onResetClicked}
          />
        }
      </BasePage>
    </React.Fragment>
  )
}

export default TestGroups
