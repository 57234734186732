import React from 'react'

import { i18n } from 'app-core'
import SimpleError from 'app-components/errors/SimpleError'

const GraphqlError = (props) => {
  if (!props.error) return null

  const error = props.error
  let message

  try {
    message = i18n.t(`errors.graphQLErrors.${error.extensions.code}`, {
      defaultValue: props.error.message
    })
  } catch (e) {
    message = error.message
  }

  return <SimpleError className={props.className} message={message} />
}

export default GraphqlError
