import gql from "graphql-tag";

export const examTaskAggregationList = gql`
  query examTaskAggregationList {
    examTaskAggregationList {
      _id
      userId
      fileName
      createdAt
    }
  }
`;
