import gql from 'graphql-tag'
import { Fragments } from 'app-gql/fragments'

export const test = gql`
  query examTaskAdmin($id: ID!) {
    examTaskAdmin(id: $id) {
      ...testFull
    }
  }
  ${Fragments.testFull}
`
