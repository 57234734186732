import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'

import configureStore from 'app-store'
import { Apollo } from 'app-core'

import Main from './Main'

import 'app-styles/index.scss'

const history = createBrowserHistory()
const store = configureStore(undefined, history)

ReactDOM.render(
  <Main
    client={Apollo.client}
    history={history}
    store={store}
  />,
  document.getElementById('root')
)
