import React from 'react'
import { Field } from 'redux-form'
import cx from 'classnames'

import { i18n } from 'app-core'
import { validators } from 'app-libs'
import { FormTextAreaField } from 'app-components/forms/FormTextAreaField'
import { isAdmin, isEmployee } from 'app-models/UsersType'
import SortableItem from 'app-screens/tests/components/Questions/SortableItem'
import { getQuestion } from 'app-libs/helpers'

import SortableAnswers from './SortableAnswers'

import styles from './QuestionItem.module.scss'
import AnswersType from 'app-models/AnswersType'
import Badge from 'reactstrap/es/Badge'

const QuestionItem = ({
  questionIdx,
  question,
  userRole,
  onRemoveAnswer,
  onAddAnswerClick,
  onEditClick,
  onRemoveQuestion,
  onSortEnd,
  onAnswerClicked,
  onEditAnswer
}) => {
  const { type } = question

  const isTypeText = type => type === AnswersType.TEXT_ANSWER
  const filterControl = callback => {
    return isAdmin(userRole) ? callback() : ''
  }

  const drawEditButton = () => {
    return (
      <button className='btn btn-warning text-nowrap mr-2 btn-sm' type='button' onClick={ () => { onEditClick(question) } }>
        { i18n.t('tests.editQuestion') }
      </button>)
  }

  const drawAddButton = () => {
    return isTypeText(type) ? '' : (
      <button className='btn btn-success text-nowrap mr-2 btn-sm' type='button' onClick={ () => { onAddAnswerClick(question) } }>
        { i18n.t('tests.addAnswer') }
      </button>)
  }

  const drawDeleteButton = () => {
    return (
      <button className='btn btn-danger text-nowrap btn-sm' type='button' onClick={() => onRemoveQuestion(question)} >
        { i18n.t('tests.deleteQuestion') }
      </button>
    )
  }

  const drawTextArea = questionIdx => {
    return (
      <Field
        name={`Question${questionIdx}TextArea`}
        component={FormTextAreaField}
        validate={[validators.required]}
      />
    )
  }

  const onAnswersSortEnd = ({ oldIndex, newIndex }) => {
    onSortEnd({ questionId: question._id, oldIndex, newIndex })
  }

  return <SortableItem disabled={isEmployee(userRole)} key={`item-${questionIdx}`} index={questionIdx} value={
    <li key={questionIdx} className={cx('list-group-item', styles.questionListItem)}>
      <div className="d-flex align-items-center">
        <div>
          <strong>{(questionIdx + 1) + '. ' + getQuestion(question)}</strong>
          {!isEmployee(userRole) && (
            <Badge className='ml-2 p-1' color="secondary">{i18n.t(`common.labels.questionObjective.${question.calcType}`)}</Badge>
          )}
        </div>
        <div className="d-flex align-items-center ml-auto">
          <div className="question-controls-buttons">
            { filterControl(() => drawEditButton()) }
            { filterControl(() => drawAddButton()) }
            { filterControl(() => drawDeleteButton()) }
          </div>
        </div>
      </div>
      <div className={'mt-2'}>
        {
          isTypeText(type)
            ? drawTextArea(questionIdx)
            : (
              <SortableAnswers
                question={question}
                onRemoveAnswer={onRemoveAnswer}
                onSortEnd={onAnswersSortEnd}
                userRole={userRole}
                onAnswerClicked={onAnswerClicked}
                onEditAnswer={onEditAnswer}
              />)
        }
      </div>
    </li>
  } />
}

export default QuestionItem
