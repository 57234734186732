import React from "react";
import { Table } from "reactstrap";
import { i18n } from "app-core";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "app-hooks";
import { isCompanyAdmin, isEmployee, isSuperAdmin } from "app-models/UsersType";

const getListItems = (
  testsData,
  t,
  onCheckResultClick,
  downloadCsvClicked,
  isAdmin
) => {
  const { language } = i18n.instance;

  return testsData.map((value) => {
    const titleTranslation = value.title.find(({ lang }) => lang === language);
    const descriptionTranslation = value.description.find(
      ({ lang }) => lang === language
    );

    return (
      <tr key={value._id} className="font-sm">
        <td>{titleTranslation && titleTranslation.text}</td>
        <td>{descriptionTranslation && descriptionTranslation.text}</td>
        <td>
          <button
            className="btn btn-info btn-sm"
            type="button"
            onClick={() => onCheckResultClick(value._id)}
          >
            {t("tests.checkResults")}
          </button>
          {isAdmin && (
            <button
              className="btn btn-info btn-sm ml-2"
              type="button"
              onClick={() => downloadCsvClicked(value._id)}
            >
              {"CSV"}
            </button>
          )}
        </td>
      </tr>
    );
  });
};

const TestsList = ({ testsData, onCheckResultClick, downloadCsvClicked }) => {
  const { t } = useTranslation();
  const { user } = useAuthorization();
  const isAdmin = isSuperAdmin(user.role);

  return (
    <Table borderless responsive className="table-hover">
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {getListItems(
          testsData,
          t,
          onCheckResultClick,
          downloadCsvClicked,
          isAdmin
        )}
      </tbody>
    </Table>
  );
};

export default TestsList;
