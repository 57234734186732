import gql from 'graphql-tag'

export const signUp = gql`
  mutation signUp(
    $companyId: String!, 
    $email: String!, 
    $localization: String!, 
    $name: String!, 
    $password: String!, 
    $surname: String!
  ) {
    signUp(
      companyId: $companyId,
      email: $email,
      localization: $localization,
      name: $name,
      password: $password,
      surname: $surname
    ) {
      localization
      refreshToken
      token
    }
  }
`
