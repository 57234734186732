export const required = value =>
  value || typeof value === 'number' ? undefined : 'Required'

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined

export const zipCode = value =>
  value && !/^([0-9]{5})$/i.test(value)
    ? 'Invalid zip code , must be 5 digits'
    : undefined

export const alpha = value =>
  value && /[^a-zA-Z]/i.test(value) ? 'Only alphabetic characters' : undefined

export const url = value =>
  !value || /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i.test(value)
    ? undefined : 'Invalid URL'

export const inRange = (min, max) => {
  return (value) => {
    if (value && value.toString().length > max) {
      return `Max length: ${max}`
    }
    if (value && value.toString().length < min) {
      return `Min length: ${min}`
    }
    return undefined
  }
}

export const passwordsMustMatch = (value, allValues) => {
  return value !== allValues.password ? 'Passwords do not match' : undefined
}
