import I18n from './i18n'

import locales from 'app-locales'
import { initApolloClient } from './gql'
import Configuration from './Configuration'
import { api, restApi } from './api'

export const configuration = new Configuration(process.env)
export const Apollo = initApolloClient(configuration)
export const i18n = new I18n(locales)
export { api }
export { restApi }
