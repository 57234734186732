import { Actions } from './actions'
import reducer from './reducers'

export const SidebarActions = {
  sidebar: Actions
}

export const sidebarReducer = {
  sidebar: reducer
}
