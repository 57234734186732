import { Actions } from './actions'
import reducer from './reducers'

export const AuthorizationActions = {
  authorization: Actions
}

export const authorizationReducer = {
  authorization: reducer
}
