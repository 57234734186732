import React from 'react'
import { FormFeedback, FormGroup, Label } from 'reactstrap'
import Select from 'app-components/Select'

export default function FormSelectMappingField ({
  label,
  virtualized,
  mapping,
  isLoading,
  placeholder,
  input,
  disabled,
  isMulti,
  meta: { touched, error }
}) {
  const options = Array.isArray(mapping) ? mapping : Object.keys(mapping).reduce((memo, key) => {
    memo.push({ value: key, label: mapping[key] })
    return memo
  }, [])

  const invalid = error && touched
  const { name, value, onChange, onBlur } = input

  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <div className='InputContainer'>
        <Select
          {...input}
          id={name}
          virtualized={virtualized}
          isMulti={isMulti}
          options={options}
          invalid={invalid}
          isLoading={isLoading}
          placeholder={placeholder}
          disabled={disabled}
          value={isMulti
            ? value
            : options.find((option) => option === value || option.value === value.value) || ''
          }
          onChange={onChange}
          onBlur={() => onBlur(value)}
        />
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </div>
    </FormGroup>
  )
}
