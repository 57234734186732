import gql from 'graphql-tag'

export const updateCompany = gql`
  mutation updateCompany(
    $_id: ID!,
    $name: String,
    $address: AddressInputType,
    $email: String,
    $phone: String,
    $contactName: String,
    $companyLogo: Upload,
    $loginPermitted: Boolean
  ) {
    updateCompany(
      _id: $_id,
      name: $name,
      address: $address,
      email: $email,
      phone: $phone,
      contactName: $contactName,
      companyLogo: $companyLogo,
      loginPermitted: $loginPermitted
    ) {
      status
      data {
        _id
      }
    }
  }
`
