let currentStoreProxy

export function setup (store) {
  currentStoreProxy = store
}

export function getAuthorizationToken () {
  if (currentStoreProxy == null) {
    return null
  }

  return currentStoreProxy.getState().authorization.token
}

export function getRefreshToken () {
  if (currentStoreProxy == null) {
    return null
  }

  return currentStoreProxy.getState().authorization.refreshToken
}

export function dispatch (action) {
  if (currentStoreProxy == null) {
    return null
  }
  return currentStoreProxy.dispatch(action)
}
