import swal from '@sweetalert/with-react'
import React from 'react'

export const ImportEmployeeFromCSV = (t, onClick) => {
  let selectedFile = null

  return swal({
    icon: 'info',
    title: t('screens.employee.import_csv_alert_title'),
    buttons: ['Cancel', 'Upload'],
    content: (
      <div className='d-flex flex-row flex-nowrap align-items-center justify-content-center'>
        <input name="companyLogo" type="file" className='w-100' accept=".xlsx" onChange={
          (event) => {
            selectedFile = event.currentTarget.files[0]
          }
        } />
      </div>
    )
  }).then((confirmed) => {
    if (confirmed != null) {
      onClick(selectedFile)
    }
  })
}
