import swal from '@sweetalert/with-react'
import { dispatch } from 'app-core/storeProxy'
import { AuthorizationActions } from 'app-store'

export function initMutationFunc (client) {
  return async ({ query, variables = null }) => {
    const result = await client.mutate({
      mutation: query,
      variables: variables
    })

    if (result.errors != null && result.errors.length > 0) {
      result.errors.forEach(error => {
        //swal.error("errore", error.message, "error")

        if (error.state != null && error.state.reason === 'jwt_expired') {
          dispatch(AuthorizationActions.authorization.signOut())
        }

        console.warn(error.message)
      })
    }

    return result
  }
}
