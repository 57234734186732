import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Collapse, Form, FormGroup, Row } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'

import { AVAILABLE_LOCALIZATIONS } from 'app-models/localizations'
import { FormGroupTextField, FormSelectField } from 'app-components/forms'
import { validators } from 'app-libs'
import CardHeader from 'reactstrap/lib/CardHeader'
import Card from 'reactstrap/lib/Card'
import { i18n } from 'app-core'
import CardBody from 'reactstrap/es/CardBody'

const TestGroupForm = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  employeesOptions,
  testsOptions,
  error,
  editState
}) => {
  const { t } = useTranslation()
  const [expandedLocales, setExpandedLocales] = useState(AVAILABLE_LOCALIZATIONS)

  const handleCollapseClick = useCallback(
    (locale) => {
      if (!expandedLocales.includes(locale)) {
        setExpandedLocales([...expandedLocales, locale])
      } else {
        setExpandedLocales(expandedLocales.filter(l => l !== locale))
      }
    },
    [expandedLocales]
  )

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}

        <Row form>
          <Col>
            <h4 className='mb-4'>{i18n.t('tests.form.info')}</h4>

            {AVAILABLE_LOCALIZATIONS.map((locale) => {
              return (
                <Card key={locale} className='mb-1'>
                  <CardHeader
                    style={{ cursor: 'pointer' }}
                    onClick={() => { handleCollapseClick(locale) }}
                  >
                    {locale}
                  </CardHeader>
                  <Collapse isOpen={expandedLocales.includes(locale)} className='p-3'>
                    <Field
                      name={`title.${locale}.text`}
                      type='text'
                      label={t('common.labels.title')}
                      labelClasses='text-muted'
                      component={FormGroupTextField}
                      validate={[validators.required]}
                    />

                    <Field
                      name={`description.${locale}.text`}
                      type='textarea'
                      label={t('common.labels.description')}
                      labelClasses='text-muted'
                      component={FormGroupTextField}
                      validate={[validators.required]}
                    />
                  </Collapse>
                </Card>
              )
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className='mb-4 mt-4'>{i18n.t('tests.assignEmployeesToGroup')}</h4>
            <Card>
              <CardHeader>{t('tests.selectEmployees')}</CardHeader>
              <CardBody>
                <Row form>
                  <Col>
                    <Field
                      name='employeesList'
                      labelClasses='text-muted'
                      mapping={employeesOptions}
                      component={FormSelectField}
                      isLoading={false}
                      isMulti
                      validate={[validators.required]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className='mb-4'>{i18n.t('tests.assignTestsToGroup')}</h4>
            <Card>
              <CardHeader>{t('tests.selectTests')}</CardHeader>
              <CardBody>
                <Row form>
                  <Col>
                    <Field
                      name='testsList'
                      labelClasses='text-muted'
                      mapping={testsOptions}
                      component={FormSelectField}
                      isLoading={false}
                      isMulti
                      validate={[validators.required]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <FormGroup className='d-flex align-items-center justify-content-between m-0'>
          <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
            {editState ? i18n.t('common.labels.update') : i18n.t('common.labels.create')}
          </Button>
        </FormGroup>
      </Form>
    </>
  )
}

export default reduxForm({
  form: 'TestGroupForm'
})(TestGroupForm)
