import React from 'react'
import { Button, NavItem, NavLink } from 'reactstrap'
import { NavLink as NavLinkRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAuthorization } from 'app-hooks'
import SIDEBAR_ITEM_TYPE from './SidebarItemType'
import { SidebarActions } from 'app-store'

const NavLinkTemplate = ({ data }) => {
  return (
    <NavItem className="c-sidebar-nav-item">
      <NavLink className="c-sidebar-nav-link" activeClassName="c-active" to={data.to} tag={NavLinkRouter}>
        {data.icon ? <i className={`c-sidebar-nav-icon ${data.icon || ''}`} /> : <FontAwesomeIcon className='c-sidebar-nav-icon' icon={data.faIcon} />}
        {data.text}
      </NavLink>
    </NavItem>
  )
}

const NavTitleTemplate = ({ value }) => {
  return <li className='c-sidebar-nav-title'>{value.text || value}</li>
}

const NavCategoryTemplate = ({ data, user }) => {
  return data.items.map((linkData) => {
    return linkData.resolver(user) ? <NavLinkTemplate data={linkData} key={`sub-link-${Math.random()}`} /> : null
  })
}

const SidebarMenu = props => {
  const { user } = useAuthorization()
  const { data, visible, foldState, foldSidebar, unfoldSidebar } = props

  // invisible style be default
  let sidebarClass = 'c-sidebar c-sidebar-dark c-sidebar-fixed'
  if (visible) {
    sidebarClass = cx(sidebarClass, { 'c-sidebar-show c-sidebar-lg-show': true })
    sidebarClass = cx(sidebarClass, { 'c-sidebar-minimized': foldState })
  }

  return (
    <>
      <div className={ sidebarClass }>
        <ul className='c-sidebar-nav ps ps--active-y'>
          {data.map((value, indx) => {
            if (!value.resolver(user)) return null

            if (value.type === SIDEBAR_ITEM_TYPE.LINK) {
              return <NavLinkTemplate
                data={value}
                key={`link-${Math.random()}`}
              />
            }
            if (value.type === SIDEBAR_ITEM_TYPE.TITLE) {
              return (
                <NavTitleTemplate value={value} key={`title-${Math.random()}`} />
              )
            }
            if (value.type === SIDEBAR_ITEM_TYPE.CATEGORY) {
              return (
                <NavCategoryTemplate key={`cat-link-${Math.random()}`} data={value} user={user} />
              )
            }
            return null
          })}
        </ul>
        { <Button className='c-sidebar-minimizer c-class-toggler'
          type='button' onClick={ () => { foldState ? unfoldSidebar() : foldSidebar() } } /> }
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    foldState: state.sidebar.foldState,
    visible: state.sidebar.visible
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    foldSidebar: () => {
      dispatch(SidebarActions.sidebar.fold())
    },
    unfoldSidebar: () => {
      dispatch(SidebarActions.sidebar.unfold())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
