import swal from '@sweetalert/with-react'
import { dispatch } from 'app-core/storeProxy'
import { AuthorizationActions } from 'app-store'

export function initQueryFunc (client) {
  return async ({ query, variables = null }) => {
    const { data, refetch, loading, errors } = await client.query({
      query: query,
      variables: variables
    })

    if (errors != null && errors.length > 0) {
      errors.forEach(error => {
        
        if (error.state != null && error.state.reason === 'jwt_expired') {
          //swal.error("errore", "Invalid token", "error")
          dispatch(AuthorizationActions.authorization.signOut())
        }

        console.warn(error.message)
      })
    }

    return [data, { refetch, loading, errors }]
  }
}
