import { Apollo } from 'app-core'
import { GQL } from 'app-gql'

export default async (values) => {
  const result = await Apollo.mutation({
    query: GQL.Authorization.mutations.signUp,
    variables: {
      ...values
    }
  })

  return result
}
