import UsersType from './UsersType'

const getAllowedUserTypesFor = (userType) => {
  switch (userType) {
    case UsersType.ADMIN: {
      return [UsersType.COMPANY_ADMIN]
    }
    case UsersType.COMPANY_ADMIN: {
      return [UsersType.COMPANY_ADMIN, UsersType.CLIENT_COMPANY_ADMIN, UsersType.EMPLOYEE]
    }
    default: {
      return [userType]
    }
  }
}

const PRIVACY_TYPE = {
  USERS: 'users',
  EMPLOYEES: 'employees',
  COMPANIES: 'companies',
  TESTS: 'tests',
  STORES: 'stores',
  DEVICES: 'devices'
}

const PRIVACY_ACTIONS = {
  VIEW: 'view',
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete'
}

export {
  getAllowedUserTypesFor,
  PRIVACY_TYPE,
  PRIVACY_ACTIONS
}
