import React, { Component } from "react";
import { CardBody, Col, Row, Table } from "reactstrap";
import { BasePage } from "app-layouts";
import HandlerComponent from "app-components/HandlerComponent";
import { Apollo, i18n } from "app-core";
import { GQL } from "app-gql";
import UsersType from "app-models/UsersType";
import QuestionnaireStatus from "app-models/QuestionnaireStatus";
import { getTestTitle } from "app-libs/helpers";
import moment from "moment";

class TestResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      history: props.history,
      usersList: null,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const {
      params: { id },
    } = match;

    const { data } = await Apollo.mutation({
      query: GQL.User.queries.usersList,
      variables: {
        filterRole: UsersType.EMPLOYEE,
        assignedExamTasks: {
          examTaskIds: [id],
        },
      },
    });

    const state = { testId: id };

    if (data.usersList) {
      state.usersList = data.usersList;
    }
    this.setState(state);
  }

  renderContent() {
    const { usersList } = this.state;

    if (!usersList) {
      return <HandlerComponent loading />;
    }
    return (
      <CardBody>
        <Row>
          <Col>
            <Table borderless responsive className="table-hover">
              <thead>
                <tr>
                  <th>{i18n.t("common.labels.employerResults.title")}</th>
                  <th>{i18n.t("common.labels.employerResults.performedBy")}</th>
                  <th>{i18n.t("common.labels.employerResults.status")}</th>
                  <th>{i18n.t("common.labels.employerResults.performedAt")}</th>
                  <th>{i18n.t("common.labels.employerResults.result")}</th>
                  <th className="text-center">
                    {i18n.t("common.labels.actions")}
                  </th>
                </tr>
              </thead>
              <tbody>{this.getListItems(usersList)}</tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    );
  }

  getListItems(usersList) {
    const { testId } = this.state;

    return usersList.map((user) => {
      const { questionnaires } = user;
      const test = questionnaires.find((q) => q.parentId === testId);

      let status = "";
      let finishedAt = "";
      let performedBy = "";
      let result = "";

      if (test) {
        status = this.getStatusText(test.status);
        finishedAt = moment
          .unix(test.completedAt / 1000)
          .format("DD-MM-YYYY HH:mm:ss");
        performedBy = user.name + " " + user.surname;
        result = test.resultPoints;
      }
      return (
        <>
          {test ? (
            <tr key={1} className="font-sm">
              <td>{getTestTitle(test)}</td>
              <td>{performedBy}</td>
              <td>
                <span className="badge ml-0 badge-info">{status}</span>
              </td>
              <td>{finishedAt}</td>
              <td>{result}</td>
              <td>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-info btn-sm"
                    type="button"
                    onClick={() =>
                      this.state.history.push(`/test/result/${test._id}`)
                    }
                  >
                    {i18n.t("common.labels.employerResults.view")}
                  </button>
                </div>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </>
      );
    });
  }

  getStatusText(status) {
    switch (status) {
      case QuestionnaireStatus.INIT:
        return i18n.t("tests.status.init");
      case QuestionnaireStatus.IN_PROGRESS:
        return i18n.t("tests.status.inProgress");
      case QuestionnaireStatus.FINISHED:
        return i18n.t("tests.status.finished");
      default:
        return "";
    }
  }

  render() {
    return (
      <div className="mt-3">
        <BasePage title={i18n.t("tests.testResults")}>
          {this.renderContent()}
        </BasePage>
      </div>
    );
  }
}

export default TestResults;
