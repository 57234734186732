import React, { Component } from "react";
import { CardBody } from "reactstrap";
import { BasePage } from "app-layouts";
import HandlerComponent from "app-components/HandlerComponent";
import { Heading } from "app-components";
import { Apollo, i18n } from "app-core";
import { GQL } from "app-gql";
import { RadarChart } from "app-components/charts";
import withLocalization from "app-hoc/withLocalization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { IMAGES } from "app-images";

class TestFinish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      points: null,
      detailed: null,
      user: null,
      questions: null,
      history: props.history,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const {
      params: { id },
    } = match;

    const { data, errors } = await Apollo.mutation({
      query: GQL.Test.queries.resultQuestionnaire,
      variables: {
        id: id,
      },
    });

    if (errors) {
      window.location.href = "/";
    }

    if (data.resultQuestionnaire) {
      this.setState({
        points: data.resultQuestionnaire.points,
        detailed: data.resultQuestionnaire.detailed,
        user: data.resultQuestionnaire.user,
      });
    }
  }

  async onPrintClick() {
    const css = `
.shape {
	fill-opacity: .3
}

.shape:hover {
	fill-opacity: .65;
	z-index: 100
}

.scale {
	fill: #fafafa;
	stroke: #999;
	stroke-width: .2
}

.axis {
	stroke: #555;
	stroke-width: .2
}

.dot {
	fill: #fff;
	stroke: #e7e8e7;
	r: 5;
	-webkit-transition: r .2s;
	-o-transition: r .2s;
	transition: r .2s
}

.dot:hover {
	stroke: #bbb;
	r: 8
}

.caption {
	fill: #444;
	font-weight: 400;
	text-shadow: 1px 1px 0 #fff
}
pre {
  border: none;
  white-space: pre-wrap; /* Preserve line breaks */
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
}


/*# sourceMappingURL=index.css.map*/
    `;
    const printableArea = document.getElementById("printableArea");
    const doc = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=640,toolbar=0,scrollbars=1,status=0"
    );
    doc.document.write(
      '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">'
    );
    doc.document.write(`<style>${css}</style>`);
    doc.document.write('<div class="m-3">');
    doc.document.write(printableArea.innerHTML);
    doc.document.write("</div>");

    const removeElementsByClass = (className) => {
      const elements = doc.document.getElementsByClassName(className);
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    };
    const removeElementById = (id) => {
      doc.document.getElementById(id).remove();
    };

    // wait to css finished
    await new Promise((resolve) => setTimeout(resolve, 1000));

    doc.focus();
    doc.print();
    doc.close();
  }

  renderDescription() {
    const { detailed } = this.state;
    const { localization } = this.props;

    const orderedDetailed = detailed;

    return [
      "concezione_dell_obiettivo",
      "comp_anticipazione",
      "comp_pianificazione",
      "comp_gest_asp_crit_error",
      "comp_interattiva_comunicativa",
      "comp_gestione_lavoro_di_squadra",
      "comp_conc_svilu",
      "comp_gestione_collocazione_dei_ruoli",
      "comp_generazione_della_cultura_di_squadra",
      "competenza_di_analisi_del_contesto",
      "competenza_di_valutazione",
    ].map((objective) => {
      return detailed.map((detail) => {
        if (detail.objective === objective) {
          const question = JSON.parse(detail.question);
          const answer = JSON.parse(detail.answer);
          return (
            <>
              {question && answer && (
                <div key={detail._id} className="mb-5">
                  <div className="w-100 text-center">
                    <h2>
                      {i18n.t(
                        `common.labels.questionObjective.${detail.objective}`
                      )}
                    </h2>
                  </div>
                  <hr />
                  {detail.objective !== "concezione_dell_obiettivo" && (
                    <div className="row justify-content-start align-items-center mt-3 mb-3">
                      <div className="col-12 col-md-3 ">
                        <strong>{i18n.t("tests.whatWeMean")}</strong>
                      </div>
                      <div className="col-12 col-md-9 text-justify">
                        {question && question.resultExplanation && (
                          <pre className="pre-new-lines-formatter">
                            {question.resultExplanation[localization] ||
                              question.resultExplanation.en}
                          </pre>
                        )}
                      </div>
                    </div>
                  )}
                  <>
                    <div className="row justify-content-start align-items-center mt-3 mb-3">
                      <div className="col-12 col-md-3 col-sm-12">
                        <strong>{i18n.t("tests.yourEvaluation")}</strong>
                      </div>
                      <div className="col-12 col-md-9 col-sm-12 text-justify">
                        {i18n.t(
                          `common.labels.responseOptionsLabels.${detail.answerType}`
                        )}
                        :<br />
                        {answer &&
                          answer.resultExplanation &&
                          (answer.resultExplanation[localization] ||
                            answer.resultExplanation.en)}
                      </div>
                    </div>
                  </>
                </div>
              )}
            </>
          );
        }
      });
    });
  }

  renderContent() {
    const { points, detailed, user } = this.state;
    const labels = [];
    const data = [];
    let color = "blue";
    let min = 0;
    let max = 3;
    const toBeOrderedLabels = [];

    let captions = {};
    let dataset = {};

    if (points === null || points === undefined) {
      return <HandlerComponent loading />;
    } else {
      detailed.forEach((item) => {
        if (item.objective !== "concezione_dell_obiettivo") {
          toBeOrderedLabels.push({
            label: i18n.t(`common.labels.questionObjective.${item.objective}`),
            points: item.graphPoints,
          });
          min = 0;
          max = 3;
        } else {
          color = i18n.t(
            `common.labels.responseOptionsColors.${item.answerType}`
          );
        }
      });
      let i = 0;
      toBeOrderedLabels
        .sort((a, b) => b.label.length - a.label.length)
        .map((e) => {
          captions[`label${i}`] = e.label;
          dataset[`label${i}`] = e.points / 3;
          i++;
        });
    }

    return (
      <Heading className="mb-1">
        <div className="d-flex justify-content-center align-items-center print-only">
          <img src={IMAGES.loginPageLogo} height="240px" alt="" />
        </div>
        {/* user info */}
        <div className="mb-3">
          <strong className="clearfix">
            {user.name} {user.surname}
          </strong>
          <small>{user.email}</small>
        </div>

        {/* test results */}
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div style={{ fontWeight: "bold", fontSize: "4rem" }}>{points}</div>
          <small>{i18n.t("common.labels.totalPoints")}</small>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div style={{ width: "600px" }} className="m-4">
            <RadarChart captions={captions} dataset={dataset} color={color} />
          </div>
        </div>

        {this.renderDescription()}
      </Heading>
    );
  }

  render() {
    return (
      <div className="mt-3">
        <BasePage
          title={i18n.t("common.labels.testResults")}
          className="no-print"
        >
          <a
            href="#"
            onClick={() => this.onPrintClick()}
            className="btn no-print"
          >
            <FontAwesomeIcon icon={faPrint} />
          </a>
          <CardBody id="printableArea">{this.renderContent()}</CardBody>
        </BasePage>
      </div>
    );
  }
}

export default withLocalization(TestFinish);
