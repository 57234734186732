import * as queries from './queries'
import * as mutations from './mutations'

export const User = {
  mutations: {
    ...mutations
  },
  queries: {
    ...queries
  }
}
