import React from 'react'

import NetworkError from './NetworkError'
import GraphqlError from './GraphqlError'

const GenericGraphQLError = (props) => {
  if (!props.error) return null

  const { error, className } = props
  const { graphQLErrors, networkError } = error

  const errors = []

  if (networkError) {
    errors.push(<NetworkError key='network-error' className={className} error={networkError} />)
  } else if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError, idx) => {
      errors.push(<GraphqlError key={idx} className={className} error={graphQLError} />)
    })
  }

  return (
    <React.Fragment>
      {errors}
    </React.Fragment>
  )
}

export default GenericGraphQLError
