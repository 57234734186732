import React from 'react'

export default Component =>
  class extends React.Component {
    handleSubmit = async (values) => {
      const { signIn } = this.props
      if (signIn) {
        await signIn(values)
      }
    }

    render () {
      const errors = { graphQLErrors: this.props.errors }

      return <Component onSubmit={this.handleSubmit} errors={errors}/>
    }
  }
