import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, Collapse, Form, FormGroup, Row } from "reactstrap";
import { change, Field, formValueSelector, reduxForm } from "redux-form";

import { AVAILABLE_LOCALIZATIONS } from "app-models/localizations";
import { FormGroupTextField, FormSelectField } from "app-components/forms";
import { validators } from "app-libs";
import CardHeader from "reactstrap/lib/CardHeader";
import Card from "reactstrap/lib/Card";
import { i18n } from "app-core";
import { FormCheckboxField } from "app-components/forms/FormCheckboxField";
import { useQuery } from "@apollo/react-hooks";
import { GQL } from "app-gql";
import { connect } from "react-redux";
import { mapValuesToSelectOptions } from "app-libs/helpers";

const FORM_NAME = "TestForm";

const FORM_FIELDS = {
  TEST_ALGORITHM: "algorithm",
  ALGORITHM_USERS_GROUP: "algorithmAdresses",
};

const TestForm = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  editState,
  error,
  setFieldValue,
  values,
}) => {
  const { t } = useTranslation();
  const [expandedLocales, setExpandedLocales] = useState(
    AVAILABLE_LOCALIZATIONS
  );

  const handleCollapseClick = useCallback(
    (locale) => {
      if (!expandedLocales.includes(locale)) {
        setExpandedLocales([...expandedLocales, locale]);
      } else {
        setExpandedLocales(expandedLocales.filter((l) => l !== locale));
      }
    },
    [expandedLocales]
  );

  /* algorithm logic */
  const { loading: taskAlgorithmLoading, data: taskAlgorithmData } = useQuery(
    GQL.Test.queries.examTaskAlgorithms
  );
  const selectAlgorithms = useMemo(() => {
    if (taskAlgorithmData) {
      return mapValuesToSelectOptions(taskAlgorithmData.examTaskAlgorithms);
    }
  }, [taskAlgorithmData]);

  useEffect(() => {
    if (taskAlgorithmData) {
      const firstValue = taskAlgorithmData.examTaskAlgorithms[0];
      if (firstValue) {
        setFieldValue(
          "TestForm",
          FORM_FIELDS.TEST_ALGORITHM,
          mapValuesToSelectOptions(firstValue)
        );
      }
    }
  }, [taskAlgorithmData, setFieldValue]);

  const hasSelectedAlgorithm = useMemo(
    () => !!values.selectedAlgorithm,
    [values]
  );

  /* user groups */
  const { loading: usersGroupsLoading, data: usersGroupsData } = useQuery(
    GQL.Test.queries.examTaskAlgorithmsAddresses
  );
  const userGroupsOptions = useMemo(() => {
    if (usersGroupsData) {
      return mapValuesToSelectOptions(
        usersGroupsData.examTaskAlgorithmsAdresses
      );
    }
  }, [usersGroupsData]);

  return (
    <>
      <Form
        className="d-flex flex-column justify-content-between"
        onSubmit={handleSubmit}
      >
        {error && <Alert color="danger">{error.message}</Alert>}

        <Row form>
          <Col>
            <h4 className="mb-4">{i18n.t("tests.selectAlgorithm")}</h4>
            <div>
              <Field
                name={FORM_FIELDS.TEST_ALGORITHM}
                labelClasses="text-muted"
                mapping={selectAlgorithms || []}
                component={FormSelectField}
                isLoading={taskAlgorithmLoading}
                disabled={false}
                validate={[validators.required]}
              />
            </div>

            {hasSelectedAlgorithm && (
              <>
                <h4 className="mb-4">{i18n.t("tests.algorithmUserGroup")}</h4>
                <div>
                  <Field
                    name={FORM_FIELDS.ALGORITHM_USERS_GROUP}
                    labelClasses="text-muted"
                    mapping={userGroupsOptions || []}
                    component={FormSelectField}
                    isLoading={usersGroupsLoading}
                    disabled={false}
                    validate={[validators.required]}
                  />
                </div>
              </>
            )}

            <h4 className="mb-4">{i18n.t("tests.form.info")}</h4>
            {AVAILABLE_LOCALIZATIONS.map((locale) => {
              return (
                <Card key={locale} className="mb-4">
                  <CardHeader
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCollapseClick(locale);
                    }}
                  >
                    {locale}
                  </CardHeader>

                  <Collapse
                    isOpen={expandedLocales.includes(locale)}
                    className="p-3"
                  >
                    <Field
                      name={`title.${locale}.text`}
                      type="text"
                      label={t("common.labels.title")}
                      labelClasses="text-muted"
                      component={FormGroupTextField}
                      validate={[validators.required]}
                    />

                    <Field
                      name={`description.${locale}.text`}
                      type="textarea"
                      label={t("common.labels.description")}
                      labelClasses="text-muted"
                      component={FormGroupTextField}
                      validate={[validators.required]}
                    />
                  </Collapse>
                </Card>
              );
            })}
          </Col>
        </Row>
        <Row form className="mt-2 mb-3">
          <Col>
            <Field
              name="shuffle"
              label={t("tests.shuffleTestQuestions")}
              component={FormCheckboxField}
              isEnabled
            />
          </Col>
        </Row>
        <Row form className="mt-2 mb-3">
          <Col>
            <Field
              name="hideResults"
              label={t("tests.doNotShowResults")}
              component={FormCheckboxField}
              isEnabled
            />
          </Col>
        </Row>
        <FormGroup className="d-flex align-items-center justify-content-between m-0">
          <Button
            type="submit"
            color="primary"
            disabled={submitting || pristine || invalid}
          >
            {editState ? t("common.labels.update") : t("common.labels.create")}
          </Button>
        </FormGroup>
      </Form>
    </>
  );
};

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
  const selectedAlgorithm = selector(state, FORM_FIELDS.TEST_ALGORITHM);
  return {
    values: {
      selectedAlgorithm,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue: (form, field, value) => {
      return dispatch(change(form, field, value));
    },
  };
};

export default reduxForm({
  form: FORM_NAME,
})(connect(mapStateToProps, mapDispatchToProps)(TestForm));
