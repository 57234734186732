import gql from "graphql-tag";

export const examTaskGroupAssignmentList = gql`
  query examTaskGroupAssignmentList(
    $examTaskIds: [ID]
    $ids: [ID]
    $userIds: [ID]
  ) {
    examTaskGroupAssignmentList(
      examTaskIds: $examTaskIds
      ids: $ids
      userIds: $userIds
    ) {
      _id
      description {
        lang
        text
      }
      title {
        lang
        text
      }
      users {
        _id
      }
      examTasks {
        _id
        title {
          lang
          text
        }
      }
    }
  }
`;
