import axios from '../../axios'
import { configuration } from '../../index'

class ExamTaskRestApiService {
  async downloadExamTask (examTaskId, language) {
    return axios.get(`${configuration.apiUrl}/download_exam_pdf/${examTaskId}/${language}`, { responseType: 'blob' })
  }

  async downloadExamTaskResultAsCSV (examTaskId) {
    return axios.get(`${configuration.apiUrl}/download_test_result/${examTaskId}`, { responseType: 'blob' })
  }
}

export default new ExamTaskRestApiService()
