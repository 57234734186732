// import { push } from 'connected-react-router'

import { setup } from 'app-core/storeProxy'
import UsersType from 'app-models/UsersType'
import { api } from 'app-core'

export const ActionTypes = {
  INITIALIZE: 'session.start',
  RESET: 'session.reset'
}

const initialize = () => ({ type: ActionTypes.INITIALIZE })
const reset = () => ({ type: ActionTypes.RESET })

const start = () => {
  return async (dispatch, getState) => {
    setup({ dispatch, getState })

    await dispatch(initialize())

    await api.authorization.me()

    const { isAuthenticated, user } = getState().authorization

    if (isAuthenticated) {
      if (user.role === UsersType.ADMIN) {
        // await dispatch(push('/admin/users'))
      }
    }
  }
}

export const Actions = {
  start,
  reset
}
