import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'

import { faCheckCircle, faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAdmin } from 'app-models/UsersType'

const getListItems = (data, deleteCallBack) => {
  return data.map((value) => {
    return (
      <tr key={value._id} className='font-sm'>
        <td>{value.name} {value.surname}</td>
        <td>{value.email}</td>
        <td>{value.role}</td>
        <td>{value.company && value.company.name}</td>
        <td className='text-center'>
          {value.status === 'ACTIVE' ? <FontAwesomeIcon icon={faCheckCircle} className='info-color'/>
            : <FontAwesomeIcon icon={faTimesCircle} className='danger-color'/>}
        </td>
        <td>
          <div className='d-flex justify-content-center'>
            <Link
              to={`/user/edit/${value._id}`}
              className='btn btn-ghost-info'
            >
              <FontAwesomeIcon icon={faUserEdit}/>
            </Link>
            <button
              className='btn btn-ghost-danger'
              type='button'
              onClick={() => deleteCallBack && deleteCallBack(value._id)}
              disabled={!isAdmin(value.role)}
            >
              <FontAwesomeIcon icon={faTrashAlt}/>
            </button>
          </div>
        </td>
      </tr>
    )
  })
}

const UsersList = ({ users, deleteCallBack }) => {
  return (
    <Table borderless responsive className='table-hover'>
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Role</th>
          <th>Company</th>
          <th className='text-center'>Active</th>
          <th className='text-center'>Action</th>
        </tr>
      </thead>
      <tbody>
        {getListItems(users, deleteCallBack)}
      </tbody>
    </Table>
  )
}

export default UsersList
