import { useMutation } from '@apollo/react-hooks'
import { GQL } from 'app-gql'

const useDeleteCompany = ({ onComplete, refetchQueries }) => {
  return useMutation(GQL.Company.mutations.deleteCompany, {
    onCompleted (data) {
      onComplete && onComplete(data)
    },
    refetchQueries: refetchQueries ? [{ query: refetchQueries }] : null
  })
}

export default useDeleteCompany
