import React, { useMemo } from "react";
import PropTypes from "prop-types";
import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";

const RadarChart2 = (props) => {
  const { captions, dataset, color } = props;

  const dataset1 = {
    data: dataset,
    meta: { color: color, class: "my-custom-class" },
  };

  const options = {
    captionProps: () => ({
      textAnchor: "middle",
      fontSize: 12,
      fontFamily: "Arial",
    }),
    captionMargin: 200,
  };

  return (
    <>
      <RadarChart
        captions={captions}
        data={[dataset1]}
        size={600}
        options={options}
      />
    </>
  );
};

export default RadarChart2;
