import { Table } from 'reactstrap'
import React from 'react'
import { getTestTitle } from 'app-libs/helpers'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { i18n } from 'app-core'
import { useHistory } from 'react-router-dom'

const EmployeeResult = ({ questionnaires }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const viewResult = (id) => {
    history.push(`/test/result/${id}`)
  }

  const getResultItems = () => {
    return questionnaires.map((value) => {
      return (
        <tr key={value._id} className='font-sm'>
          <td>{getTestTitle(value)}</td>
          <td>{moment.unix(value.completedAt / 1000).format('DD-MM-YYYY HH:mm:ss')}</td>
          <td>{value.resultPoints}</td>
          <td>
            <div className='d-flex justify-content-center'>
              <button className='btn btn-info btn-sm' type='button' onClick={() => viewResult(value._id)}>
                {i18n.t('common.labels.employerResults.view')}
              </button>
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <Table borderless responsive className='table-hover'>
      <thead>
        <tr>
          <th>{t('common.labels.employerResults.title')}</th>
          <th>{t('common.labels.employerResults.performedAt')}</th>
          <th>{t('common.labels.employerResults.result')}</th>
          <th className='text-center'>{t('common.labels.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {getResultItems()}
      </tbody>
    </Table>
  )
}

export default EmployeeResult
