import React from 'react'
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap'
import GenericGraphqlError from 'app-components/errors/GenericGraphqlError'
import { i18n } from 'app-core'
import EmployeeForm from 'app-screens/employees/components/EmployeeForm'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AuthorizationActions } from 'app-store'

const SignUp = () => {
  const errors = null

  const { search } = useLocation()
  const dispatch = useDispatch()

  const queryParams = queryString.parse(search)

  const handleSubmit = async values => {
    const signUpData = {
      ...values,
      companyId: queryParams.c,
      localization: values.localization.value
    }
    const testId = queryParams.t

    await dispatch(AuthorizationActions.authorization.signUp(signUpData, testId))
  }

  return (
    <div className="c-app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  {(errors) && (
                    <GenericGraphqlError className='b-radius-0' error={errors} />
                  )}
                  <h1>{i18n.t('screens.signup.text')}</h1>
                  <EmployeeForm
                    onSubmit={handleSubmit}
                    initialValues={{
                      name: '',
                      surname: '',
                      email: '',
                      password: '',
                      localization: ''
                    }}
                  />
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SignUp
