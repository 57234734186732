import { i18n } from 'app-core'

export function buildTextLangInputType (object) {
  if (!object) {
    return null
  }
  return Object.entries(object).map(([lang, { _id, text }]) => {
    return {
      ...(_id && {
        _id
      }),
      lang,
      text
    }
  })
}

export function getTestDescription (test) {
  if (!test) {
    return null
  }

  const localization = test.description.find(desc => desc.lang === i18n.instance.language)

  if (localization) {
    return localization.text
  }

  return null
}

export function getTestTitle (test) {
  if (!test) {
    return null
  }

  const localization = test.title.find(desc => desc.lang === i18n.instance.language)

  if (localization) {
    return localization.text
  }

  return null
}

export function getQuestion (quesetion) {
  if (!quesetion) {
    return null
  }

  const localization = quesetion.title.find(desc => desc.lang === i18n.instance.language)

  if (localization) {
    return localization.text
  }

  return null
}

export function getAnswer (answer) {
  if (!answer) {
    return null
  }
  const localization = answer.description.find(desc => desc.lang === i18n.instance.language)

  if (localization) {
    return localization.text
  }

  return null
}

export function extractValuesFromSelect (value) {
  if (!value) {
    return 'no value'
  }

  if (Array.isArray(value)) {
    return value.map(v => v.value)
  }

  return value.value
}

export function mapValuesToSelectOptions (values, parser) {
  if (!values) {
    return ''
  }

  if (Array.isArray(values)) {
    return values.map(v => parser ? parser(v) : { label: v, value: v })
  }

  return parser ? parser(values) : { label: values, value: values }
}

export function langReducer (lang) {
  if (!lang) {
    return null
  }
  return lang.reduce((memo, { _id, lang, text }) => {
    memo[lang] = {
      _id,
      text
    }
    return memo
  }, {})
}
