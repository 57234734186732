import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import React from "react";

import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faDownload,
  faEdit,
  faPollH,
  faShare,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipWrapper from "app-components/TooltipWrapper";
import { i18n } from "app-core";
import { useAuthorization } from "app-hooks";
import QuestionnaireStatus from "app-models/QuestionnaireStatus";
import {
  isAdmin,
  isCompanyAdmin,
  isEmployee,
  isSuperAdmin,
} from "app-models/UsersType";
import AssignCompanyFormModal from "app-screens/tests/components/AssignCompanyFormModal";
import cx from "classnames";

const getListItems = ({
  data,
  deleteCallBack,
  userRole,
  assignCompanyBtnClicked,
  getShareLinkClicked,
  downloadCsvClicked,
  onStartTest,
  onSeeResults,
  onSeeAllResults,
  questionnaires,
  tooltipToggle,
  tooltipState,
}) => {
  const { language } = i18n.instance;

  return data.map((value) => {
    const titleTranslation = value.title.find(({ lang }) => lang === language);
    const descriptionTranslation = value.description.find(
      ({ lang }) => lang === language
    );

    const DrawEditControls = () => {
      return (
        <div className="d-flex justify-content-center">
          <Link
            id="btnEdit"
            to={`/tests/edit/${value._id}`}
            className="btn btn-ghost-info"
          >
            <FontAwesomeIcon icon={faEdit} />
            <TooltipWrapper
              buttonId="btnEdit"
              tooltipText={i18n.t("common.labels.edit_test")}
            />
          </Link>
          <Link
            id="btnEditQuestion"
            to={`/tests/editor/${value._id}`}
            className="btn btn-ghost-info"
          >
            <FontAwesomeIcon icon={faPollH} />
            <TooltipWrapper
              buttonId="btnEditQuestion"
              tooltipText={i18n.t("common.labels.edit_test_questions")}
            />
          </Link>
          <button
            id="btnAssignTest"
            className="btn btn-ghost-info"
            type="button"
            onClick={() => assignCompanyBtnClicked(value._id)}
            disabled={false}
          >
            <FontAwesomeIcon icon={faShare} />
            <TooltipWrapper
              buttonId="btnAssignTest"
              tooltipText={i18n.t("common.labels.assign_companies")}
            />
          </button>
          <button
            id="btnDownloadCsv"
            className="btn btn-ghost-info"
            type="button"
            onClick={() => downloadCsvClicked(value._id)}
            disabled={false}
          >
            <FontAwesomeIcon icon={faDownload} />
            <TooltipWrapper
              buttonId="btnDownloadCsv"
              tooltipText={i18n.t("common.labels.donwload_csv_file")}
            />
          </button>
          <button
            id="btnCheckResults"
            className="btn btn-ghost-info"
            type="button"
            onClick={() => onSeeAllResults(value._id)}
            disabled={false}
          >
            <FontAwesomeIcon icon={faCheck} />
            <TooltipWrapper
              buttonId="btnCheckResults"
              tooltipText={i18n.t("common.labels.check_results")}
            />
          </button>
          <button
            id="btnCheckResults"
            className="btn btn-ghost-info"
            type="button"
            onClick={() => onSeeAllResults(value._id)}
            disabled={false}
          >
            <FontAwesomeIcon icon={faTable} />
            <TooltipWrapper
              buttonId="btnCheckResults"
              tooltipText={i18n.t("common.labels.generate_report")}
            />
          </button>
          <button
            id="btnDelete"
            className="btn btn-ghost-danger ml-3"
            type="button"
            onClick={() => deleteCallBack && deleteCallBack(value._id)}
            disabled={!isAdmin(userRole)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
            <TooltipWrapper
              buttonId="btnDelete"
              tooltipText={i18n.t("common.labels.delete_test")}
            />
          </button>
        </div>
      );
    };

    const DrawCompanyAdminActions = (testId) => {
      return (
        <>
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={() => assignCompanyBtnClicked(null)}
          >
            {i18n.t("tests.assignTo")}
          </button>
          <button
            className="btn btn-primary btn-sm ml-2"
            type="button"
            onClick={() => getShareLinkClicked(testId)}
          >
            {i18n.t("tests.copyShareLink")}
          </button>
          {/* <button
            className="btn btn-primary btn-sm ml-2"
            type="button"
            onClick={() => downloadCsvClicked(testId)}
          >
            Download
          </button> */}
          <button
            className="btn btn-primary btn-sm ml-2"
            type="button"
            onClick={() => onSeeAllResults(testId)}
          >
            {i18n.t("tests.checkResults")}
          </button>
        </>
      );
    };

    let passedCount = 0;
    let lastQuestionnaireId = -1;
    if (isEmployee(userRole) && questionnaires && questionnaires.length > 0) {
      let qlist = questionnaires
        .filter(
          (q) =>
            q.parentId === value._id &&
            q.status === QuestionnaireStatus.FINISHED &&
            q.passCounting
        )
        .sort((a, b) => b.completedAt - a.completedAt);
      if (qlist.length > 0) {
        lastQuestionnaireId = qlist[0]._id;
        passedCount = qlist.length;
      }
    }

    const canStartTest = value.infinite ? true : passedCount < value.maxCount;

    let startButtonClass = "btn btn-sm";
    startButtonClass = cx(startButtonClass, { "btn-primary": canStartTest });
    startButtonClass = cx(startButtonClass, { "btn-secondary": !canStartTest });

    let resultsButtonClass = "btn btn-sm ml-2";
    resultsButtonClass = cx(resultsButtonClass, {
      "btn-primary": !(lastQuestionnaireId === -1),
    });
    resultsButtonClass = cx(resultsButtonClass, {
      "btn-secondary": lastQuestionnaireId === -1,
    });

    return (
      <tr key={value._id} className="font-sm">
        <td>{titleTranslation && titleTranslation.text}</td>
        <td>{descriptionTranslation && descriptionTranslation.text}</td>
        {isSuperAdmin(userRole) ? (
          <>
            <td>
              <div style={{ maxWidth: "200px" }}>
                {value.companyIds &&
                  value.companyIds.map((c) => {
                    return (
                      <span key={c._id} className="badge badge-primary ml-1">
                        {c.name}
                      </span>
                    );
                  })}
                {value.companyIds.length <= 0 ? (
                  <span className="badge badge-danger ml-1">
                    {i18n.t("tests.noCompaniesAssign")}
                  </span>
                ) : null}
              </div>
            </td>
            <td>{String(value.algorithm).toLocaleLowerCase()}</td>
            <td>{String(value.algorithmAdresses).toLocaleLowerCase()}</td>
          </>
        ) : null}
        <td>{value.infinite ? "Infinite" : value.maxCount}</td>
        {isEmployee(userRole) ? <td>{passedCount}</td> : null}
        <td>
          {isSuperAdmin(userRole) ? DrawEditControls() : null}
          <div className="d-flex justify-content-center">
            {isCompanyAdmin(userRole)
              ? DrawCompanyAdminActions(value._id)
              : null}
            {isEmployee(userRole) ? (
              <div>
                <button
                  className={startButtonClass}
                  type="button"
                  onClick={() => canStartTest && onStartTest(value._id)}
                >
                  Start
                </button>
                {!value.hideResults && (
                  <button
                    className={resultsButtonClass}
                    type="button"
                    onClick={() =>
                      lastQuestionnaireId !== -1 &&
                      onSeeResults(lastQuestionnaireId)
                    }
                  >
                    Results
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </td>
      </tr>
    );
  });
};

const TestsList = ({
  tests,
  clickedTestId,
  assignCompanyPopupVisible,
  assignCompanyPopupToggle,
  assignCompanyBtnClicked,
  assignCompanySubmitClicked,
  downloadCsvClicked,
  getShareLinkClicked,
  testToShowInModal,
  deleteCallBack,
  onStartTest,
  onSeeResults,
  onSeeAllResults,
  questionnaires,
}) => {
  const { user } = useAuthorization();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipState = () => tooltipOpen;
  const { t } = useTranslation();

  return (
    <Table borderless responsive className="table-hover">
      <thead>
        <tr>
          <th>{t("common.labels.title")}</th>
          <th>{t("common.labels.description")}</th>
          {isSuperAdmin(user.role) ? (
            <>
              <th>{t("common.labels.companies")}</th>
              <th>{t("common.labels.algorithm")}</th>
              <th>{t("common.labels.addressees")}</th>
            </>
          ) : null}
          <th>{t("common.labels.max_repeat_count")}</th>
          {isEmployee(user.role) ? (
            <th>{t("common.labels.attempts")}</th>
          ) : null}
          <th className="text-center">{t("common.labels.actions")}</th>
        </tr>
      </thead>
      <tbody>
        {getListItems({
          data: tests,
          deleteCallBack,
          userRole: user.role,
          assignCompanyBtnClicked,
          downloadCsvClicked,
          getShareLinkClicked,
          onStartTest,
          onSeeResults,
          onSeeAllResults,
          questionnaires,
          tooltipToggle,
          tooltipState,
        })}
        <AssignCompanyFormModal
          testData={testToShowInModal}
          isOpen={assignCompanyPopupVisible}
          toggle={assignCompanyPopupToggle}
          onSubmit={assignCompanySubmitClicked}
          testId={clickedTestId}
        />
      </tbody>
    </Table>
  );
};

export default TestsList;
