import React from 'react'
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap'
import { i18n } from 'app-core'

import SignInForm from './SignInForm'
import GenericGraphqlError from 'app-components/errors/GenericGraphqlError'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { IMAGES } from 'app-images'

const LoginScreen = (props) => {
  const { onSubmit, errors } = props

  const { search } = useLocation()
  const queryParams = queryString.parse(search)

  return (
    <div className='c-app flex-row align-items-center'>
      <Container>
        <Row className='justify-content-center'>
          <Col md='6' className='text-center'>
            <img src={IMAGES.loginPageLogo} height='240px' alt='' />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md='6'>
            <Card className='p-2'>
              <CardBody className='p-4'>
                {(errors) && (
                  <GenericGraphqlError className='b-radius-0' error={errors} />
                )}
                <h1>{i18n.t('screens.login.title')}</h1>
                <span className='text-muted'>{i18n.t('screens.login.text')}</span>
                {
                  queryParams.c && queryParams.t ? (
                    <span className='text-muted float-right'>
                      {i18n.t('screens.login.no_account')}
                      <Link className="ml-1" to={'/signup' + search}>{i18n.t('screens.signup.text')}</Link>
                    </span>
                  ) : null
                }
                <SignInForm onSubmit={onSubmit} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LoginScreen
