import gql from "graphql-tag";

export const question = gql`
  fragment question on QuestionAdminType {
    _id
    answers {
      _id
      description {
        lang
        text
      }
      resultExplanation {
        lang
        text
      }
      points
      sortOrder
      type
      defaultAnswer
    }
    calcType
    companyId
    description {
      lang
      text
    }
    name
    title {
      lang
      text
    }
    resultExplanation {
      lang
      text
    }
    type
  }
`;

export const address = gql`
  fragment address on AddressType {
    address
    country
    province
    region
    zip
  }
`;

export const company = gql`
  fragment company on Company {
    _id
    name
  }
`;

export const test = gql`
  fragment test on ExamTaskType {
    _id
    companyIds {
      _id
      name
    }
    title {
      lang
      text
    }
    description {
      lang
      text
    }
    maxCount
    infinite
    hideResults
  }
`;

export const testAdmin = gql`
  fragment testAdmin on ExamTaskAdminType {
    _id
    algorithm
    algorithmAdresses
    companyIds {
      _id
      name
    }
    title {
      lang
      text
    }
    description {
      lang
      text
    }
    maxCount
    infinite
    hideResults
  }
`;

export const testFull = gql`
  fragment testFull on ExamTaskFullAdminType {
    _id
    algorithm
    algorithmAdresses
    companyIds {
      _id
      name
    }
    name
    title {
      lang
      text
    }
    description {
      lang
      text
    }
    questions {
      ...question
    }
    shuffle
    maxCount
    infinite
    hideResults
  }
  ${question}
`;
