import gql from "graphql-tag";

export const resultQuestionnaire = gql`
  query resultQuestionnaire($id: ID!) {
    resultQuestionnaire(_id: $id) {
      _id
      points
      detailed {
        objective
        value
        points
        graphPoints
        question
        answer
        answerType
      }
      user {
        name
        surname
        email
      }
    }
  }
`;

export const resultQuestionnaireByGroupIdsCompletedAt = gql`
  query resultQuestionnaireByGroupIdsCompletedAt(
    $testIds: [ID]!
    $groupIds: [ID]!
    $startDate: String!
    $endDate: String!
  ) {
    resultQuestionnaireByGroupIdsCompletedAt(
      testIds: $testIds
      groupIds: $groupIds
      startDate: $startDate
      endDate: $endDate
    ) {
      _id
      completedAt
      title {
        lang
        text
      }
      userData {
        name
        surname
        email
      }
    }
  }
`;

export const resultQuestionnaireByTestIdsCompletedAt = gql`
  query resultQuestionnaireByTestIdsCompletedAt(
    $testIds: [ID]!
    $startDate: String!
    $endDate: String!
  ) {
    resultQuestionnaireByTestIdsCompletedAt(
      testIds: $testIds
      startDate: $startDate
      endDate: $endDate
    ) {
      _id
      completedAt
      title {
        lang
        text
      }
      userData {
        name
        surname
        email
      }
    }
  }
`;
