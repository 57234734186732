import React from 'react'
import { FormGroup, Label } from 'reactstrap'

export const FormRadioButton = ({
  input,
  label,
  name,
  isEnabled
}) => {
  return (
    <FormGroup className='d-flex align-items-center mb-0'>
      <input {...input} type='radio' disabled={!isEnabled} />
      {label && <Label for={name} className={'ml-1 mt-2'}>{label}</Label>}
    </FormGroup>
  )
}
