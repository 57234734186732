import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { BreadcrumbItem, CardBody } from 'reactstrap'

import { BreadcrumbCommon } from 'app-components'
import BasePage from 'app-layouts/BasePage'
import TestForm from 'app-screens/tests/components/TestForm'
import { useMutation } from '@apollo/react-hooks'
import { GQL } from 'app-gql'
import { buildTextLangInputType, extractValuesFromSelect } from 'app-libs/helpers'
import { useAuthorization } from 'app-hooks'
import { isSuperAdmin } from 'app-models/UsersType'

const CreateTest = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { user } = useAuthorization()
  const isAdmin = isSuperAdmin(user.role)

  const [createTest] = useMutation(GQL.Test.mutations.createTest, {
    onCompleted: ({ createExamTask }) => {
      if (createExamTask) {
        const { status } = createExamTask
        status === 'ok' && history.push('/tests')
      }
    },
    refetchQueries: [
      { query: isAdmin ? GQL.Test.queries.adminTests : GQL.Test.queries.tests }
    ]
  })

  const handleSubmit = useCallback(async (values) => {
    if (createTest) {
      await createTest({
        variables: {
          algorithm: extractValuesFromSelect(values.algorithm),
          algorithmAdresses: extractValuesFromSelect(values.algorithmAdresses),
          title: buildTextLangInputType(values.title),
          description: buildTextLangInputType(values.description),
          shuffle: values.shuffle,
          hideResults: values.hideResults
        }
      })
    }
  }, [createTest])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>
          <Link to='/admin/users'>{t('common.page.tests')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('common.labels.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.addNewTest')}>
        <CardBody>
          <TestForm
            onSubmit={handleSubmit}
          />
        </CardBody>
      </BasePage>
    </>
  )
}

export default CreateTest
