import gql from "graphql-tag";
import { Fragments } from "app-gql/fragments";

export const tests = gql`
  query examTaskList($companyIds: [ID!]) {
    examTaskList(companyIds: $companyIds) {
      ...test
    }
  }
  ${Fragments.test}
`;

export const adminTests = gql`
  query examTaskAdminList($companyIds: [ID!]) {
    examTaskAdminList(companyIds: $companyIds) {
      ...testAdmin
    }
  }
  ${Fragments.testAdmin}
`;
