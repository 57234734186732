import createReducer from './../../libs/createReducer'
import { ActionTypes } from './actions'

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isRestoring: false,
  loggedInTimeStamp: null,
  user: null,
  token: null,
  refreshToken: null
}

export default createReducer(initialState, {
  [ActionTypes.START] () {
    return {
      ...initialState,
      isAuthenticating: true
    }
  },
  [ActionTypes.SET] (state, action) {
    const { payload } = action

    return {
      ...state,
      ...payload,
      loggedInTimeStamp: Date.now()
    }
  },
  [ActionTypes.COMPLETE] (state) {
    return {
      ...state,
      isAuthenticating: false
    }
  },
  [ActionTypes.LOGOUT] () {
    return {
      ...initialState
    }
  }
})
