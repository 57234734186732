import { useMutation } from '@apollo/react-hooks'
import { changePasswordMutation } from 'app-gql/user/mutations/changePassword'

const useChangePassword = ({ onComplete, refetchQueries }) => {
  return useMutation(changePasswordMutation, {
    onCompleted (data) {
      onComplete && onComplete(data)
    },
    refetchQueries: refetchQueries ? [{ query: refetchQueries }] : null
  })
}

export default useChangePassword
