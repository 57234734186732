import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BreadcrumbItem, CardBody } from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { isSuperAdmin } from "app-models/UsersType";
import BreadcrumbCommon from "app-components/BreadcrumbCommon";
import BasePage from "app-layouts/BasePage";
import CompanyForm from "app-screens/company/components/CompanyForm";
import Company from "app-models/company";
import { GQL } from "app-gql";
import { useAuthorization } from "app-hooks";
import GenericGraphqlError from "app-components/errors/GenericGraphqlError";

/* CREATE COMPANY PAGE */
const CreateCompany = () => {
  const { t } = useTranslation();
  const { user } = useAuthorization();
  const history = useHistory();

  const [createCompany, { error }] = useMutation(
    GQL.Company.mutations.createCompany,
    {
      onCompleted: ({ createCompany }) => {
        if (createCompany) {
          history.push("/companies");
        }
      },
      refetchQueries: [{ query: GQL.Company.queries.companies }],
    }
  );

  const handleSubmit = useCallback(
    async (values) => {
      /* create base company */
      if (createCompany && isSuperAdmin(user.role)) {
        if (typeof values.companyLogo === "string") {
          values.companyLogo = null;
        }

        values.address.zip = +values.address.zip;

        await createCompany({
          variables: {
            ...values,
          },
        });
      }
    },
    [createCompany, user]
  );

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{t("common.page.admin.create")}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t("common.page.admin.create_company")}>
        <CardBody>
          {error && (
            <GenericGraphqlError className="b-radius-0" error={error} />
          )}
          <CompanyForm
            onSubmit={handleSubmit}
            initialValues={Company.initialData}
            enableReinitialize
          />
        </CardBody>
      </BasePage>
    </>
  );
};

export default CreateCompany;
