import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLazyQuery } from "@apollo/react-hooks";
import renderDatePicker from "../../components/forms/FormDatePickerField";
import {
  CardBody,
  Col,
  Row,
  FormGroup,
  Card,
  Form,
  CardHeader,
} from "reactstrap";
import { getTestTitle } from "app-libs/helpers";
import { connect } from "react-redux";
import { i18n } from "app-core";
import { GQL } from "app-gql";
import { FormCheckboxField } from "app-components/forms/FormCheckboxField";
import { Button } from "reactstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormMultiSelectField from "app-components/forms/FormMultiSelectField";
import { useTranslation } from "react-i18next";

let CreateTestAggregatedDataForm = ({
  isCreatingPdf,
  resetUserTests,
  handleSubmit,
  tests,
  selectedTestsIds,
  selectedGroupIds,
  startDate,
  endDate,
  selectedUserTests,
  selectedSections,
  savePdf,
}) => {
  let [invalid, setInvalid] = useState(false);
  const { t } = useTranslation();

  let [
    getExamTaskGroupAssignmentList,
    { data: { examTaskGroupAssignmentList: userGroups } = {} },
  ] = useLazyQuery(GQL.Test.queries.examTaskGroupAssignmentList, {
    variables: {
      examTaskIds: selectedTestsIds,
    },
  });

  const [
    gerResultQuestionnaireByGroupIdsCompletedAt,
    {
      data: {
        resultQuestionnaireByGroupIdsCompletedAt: userTestsFromGroups,
      } = {},
    },
  ] = useLazyQuery(GQL.Test.queries.resultQuestionnaireByGroupIdsCompletedAt, {
    variables: {
      testIds: selectedTestsIds,
      groupIds: selectedGroupIds,
      startDate: startDate,
      endDate: endDate,
    },
  });

  const [
    getResultQuestionnaireByTestIdsCompletedAt,
    {
      data: {
        resultQuestionnaireByTestIdsCompletedAt: userTestsFromTests,
      } = {},
    },
  ] = useLazyQuery(GQL.Test.queries.resultQuestionnaireByTestIdsCompletedAt, {
    variables: {
      testIds: selectedTestsIds,
      startDate: startDate,
      endDate: endDate,
    },
  });

  useEffect(() => {
    if (selectedTestsIds.length > 0) {
      getResultQuestionnaireByTestIdsCompletedAt();
      getExamTaskGroupAssignmentList();
    } else {
      userGroups = [];
    }
  }, [selectedTestsIds]);

  useEffect(() => {
    if (startDate && endDate && selectedGroupIds?.length) {
      gerResultQuestionnaireByGroupIdsCompletedAt();
    } else {
      userGroups = [];
    }
  }, [startDate, endDate, selectedGroupIds]);

  useEffect(() => {
    if (
      !startDate ||
      !endDate ||
      selectedTestsIds?.length === 0 ||
      // selectedGroupIds?.length === 0 ||
      selectedUserTests?.length === 0 ||
      selectedUserTests === undefined ||
      selectedSections?.length === 0 ||
      selectedSections === undefined
    ) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [
    startDate,
    endDate,
    selectedTestsIds,
    selectedGroupIds,
    selectedUserTests,
    selectedSections,
  ]);

  const sections = {
    concezione_dell_obiettivo: i18n.t(
      "tests.aggregateData.sections.concezione_dell_obiettivo"
    ),
    comp_anticipazione: i18n.t(
      "tests.aggregateData.sections.comp_anticipazione"
    ),
    comp_pianificazione: i18n.t(
      "tests.aggregateData.sections.comp_pianificazione"
    ),
    comp_gest_asp_crit_error: i18n.t(
      "tests.aggregateData.sections.comp_gest_asp_crit_error"
    ),
    comp_interattiva_comunicativa: i18n.t(
      "tests.aggregateData.sections.comp_interattiva_comunicativa"
    ),
    comp_gestione_lavoro_di_squadra: i18n.t(
      "tests.aggregateData.sections.comp_gestione_lavoro_di_squadra"
    ),
    comp_conc_svilu: i18n.t("tests.aggregateData.sections.comp_conc_svilu"),
    comp_gestione_collocazione_dei_ruoli: i18n.t(
      "tests.aggregateData.sections.comp_gestione_collocazione_dei_ruoli"
    ),
    comp_generazione_della_cultura_di_squadra: i18n.t(
      "tests.aggregateData.sections.comp_generazione_della_cultura_di_squadra"
    ),
    competenza_di_analisi_del_contesto: i18n.t(
      "tests.aggregateData.sections.competenza_di_analisi_del_contesto"
    ),
    competenza_di_valutazione: i18n.t(
      "tests.aggregateData.sections.competenza_di_valutazione"
    ),
  };

  return (
    <Form
      className="d-flex flex-column justify-content-between"
      onSubmit={handleSubmit}
    >
      <Row form>
        <Col>
          <Card>
            <CardHeader>
              {i18n.t("tests.aggregateData.labels.dates")}
            </CardHeader>
            <CardBody>
              <Field
                name="startDate"
                label={i18n.t("tests.aggregateData.labels.from")}
                inputValueFormat="dd/MM/yyyy"
                props={{ className: "form-control form-control" }}
                component={renderDatePicker}
              />
              <hr />
              <Field
                name="endDate"
                label={i18n.t("tests.aggregateData.labels.to")}
                inputValueFormat="dd/MM/yyyy"
                props={{ className: "form-control form-control" }}
                component={renderDatePicker}
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              {i18n.t("tests.aggregateData.labels.examsList")}
            </CardHeader>
            <CardBody>
              {tests?.map((test, index) => {
                return (
                  <Field
                    key={index}
                    name={`tests[${test._id}]`}
                    label={getTestTitle(test)}
                    component={FormCheckboxField}
                    isEnabled
                  />
                );
              })}
              {selectedTestsIds.length === 1 && (
                <>
                  <hr />
                  <Field
                    name="hideDescriptions"
                    label={i18n.t(
                      "tests.aggregateData.labels.hideDescriptions"
                    )}
                    component={FormCheckboxField}
                    props={{ name: "hideDescriptions" }}
                    isEnabled
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              {i18n.t("tests.aggregateData.labels.userGroups")}
            </CardHeader>
            <CardBody>
              {userGroups?.map((userGroup, index) => {
                return (
                  <Field
                    key={index}
                    name={`userGroups[${userGroup._id}]`}
                    label={userGroup.title[0].text}
                    component={FormCheckboxField}
                    isEnabled
                  />
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              {i18n.t("tests.aggregateData.labels.userTests")}
            </CardHeader>
            <CardBody>
              <Field
                name="userTests"
                component={FormMultiSelectField}
                props={{
                  options: (selectedGroupIds?.length
                    ? userTestsFromGroups
                    : userTestsFromTests
                  )?.map((userTest) => {
                    return {
                      value: userTest._id,
                      label: `${userTest.userData.email} - ${userTest.title[0].text} - ${userTest.completedAt}`,
                    };
                  }),
                }}
                isEnabled
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              {i18n.t("tests.aggregateData.labels.sections")}
            </CardHeader>
            <CardBody>
              {Object.entries(sections).map(([key, value], index) => {
                return (
                  <Field
                    key={index}
                    name={`sections[${key}]`}
                    label={value}
                    component={FormCheckboxField}
                    isEnabled
                  />
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Field
        name="savePdf"
        label={t("common.labels.savePdf")}
        component={FormCheckboxField}
        isEnabled
      />
      <FormGroup className="d-flex align-items-center m-0">
        <Button
          type="submit"
          color="primary"
          disabled={isCreatingPdf || invalid}
        >
          {savePdf
            ? t("common.labels.savePdfOnDisk")
            : t("common.labels.generatePdf")}
        </Button>
        {isCreatingPdf ? (
          <div className="ml-1 spinner-border" role="status">
            <span className="visually-hidden"></span>
          </div>
        ) : (
          ""
        )}
      </FormGroup>
    </Form>
  );
};

CreateTestAggregatedDataForm = reduxForm({
  form: "createTestAggregateData",
})(CreateTestAggregatedDataForm);

const selector = formValueSelector("createTestAggregateData");
CreateTestAggregatedDataForm = connect((state) => {
  const selectedSectionsObj = selector(state, "sections");
  const selectedTests = selector(state, "tests");
  const selectedGroups = selector(state, "userGroups");
  const selectedUserTests = selector(state, "userTests");
  const hideDescriptions = selector(state, "hideDescriptions");
  const startDate = selector(state, "startDate");
  const endDate = selector(state, "endDate");
  const savePdf = selector(state, "savePdf");
  let selectedGroupIds = [];
  let selectedTestsIds = [];
  let selectedSections = [];
  if (selectedSectionsObj) {
    selectedSections = Object.keys(_.pickBy(selectedSectionsObj, Boolean));
  }
  if (selectedTests) {
    selectedTestsIds = Object.keys(_.pickBy(selectedTests, Boolean));
  }
  if (selectedGroups) {
    selectedGroupIds = Object.keys(_.pickBy(selectedGroups, Boolean));
  }
  return {
    hideDescriptions,
    selectedTestsIds,
    selectedGroupIds,
    selectedUserTests,
    selectedSections,
    startDate,
    endDate,
    savePdf,
  };
})(CreateTestAggregatedDataForm);

export default CreateTestAggregatedDataForm;
