import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Table,
} from "reactstrap";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import BreadcrumbCommon from "app-components/BreadcrumbCommon";
import BasePage from "app-layouts/BasePage";
import HandlerComponent from "app-components/HandlerComponent";
import withLocalization from "../../../hoc/withLocalization";
import { GQL } from "app-gql";
import EditEmployeeForm from "app-screens/employees/components/EditEmployeeForm";
import GenericGraphqlError from "app-components/errors/GenericGraphqlError";
import cx from "classnames";
import { i18n } from "app-core";
import { useAuthorization } from "app-hooks";
import MessageAlert from "app-components/alerts/MessageAlert";
import EmployeeResult from "app-screens/employees/components/EmployeeResult";

const EditEmployee = ({ employee }) => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const { language } = i18n.instance;
  const { id } = params;
  const { user } = useAuthorization();

  const [assignedTest, setAssignedTest] = useState(null);

  const { loading, error, data } = useQuery(GQL.User.queries.user, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  const testsRequest = useQuery(GQL.Test.queries.tests, {
    variables: { companyIds: user.companyId },
  });

  /* EDIT EMPLOYEE INFO */
  const [updateEmployee, { error: gqlError }] = useMutation(
    GQL.User.mutations.editUser,
    {
      onCompleted({ updateUser }) {
        if (updateUser && updateUser.status === "ok") {
          history.goBack();
        }
      },
    }
  );

  const handleEmployeeInfoSubmit = useCallback(
    async (values) => {
      if (updateEmployee) {
        // await updateEmployee({
        //   variables: { ...values }
        // })
      }
    },
    [updateEmployee]
  );

  const [assignTestToEmployee] = useMutation(
    GQL.Employees.mutations.setExamTaskAssignment,
    {
      onCompleted({ setExamTaskAssignment }) {
        if (setExamTaskAssignment && setExamTaskAssignment.status === "ok") {
          data.user.examTasksAssignment =
            setExamTaskAssignment.examTasksAssignment.examTaskIds;

          setAssignedTest(null);
        }
      },
    }
  );

  const [removeTestFromEmployee] = useMutation(
    GQL.Employees.mutations.removeExamTaskAssignment,
    {
      onCompleted({ removeExamTaskAssignment }) {
        if (
          removeExamTaskAssignment &&
          removeExamTaskAssignment.status === "ok"
        ) {
          data.user.examTasksAssignment =
            removeExamTaskAssignment.examTasksAssignment.examTaskIds;

          setAssignedTest(null);
        }
      },
    }
  );

  const [resetData, setResetData] = useState(null);
  const [resetTestCountForEmployee] = useMutation(
    GQL.Test.mutations.resetQuestionnaireCounter,
    {
      onCompleted(data) {
        if (
          data &&
          data.resetQuestionnaireCounter &&
          data.resetQuestionnaireCounter.status
        ) {
          setResetData(t(`alerts.${data.resetQuestionnaireCounter.status}`));
          window.setTimeout(() => {
            setResetData(null);
          }, 2000);
        }
      },
    }
  );

  const assignTest = async (testId) => {
    setAssignedTest(testId);

    if (assignTestToEmployee) {
      await assignTestToEmployee({
        variables: {
          userId: id,
          examTaskIds: [testId],
        },
      });
    }
  };

  const removeTest = async (testId) => {
    setAssignedTest(testId);

    if (removeTestFromEmployee) {
      await removeTestFromEmployee({
        variables: {
          userId: id,
          examTaskIds: [testId],
        },
      });
    }
  };

  const removeCountTest = async (testId) => {
    if (resetTestCountForEmployee) {
      await resetTestCountForEmployee({
        variables: {
          userIds: [id],
          examTaskIds: [testId],
        },
      });
    }
  };

  const isTestAdded = (testId) => {
    if (data.user.examTasksAssignment) {
      return data.user.examTasksAssignment.indexOf(testId) !== -1;
    }
    return false;
  };

  const isTestAddedByGroup = (testId) => {
    let isAddedByGropup = false;
    if (!isTestAdded(testId)) {
      const assignedExamTasks = data.user.assignedExamTasks.filter(
        (ex) => ex["_id"] === testId
      );
      if (assignedExamTasks.length > 0) {
        isAddedByGropup = true;
      }
    }
    return isAddedByGropup;
  };

  const getListItems = (assignTest, removeTest, removeCountTest) => {
    const { examTaskList } = testsRequest.data;

    return examTaskList.map((value) => {
      const titleTranslation = value.title.find(
        ({ lang }) => lang === language
      );
      const descriptionTranslation = value.description.find(
        ({ lang }) => lang === language
      );

      const testAdded = isTestAdded(value._id);
      const testAddedByGroup = isTestAddedByGroup(value._id);

      let statusClass = "badge ml-0";
      statusClass = cx(statusClass, { "badge-success": testAdded });
      statusClass = cx(statusClass, { "badge-secondary": !testAdded });

      const statusText = testAdded
        ? t("tests.assignedDirectly")
        : testAddedByGroup
        ? t("tests.inherited")
        : t("tests.notAssigned");

      return (
        <tr key={value._id} className="font-sm">
          <td>{titleTranslation && titleTranslation.text}</td>
          <td>{descriptionTranslation && descriptionTranslation.text}</td>
          <td>
            <span className={statusClass}>{statusText}</span>
          </td>
          <td>
            <div className="d-flex justify-content-center">
              {testAdded ? (
                <div>
                  <Row>
                    <Col>
                      <button
                        className="btn btn-danger btn-sm"
                        type="button"
                        onClick={() => removeTest(value._id)}
                      >
                        {i18n.t("tests.remove")}
                      </button>
                    </Col>
                    <Col>
                      <button
                        className="btn btn-danger btn-sm"
                        type="button"
                        onClick={() => removeCountTest(value._id)}
                      >
                        {i18n.t("tests.resetCount")}
                      </button>
                    </Col>
                  </Row>
                </div>
              ) : (
                <button
                  className="btn btn-info btn-sm"
                  type="button"
                  onClick={() => assignTest(value._id)}
                >
                  {i18n.t("tests.assignDirectly")}
                </button>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const drawTests = (assignTest, removeTest, removeCountTest) => {
    return (
      <Table borderless responsive className="table-hover">
        <thead>
          <tr>
            <th>{t("common.labels.title")}</th>
            <th>{t("common.labels.description")}</th>
            <th>{t("common.labels.status")}</th>
            <th className="text-center">{t("common.labels.actions")}</th>
          </tr>
        </thead>
        <tbody>{getListItems(assignTest, removeTest, removeCountTest)}</tbody>
      </Table>
    );
  };

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>
          {t("common.page.admin.update_employee")}
        </BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t("common.page.admin.update_employee")}>
        <CardBody>
          <HandlerComponent loading={loading} />
          {(error || gqlError) && (
            <GenericGraphqlError
              className="b-radius-0"
              error={error || gqlError}
            />
          )}
          {data && (
            <>
              <Row className="mb-3">
                <Col>
                  <EditEmployeeForm
                    initialValues={{ ...data.user }}
                    enableReinitialize
                    editState
                    onSubmit={handleEmployeeInfoSubmit}
                    testsRequest={testsRequest}
                    assignTest={assignTest}
                    removeTest={removeTest}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {resetData && (
                    <MessageAlert className="b-radius-0" message={resetData} />
                  )}
                  <Card className="card-accent-warning">
                    <CardHeader>{t("tests.assignTests")}</CardHeader>
                    <HandlerComponent
                      loading={testsRequest.loading}
                      error={testsRequest.error}
                    />
                    <CardBody className={"pt-0 pl-2"}>
                      {data &&
                        testsRequest &&
                        testsRequest.data &&
                        drawTests(assignTest, removeTest, removeCountTest)}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="card-accent-warning">
                    <CardHeader>{t("tests.testResults")}</CardHeader>
                    <HandlerComponent
                      loading={testsRequest.loading}
                      error={testsRequest.error}
                    />
                    <CardBody className={"pt-0 pl-2"}>
                      {data && (
                        <EmployeeResult
                          questionnaires={data.user.questionnaires}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
        <CardFooter className="float-right">
          <Button color="primary" onClick={() => history.goBack()}>
            {t("common.labels.close")}
          </Button>
        </CardFooter>
      </BasePage>
    </>
  );
};

EditEmployee.propTypes = {
  employee: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    // employee: state.authorization.user
  };
};

export default connect(mapStateToProps)(withLocalization(EditEmployee));
