import React from 'react'

import SidebarMenu from './SidebarMenu'
import SidebarData from './SidebarData'
import { useAuthorization } from 'app-hooks'

const Sidebar = () => {
  const { user } = useAuthorization()

  return <SidebarMenu data={SidebarData(user)} />
}

export default Sidebar
