import { GQL } from 'app-gql'
import { useMutation } from '@apollo/react-hooks'

const useDeleteTest = ({ onComplete, refetchQueries }) => {
  return useMutation(GQL.Test.mutations.deleteTest, {
    onCompleted (data) {
      onComplete && onComplete(data)
    },
    refetchQueries: refetchQueries ? [{ query: refetchQueries }] : null
  })
}

export default useDeleteTest
