import React, { useCallback, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'reactstrap'
import { change, formValueSelector, reduxForm } from 'redux-form'

import { i18n } from 'app-core'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { useMutation } from '@apollo/react-hooks'
import { GQL } from 'app-gql'
import { useAuthorization } from 'app-hooks'
import { isAdmin, isSuperAdmin } from 'app-models/UsersType'
import SortableQuestions from 'app-screens/tests/components/Questions/SortableQuestions'

const TestEditorForm = ({
  pristine,
  invalid,
  submitting,
  handleSubmit,
  values,
  initialValues,
  error,
  addQuestion,
  editQuestion,
  addAnswer,
  editAnswer,
  onAnswerRemoved,
  exportToPdf,
  setFieldValue,
  onReorderQuestions,
  onReorderAnswers,
  onAnswerClicked,
  onPrintClick
}) => {
  const { questions } = values
  const { testId } = initialValues
  const { user } = useAuthorization()

  const [questionToDelete, setQuestionToDelete] = useState(null)
  const [answerToDelete, setAnswerToDelete] = useState(null)

  const [updateQuestion] = useMutation(GQL.Test.mutations.updateQuestion, {
    onCompleted: (result) => {
      if (answerToDelete != null) {
        const newQuestions = [...questions]

        const questionToUpdate = newQuestions.find(
          (q) => q._id === answerToDelete.questionId)
        questionToUpdate.answers = questionToUpdate.answers.filter(
          (a) => a._id !== answerToDelete.answerId)

        setAnswerToDelete(null)

        setFieldValue('TestEditorForm', 'questions', newQuestions)
      }
    }
  })

  const [deleteQuestion] = useMutation(GQL.Test.mutations.deleteQuestion, {
    onCompleted: (result) => {
      // remove question from the state
      const filteredQuestion = questions.filter(question => {
        return question._id !== questionToDelete._id
      })
      setQuestionToDelete(null)

      setFieldValue('TestEditorForm', 'questions', filteredQuestion)
    }
  })

  const onRemoveQuestion = useCallback(async (question) => {
    setQuestionToDelete(question)

    await deleteQuestion({
      variables: {
        id: question._id
      }
    })
  }, [setQuestionToDelete, deleteQuestion])

  const onRemoveAnswer = useCallback(async (answer, questionId) => {
    setAnswerToDelete({
      questionId,
      answerId: answer._id
    })

    await updateQuestion({
      variables: {
        id: questionId,
        examTaskIds: [testId],
        removeAnswers: {
          ids: [answer._id]
        }
      }
    })

    onAnswerRemoved && onAnswerRemoved()
  }, [testId, setAnswerToDelete, updateQuestion, onAnswerRemoved])

  const onAddAnswerClick = useCallback(question => {
    addAnswer(question)
  }, [addAnswer])

  const onEditAnswer = useCallback((question, answer) => {
    editAnswer(question, answer)
  }, [editAnswer])

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      onReorderQuestions(oldIndex, newIndex)
    } else {
    }
  }, [onReorderQuestions])

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Row form>
          <Col>
            {questions.length === 0 && (
              <div>
                <ul className='list-group'>
                  <li className='list-group-item list-group-item-secondary'>
                    {i18n.t('tests.question')}
                  </li>
                  <li className='list-group-item list-group-item-danger'>
                    {i18n.t('tests.createFirstQuestion')}
                  </li>
                </ul>
                <br />
              </div>
            )}
            {questions.length > 0 && (
              <div>
                <SortableQuestions
                  questions={questions}
                  onExportToPdfClick={exportToPdf}
                  onSortEnd={onSortEnd}
                  onAnswersSortEnd={onReorderAnswers}
                  userRole={user.role}
                  onRemoveAnswer={onRemoveAnswer}
                  onEditClick={editQuestion}
                  onAddAnswerClick={onAddAnswerClick}
                  onRemoveQuestion={onRemoveQuestion}
                  canDownloadAsPDF={isAdmin(user.role)}
                  onAnswerClicked={onAnswerClicked}
                  onEditAnswer={onEditAnswer}
                  onPrintClick={onPrintClick}
                  canPrint={isAdmin(user.role)}
                />
                <br />
              </div>
            )}
            {
              isSuperAdmin(user.role) ? (
                <Button id="add-question-button" color='primary' type='button' onClick={addQuestion}>
                  {i18n.t('tests.addQuestion')}
                </Button>
              ) : null
            }
          </Col>
        </Row>
      </Form>
    </>
  )
}

const selector = formValueSelector('TestEditorForm')

const questionsSelector = (state) => selector(state, 'questions')

const formValuesSelector = createSelector(
  questionsSelector,
  (questions) => {
    return {
      values: {
        questions
      }
    }
  }
)

const mapStateToProps = (state) => {
  return formValuesSelector(state)
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue: (form, field, value) => {
      return dispatch(change(form, field, value))
    }
  }
}

export default reduxForm({
  form: 'TestEditorForm'
})(connect(mapStateToProps, mapDispatchToProps)(TestEditorForm))
