import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Field, formPropTypes, reduxForm } from 'redux-form'

import { i18n } from 'app-core'
import { validators } from 'app-libs'
import { FormTextField } from 'app-components/forms'

const SignInForm = (props) => {
  const {
    handleSubmit,
    pristine,
    invalid,
    submitting
  } = props

  return (
    <form onSubmit={handleSubmit} className={'mt-2'}>
      <Field
        type='email'
        name='email'
        icon='envelope'
        placeholder={i18n.t('screens.login.form.email.placeholder')}
        component={FormTextField}
        validate={[validators.required, validators.email]}
      />
      <Field
        type='password'
        name='password'
        icon='lock'
        placeholder={i18n.t('screens.login.form.password.placeholder')}
        component={FormTextField}
        validate={[validators.required]}
      />

      <Row>
        <Col className="d-flex">
          <Button color='primary'
            type='submit'
            disabled={submitting || pristine || invalid}
          >
            {i18n.t('screens.login.form.button.signIn')}
          </Button>

          <Link className='ml-auto d-flex align-items-center' to='/recover'>
            {i18n.t('screens.login.form.button.forgotPassword')}
          </Link>
        </Col>
      </Row>

    </form>
  )
}

SignInForm.propTypes = formPropTypes

export default reduxForm({
  form: 'signInForm'
})(SignInForm)
