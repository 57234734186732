import gql from 'graphql-tag'

export const createTest = gql`
  mutation createExamTask(
    $algorithm: String!,
    $algorithmAdresses: String!,
    $companyIds: [ID],
    $description: [TextLangInputType],
    $name: String,
    $questions: [ID],
    $title: [TextLangInputType]
    $shuffle: Boolean
    $hideResults: Boolean
  ) {
    createExamTask(
      algorithm: $algorithm,
      algorithmAdresses: $algorithmAdresses,
      companyIds: $companyIds,
      description: $description,
      name: $name,
      questions: $questions,
      title: $title,
      shuffle: $shuffle,
      hideResults: $hideResults
    ) {
      status
    }
  }
`
