import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Label } from 'reactstrap'
import moment from 'moment'

const renderDatePicker = ({ input, inputValueFormat, placeholder, defaultValue, className, label, meta: { touched, error } }) => {
  return (
    <div>
      {label && <Label>{label}</Label>}
      <DatePicker {...input} dateFormat={inputValueFormat} className={className} selected={input.value ? new Date(moment(input.value)) : null} />
      {touched && error && <span>{error}</span>}
    </div>
  );
}

export default renderDatePicker
