import gql from 'graphql-tag'

export const createUser = gql`
  mutation createUser(
    $name: String!,
    $surname: String!,
    $email: String!,
    $password: String!,
    $role: RoleEnum!,
    $users: [String],
    $companies: [String],
    $localization: String!,
    $companyId: ID
  ) {
    createUser(
      name: $name,
      surname: $surname,
      email: $email,
      password: $password,
      role: $role,
      users: $users,
      companies: $companies,
      localization: $localization,
      companyId: $companyId
    ) {
      status
    }
  }
`
