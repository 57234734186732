import { useQuery } from '@apollo/react-hooks'
import { GQL } from 'app-gql'
import UsersType from 'app-models/UsersType'

const useFetchEmployees = () => {
  return useQuery(GQL.User.queries.usersList, {
    variables: { filterRole: [UsersType.EMPLOYEE] },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })
}
export default useFetchEmployees
