import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { from } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'

import { initAuthLink } from './links'
import { initMutationFunc } from './mutation'
import { initQueryFunc } from './query'

const options = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

export default function initApolloClient (configuration) {
  const cache = new InMemoryCache({ addTypename: false })

  const httpLink = new HttpLink({ uri: `${configuration.apiUrl}/graphql` })
  const uploadLink = createUploadLink({ uri: `${configuration.apiUrl}/graphql` })
  const authLink = initAuthLink()

  const client = new ApolloClient({
    link: from([authLink, uploadLink, httpLink]),
    cache: cache,
    defaultOptions: options
  })

  return {
    client,
    mutation: initMutationFunc(client),
    query: initQueryFunc(client)
  }
}
