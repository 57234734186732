import React, { useState, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { CardBody } from "reactstrap";
import CreateTestAggregatedDataForm from "./CreateTestAggregatedDataForm";
import { i18n, restApi } from "app-core";
import { GQL } from "app-gql";
import { BreadcrumbItem } from "reactstrap";
import BreadcrumbCommon from "app-components/BreadcrumbCommon";
import { useAuthorization } from "app-hooks";
import { saveAs } from "file-saver";
import { BasePage } from "app-layouts";
import { Link } from "react-router-dom";
import _ from "lodash";

const CreateTestAggregatedData = () => {
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const { user } = useAuthorization();
  const {
    loading,
    error,
    data: { examTaskList: tests } = {},
  } = useQuery(GQL.Test.queries.tests);

  const handleSubmit = useCallback(async (values) => {
    setIsCreatingPdf(true);
    restApi.ExamAggregateDataApiService.generateAndDownloadTestReport(
      values,
      i18n.instance.language
    ).then((response) => {
      const locale = i18n.instance.language === "it" ? "it-IT" : "en-US";
      const fileName = _.snakeCase(
        `report_${user.company.name}_${new Date().toLocaleDateString(locale)}`
      );
      saveAs(response.data, `${fileName}.pdf`);
      setIsCreatingPdf(false);
    });
  }, []);

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem>
          <Link to="/tests">{i18n.t("common.page.tests")}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {i18n.t("common.page.admin.create")}
        </BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={i18n.t("tests.aggregatedData")}>
        <CardBody>
          <CreateTestAggregatedDataForm
            onSubmit={handleSubmit}
            tests={tests}
            isCreatingPdf={isCreatingPdf}
          />
        </CardBody>
      </BasePage>
    </>
  );
};

export default CreateTestAggregatedData;
