import gql from 'graphql-tag'

export const createQuestion = gql`
  mutation createQuestion(
    $answers: [AnswerInputType],
    $calcType: String!
    $companyId: String,
    $description: [TextLangInputType],
    $resultExplanation: [TextLangInputType],
    $name: String,
    $title: [TextLangInputType],
    $type: QuestionTypeEnum!,
    $examTaskIds: [ID],
  ) {
    createQuestion(
      answers: $answers,
      calcType: $calcType,
      companyId: $companyId,
      description: $description,
      resultExplanation: $resultExplanation,
      name: $name,
      title: $title,
      type: $type,
      examTaskIds: $examTaskIds,
    ) {
      status
      question {
        _id
        title {
          lang
          text
        }
      }
    }
  }
`
