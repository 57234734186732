import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Label } from 'reactstrap'

import Row from 'reactstrap/lib/Row'

const FormImageField = ({ src, onChange }) => {
  const { t } = useTranslation()
  return (
    <Row>
      <Col md={3} className='d-flex justify-content-center align-items-center'>
        <img src={src} alt="logo" style={{
          maxWidth: '100px',
          maxHeight: '74px'
        }}/>
      </Col>
      <Col md={9}>
        <FormGroup>
          <Label className={'text-muted mr-1'}>{t('common.labels.company.companyLogo')}</Label>
          <input name="companyLogo" type="file" className='w-100' accept="image/png" onChange={onChange}/>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default FormImageField
