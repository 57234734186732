import gql from 'graphql-tag'

export const user = gql`
  query user($id:String) {
    user(_id:$id) {
      _id
      name
      surname
      role
      privacy {
        users
        companies
        tests
        examtask
        questions
      }
      status
      email
      companyId
      company {
        _id
        name
      }
      localization
      assignedExamTasks {
        _id
      }
      examTasksAssignment
      questionnaires {
          _id
          title {
            lang
            text
          }
          status
          resultPoints
          completedAt
      }
    }
  }
`
