import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'
import cx from 'classnames'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isSuperAdmin } from 'app-models/UsersType'
import { useAuthorization } from 'app-hooks'
import { Select } from 'app-components'

const parseAddress = (value) => {
  return value ? `${value.zip}, ${value.address}, ${value.province}, ${value.region}, ${value.country}` : ''
}

const CompaniesList = ({ companiesList, deleteCompanyClicked, loginPermittedClicked }) => {
  const { user } = useAuthorization()
  const { t } = useTranslation()

  const [filterValue, setFilterValue] = useState(null)

  const filteredCompanyList = companiesList.filter(c => {
    if (filterValue) {
      return filterValue.find(f => f.label === c.name) !== undefined
    }
    return true
  })

  const filterOptions = companiesList.map(c => {
    return { value: c._id, label: c.name }
  })

  const onSelectChanged = useCallback((e) => {
    let options = e
    if (options && options.length <= 0) {
      options = null
    }
    setFilterValue(options)
  }, [])

  const maxCompanyAdminsCount = 2

  return (
    <>
      <div className="d-flex p-3">
        Filter companies
        <Select
          id={'companySearch'}
          isMulti={true}
          options={filterOptions}
          onChange={onSelectChanged}
          value={filterValue}
        />
      </div>
      <Table borderless responsive className='table-hover'>
        <thead>
          <tr>
            <th>{t('common.labels.company.name')}</th>
            <th>{t('common.labels.company.contact_name')}</th>
            <th>{t('common.labels.company.email')}</th>
            <th>{t('common.labels.company.address')}</th>
            { isSuperAdmin(user.role) ? <th>{t('common.labels.company.permissions.login')}</th> : null }
            <th className='text-center'>{t('common.labels.company.admins')}</th>
            <th className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCompanyList && filteredCompanyList.map((value) => {
            return (
              <tr key={value._id} className='font-sm'>
                <td>{value.name}</td>
                <td>{value.contactName}</td>
                <td>{value.email}</td>
                <td>{parseAddress(value.address)}</td>
                { isSuperAdmin(user.role) ? (
                  <td>
                    <div>
                      <input type='checkbox' onChange={ () => loginPermittedClicked(value) } checked={value.loginPermitted} />
                    </div>
                  </td>)
                  : null
                }
                <td>
                  <div style={{ maxWidth: '200px' }}>
                    {
                      value.companyAdmins && value.companyAdmins.slice(0, maxCompanyAdminsCount).map(userData => {
                        return <span key={userData._id} className='badge badge-primary ml-1'>{userData.name} {userData.surname}</span>
                      })
                    }
                    {
                      value.companyAdmins.length > maxCompanyAdminsCount ? <span className='badge badge-primary ml-1'>{'....'}</span> : null
                    }
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <Link to={`/company/edit/${value._id}`} className='btn btn-ghost-info'>
                      <FontAwesomeIcon icon={faEdit}/>
                    </Link>
                    <button
                      disabled={!isSuperAdmin(user.role)}
                      className={cx('btn', { 'btn-ghost-danger': !value.defaultCompany }, { 'btn-ghost-secondary': value.defaultCompany })}
                      type='button'
                      onClick={() => deleteCompanyClicked && deleteCompanyClicked(value._id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default CompaniesList
