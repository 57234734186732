export const ActionTypes = {
  FOLD: 'sidebar.fold',
  UNFOLD: 'sidebar.unfold',
  SWITCH_VISIBILITY: 'sidebar.switch_visibility'
}

const fold = () => ({ type: ActionTypes.FOLD })
const unfold = () => ({ type: ActionTypes.UNFOLD })
const switchVisibility = () => ({ type: ActionTypes.SWITCH_VISIBILITY })

export const Actions = {
  fold,
  unfold,
  switchVisibility
}
