import { useMutation } from '@apollo/react-hooks'
import { deleteUser } from 'app-gql/user/mutations/deleteUser'

const useDeleteUser = ({ onComplete, refetchQueries }) => {
  return useMutation(deleteUser, {
    onCompleted (data) {
      onComplete && onComplete(data)
    },
    refetchQueries: refetchQueries
  })
}

export default useDeleteUser
