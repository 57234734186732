import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form, FormGroup } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'

import { AVAILABLE_LOCALIZATIONS } from 'app-models/localizations'
import { FormGroupTextField } from 'app-components/forms'
import { validators } from 'app-libs'
import { getAllowedUserTypesFor } from 'app-models/Privacy'
import FormSelectField from 'app-components/forms/FormSelectField'
import useAuthorization from 'app-hooks/useAuthorization'

const UserForm = (
  {
    handleSubmit,
    pristine,
    invalid,
    submitting,
    error,
    disabledFields,
    initialValues,
    setFieldValue,
    values,
    companiesList,
    ...rest
  }
) => {
  const { t } = useTranslation()
  const { user } = useAuthorization()
  const allowedUserTypes = getAllowedUserTypesFor(user.role)

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Field
          name='name'
          autoComplete='new-name'
          type='text'
          label={t('common.labels.user_name')}
          labelClasses='text-muted'
          component={FormGroupTextField}
          validate={[validators.required]}
        />
        {/* USER SECOND NAME */}
        <Field
          name='surname'
          autoComplete='new-surname'
          type='text'
          label={t('common.labels.user_second_name')}
          labelClasses='text-muted'
          component={FormGroupTextField}
          validate={[validators.required]}
        />
        {/* USER ROLE */}
        <Field
          name='role'
          label={t('common.labels.account_type')}
          labelClasses='text-muted'
          mapping={allowedUserTypes.map(role => {
            return {
              label: String(t(`common.labels.${role}`))
                .toUpperCase(),
              value: role
            }
          })}
          component={FormSelectField}
          isMulti={false}
          validate={[validators.required]}
        />
        {/* COMPANY ID */}
        <Field
          name='company'
          label={t('common.labels.company.companyToAdministrate')}
          labelClasses='text-muted'
          mapping={companiesList.map(company => {
            return {
              label: company.name,
              value: company._id
            }
          })}
          component={FormSelectField}
          isMulti={false}
          validate={[validators.required]}
        />
        {/* USER EMAIL */}
        <Field
          name='email'
          autoComplete='new-email'
          type='email'
          label={t('common.labels.email')}
          labelClasses='text-muted'
          component={FormGroupTextField}
          validate={[validators.email, validators.required]}
        />
        {/* TEMP PASSWORD */}
        <Field
          name='password'
          type='password'
          label={t('common.labels.password')}
          labelClasses='text-muted'
          component={FormGroupTextField}
          validate={[validators.required]}
          autoComplete='new-password'
        />
        {/* USER ROLE */}
        <Field
          name='localization'
          label={t('common.labels.language')}
          labelClasses='text-muted'
          mapping={AVAILABLE_LOCALIZATIONS.map(value => {
            return {
              label: String(t(`common.labels.locale.${value}`)),
              value: value
            }
          })}
          component={FormSelectField}
          isMulti={false}
          validate={[validators.required]}
        />

        <FormGroup className='d-flex align-items-center justify-content-between m-0'>
          <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
            {t('common.create')}
          </Button>
        </FormGroup>
      </Form>
    </>
  )
}

export default reduxForm({
  form: 'UserForm'
})(UserForm)
