import React from 'react'
import { connect } from 'react-redux'
import { SidebarActions } from 'app-store'

const SidebarBackdrop = ({ sidebarVisible, switchSidebarVisibility }) => {
  const onClick = () => {
    switchSidebarVisibility()
  }
  return (
    sidebarVisible
      ? <div className="c-sidebar-backdrop c-fade c-show" onClick={ onClick }></div>
      : <div></div>
  )
}

const mapStateToProps = (state) => {
  return {
    sidebarVisible: state.sidebar.visible
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    switchSidebarVisibility: () => {
      dispatch(SidebarActions.sidebar.switchVisibility())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarBackdrop)
