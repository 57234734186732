import { faAddressBook } from '@fortawesome/free-regular-svg-icons'
import { faGripHorizontal, faListAlt, faTable, faUserPlus } from '@fortawesome/free-solid-svg-icons'

import SIDEBAR_ITEM_TYPE from './SidebarItemType'
import { i18n } from 'app-core'
import { PRIVACY_ACTIONS, PRIVACY_TYPE } from 'app-models/Privacy'
import { isAdmin, isClientCompanyAdmin, isCompanyAdmin, isEmployee, isSuperAdmin } from 'app-models/UsersType'

const SidebarData = user => {
  return [
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: 'Arkyreyma SQUARE',
      resolver: () => {
        return true
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.LINK,
      to: '/dashboard',
      text: i18n.t('sidebar.menu.dashboard'),
      faIcon: faGripHorizontal,
      resolver: () => {
        return true
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('sidebar.menu.users'),
      resolver: (user) => {
        if (isSuperAdmin(user.role)) return true
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        if (isSuperAdmin(user.role)) return true
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/admin/users',
          text: i18n.t('sidebar.menu.usersList'),
          faIcon: faAddressBook,
          resolver: (user) => {
            if (isAdmin(user.role)) return true
            return !!user.privacy && user.privacy[PRIVACY_TYPE.USERS].includes(PRIVACY_ACTIONS.VIEW)
          }
        },
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/user/create',
          text: i18n.t('common.create'),
          faIcon: faUserPlus,
          resolver: (user) => {
            if (isAdmin(user.role)) return true
            return !!user.privacy && user.privacy[PRIVACY_TYPE.USERS].includes(PRIVACY_ACTIONS.CREATE)
          }
        }
      ]
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('sidebar.menu.employees'),
      resolver: (user) => {
        if (isCompanyAdmin(user.role)) return true
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (employee) => {
        if (isCompanyAdmin(employee.role)) return true
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/admin/employees',
          text: i18n.t('sidebar.menu.employeesList'),
          faIcon: faAddressBook,
          resolver: (employee) => {
            if (isCompanyAdmin(employee.role)) return true
            return !!employee.privacy && employee.privacy[PRIVACY_TYPE.EMPLOYEES].includes(PRIVACY_ACTIONS.VIEW)
          }
        },
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/employee/create',
          text: i18n.t('common.create'),
          faIcon: faUserPlus,
          resolver: (employee) => {
            if (isCompanyAdmin(employee.role)) return true
            return !!employee.privacy && employee.privacy[PRIVACY_TYPE.EMPLOYEES].includes(PRIVACY_ACTIONS.CREATE)
          }
        }
      ]
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('sidebar.menu.company'),
      resolver: (user) => {
        if (isAdmin(user.role)) return true
        if (isClientCompanyAdmin(user.role) || isEmployee(user.role)) return false
        return !!user.privacy && user.privacy[PRIVACY_TYPE.COMPANIES].includes(PRIVACY_ACTIONS.VIEW)
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        if (isSuperAdmin(user.role)) return true
        if (isEmployee(user.role)) return false
        return !!user.privacy && user.privacy[PRIVACY_TYPE.COMPANIES].includes(PRIVACY_ACTIONS.VIEW)
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/companies',
          text: i18n.t('sidebar.menu.companies_list'),
          faIcon: faTable,
          resolver: (user) => {
            return isSuperAdmin(user.role)
          }
        }
      ]
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        return isCompanyAdmin(user.role)
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.DYNAMIC_LINK,
          to: '/company/edit/' + user.companyId,
          text: i18n.t('common.labels.company.my_company'),
          faIcon: faTable,
          resolver: (user) => {
            return isCompanyAdmin(user.role)
          }
        }
      ]
    },
    {
      type: SIDEBAR_ITEM_TYPE.TITLE,
      text: i18n.t('sidebar.menu.tests'),
      resolver: (user) => {
        if (isAdmin(user.role)) return true
      }
    },
    {
      type: SIDEBAR_ITEM_TYPE.CATEGORY,
      resolver: (user) => {
        if (isAdmin(user.role)) return true
        return !!user.privacy && user.privacy[PRIVACY_TYPE.TESTS].includes(PRIVACY_ACTIONS.VIEW)
      },
      items: [
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/tests',
          text: i18n.t('sidebar.menu.testsList'),
          faIcon: faListAlt,
          resolver: (user) => {
            return (isAdmin(user.role))
          }
        },
        {
          type: SIDEBAR_ITEM_TYPE.LINK,
          to: '/test/groups',
          text: i18n.t('sidebar.menu.testsGroups'),
          faIcon: faListAlt,
          resolver: (user) => {
            return (isCompanyAdmin(user.role))
          }
        }
      ]
    }
  ]
}

export default SidebarData
