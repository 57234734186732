import gql from 'graphql-tag'

export const answerQuestionnaire = gql`
  mutation answerQuestionnaire(
    $id: ID!,
    $questions: [QuestionnaireAnswerInputType]
  ) {
    answerQuestionnaire(
      _id: $id,
      questions: $questions
    ) {
      status
    }
  }
`
