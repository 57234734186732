import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { BreadcrumbItem, CardBody } from 'reactstrap'
import {useMutation, useQuery} from '@apollo/react-hooks'

import { BreadcrumbCommon } from 'app-components'
import BasePage from 'app-layouts/BasePage'
import UserForm from './components/UserForm'
import { GQL } from 'app-gql'
import GenericGraphqlError from 'app-components/errors/GenericGraphqlError'
import HandlerComponent from 'app-components/HandlerComponent'

const CreateUser = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { loading, data: companiesData } = useQuery(GQL.Company.queries.companies)

  const [createUser, { error }] = useMutation(GQL.User.mutations.createUser, {
    onCompleted: ({ createUser }) => {
      if (createUser) {
        const { status } = createUser
        status === 'ok' && history.push('/admin/users')
      }
    },
    refetchQueries: [{ query: GQL.Company.queries.companies }]
  })

  const handleSubmit = useCallback(async (values) => {
    if (createUser) {
      const role = values.role.value
      const users = values.users.map(v => v.value)

      await createUser({
        variables: {
          ...values,
          role,
          users,
          companies: [], // TODO remove. not needed
          companyId: values.company.value,
          localization: values.localization.value
        }
      })
    }
  }, [createUser])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>
          <Link to='/admin/users'>{t('common.page.admin.users')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('common.page.admin.create')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t('common.page.admin.add_new_user')}>
        <CardBody>
          {(error) && (
            <GenericGraphqlError className='b-radius-0' error={error} />
          )}
          <HandlerComponent loading={loading} />
          { companiesData && <UserForm
            onSubmit={handleSubmit}
            initialValues={{
              name: '',
              surname: '',
              email: '',
              password: '',
              users: [],
              stores: [],
              devices: [],
              localization: ''
            }}
            companiesList={companiesData.companiesList}
          /> }
        </CardBody>
      </BasePage>
    </>
  )
}

export default CreateUser
