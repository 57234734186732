import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createRootReducer from './reducers'

function createEnhancer (middlewares) {
  const storeEnhancer = applyMiddleware(...middlewares)
  if (process.env.NODE_ENV === 'development') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(storeEnhancer)
  }

  return storeEnhancer
}

export default function configureStore (initialState, history) {
  const reducer = createRootReducer(history)
  const middlewares = [thunk, routerMiddleware(history)]

  if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger')
    middlewares.push(createLogger())
  }

  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['session', 'form']
  }

  const persistedReducer = persistReducer(persistConfig, reducer)

  const enhancer = createEnhancer(middlewares)
  const store = createStore(persistedReducer, undefined, enhancer)

  // store.asyncReducers = {}
  //
  // store.attachReducer = (key, reducer) => {
  //   store.asyncReducers[key] = reducer
  //   store.replaceReducer(createRootReducer(history, store.asyncReducers))
  //
  //   return store
  // }
  //
  // store.detachReducer = (key) => {
  //   if (key in store.asyncReducers) {
  //     delete store.asyncReducers[key]
  //
  //     store.replaceReducer(createRootReducer(history, store.asyncReducers))
  //   }
  //
  //   return store
  // }

  const persistor = persistStore(store)

  return {
    store,
    persistor
  }
}
