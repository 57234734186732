import React from 'react'

import SimpleError from './SimpleError'
import { i18n } from 'app-core'

const NetworkError = (props) => {
  if (!props.error) return null

  const error = props.error

  const errorMessage = i18n.t(`errors.network.${error.statusCode}`, {
    defaultValue: i18n.t('errors.network.unknown_error')
  })

  return <SimpleError className={props.className} message={errorMessage} />
}

export default NetworkError
