import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { BreadcrumbItem, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import BreadcrumbCommon from "app-components/BreadcrumbCommon";
import BasePage from "app-layouts/BasePage";
import HandlerComponent from "app-components/HandlerComponent";
import CompanyForm from "app-screens/company/components/CompanyForm";
import { configuration } from "app-core";
import { GQL } from "app-gql";
import { useAuthorization } from "app-hooks";
import GenericGraphqlError from "app-components/errors/GenericGraphqlError";
import TestsList from "app-screens/company/components/TestsList";
import { i18n, restApi } from "app-core";
import { saveAs } from "file-saver";
import { getTestTitle } from "app-libs/helpers";

/* CREATE COMPANY PAGE */
const EditCompany = () => {
  const { user } = useAuthorization();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { companyId } = user;

  const [company, setCompany] = useState(null);
  /* FETCH COMPANY DATA */
  const { loading, error, data } = useQuery(GQL.Company.queries.company, {
    variables: { id: id || companyId },
    fetchPolicy: "no-cache",
  });

  const refetchQueries = useMemo(() => {
    const refetchQueriesList = [{ query: GQL.Company.queries.companies }];
    if (companyId === id || !id) {
      refetchQueriesList.push({
        query: GQL.User.queries.me,
      });
    }

    return refetchQueriesList;
  }, [companyId, id]);

  const testsRequest = useQuery(GQL.Test.queries.tests, {
    variables: { companyIds: [id] },
  });

  const [updateCompany, { error: gqlError }] = useMutation(
    GQL.Company.mutations.updateCompany,
    {
      onCompleted: ({ updateCompany }) => {
        if (updateCompany && updateCompany.status === "ok") {
          history.push("/companies");
        }
      },
      refetchQueries,
    }
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (updateCompany) {
        if (typeof values.companyLogo === "string") {
          values.companyLogo = null;
        }

        await updateCompany({
          variables: {
            ...values,
          },
        });
      }
    },
    [updateCompany]
  );

  useEffect(() => {
    if (data) {
      const companyData = { ...data.company };

      companyData.companyLogo = `${configuration.apiUrl}/${
        companyData.companyLogo
      }?rnd=${Math.random()}`; // disabled logo cache

      setCompany(companyData);
    }
  }, [data, user, setCompany]);

  const onCheckResultClick = (testId) => {
    history.push(`/test/results/${testId}?cid=${company._id}`);
  };

  const downloadCsvClicked = (testId) => {
    restApi.ExamTaskRestApiService.downloadExamTaskResultAsCSV(
      testId,
      i18n.instance.language
    ).then((response) => {
      const list =
        testsRequest.data.examTaskList || testsRequest.data.examTaskAdminList;
      const test = list.find((t) => t._id === testId);

      saveAs(response.data, getTestTitle(test));
    });
  };

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>
          {t("common.labels.company.text")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("common.page.admin.edit")}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={t("common.page.admin.edit_company")}>
        <CardBody>
          <HandlerComponent loading={loading} />
          {(error || gqlError) && (
            <GenericGraphqlError
              className="b-radius-0"
              error={error || gqlError}
            />
          )}
          {company && (
            <CompanyForm
              onSubmit={handleSubmit}
              initialValues={company}
              enableReinitialize
              editState
            />
          )}
        </CardBody>
      </BasePage>
      <BasePage title={t("tests.companyTests")}>
        <CardBody>
          <HandlerComponent
            loading={testsRequest.loading}
            error={testsRequest.error}
          />
          {testsRequest.data && (
            <TestsList
              testsData={testsRequest.data.examTaskList}
              onCheckResultClick={onCheckResultClick}
              downloadCsvClicked={downloadCsvClicked}
            />
          )}
        </CardBody>
      </BasePage>
    </>
  );
};

EditCompany.propTypes = {
  user: PropTypes.object,
};

export default EditCompany;
