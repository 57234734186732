import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, Form, FormGroup, Row } from "reactstrap";
import { change, Field, reduxForm } from "redux-form";

import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";

import { validators } from "app-libs";
import { FormGroupTextField, FormNumericList } from "app-components/forms";
import FormImageField from "app-components/forms/FormImageField";
import { IMAGES } from "app-images";
import { FormCheckboxField } from "app-components/forms/FormCheckboxField";
import { isSuperAdmin } from "app-models/UsersType";
import { useAuthorization } from "app-hooks";

const CompanyForm = ({
  pristine,
  invalid,
  submitting,
  handleSubmit,
  error,
  values,
  setFieldValue,
  initialValues,
  editState = false,
}) => {
  const { t } = useTranslation();
  const [companyImage, setCompanyImage] = useState(
    initialValues && !!initialValues.companyLogo
      ? initialValues.companyLogo
      : IMAGES.noImage
  );

  const { user } = useAuthorization();

  return (
    <Form
      className="d-flex flex-column justify-content-between"
      onSubmit={handleSubmit}
    >
      {error && <Alert color="danger">{error.message}</Alert>}
      <Row form>
        <Col>
          <Card>
            <CardBody>
              <Field
                name="name"
                type="text"
                label={t("common.labels.company.name")}
                labelClasses="text-muted"
                component={FormGroupTextField}
                validate={[validators.required]}
              />
              <Row form>
                <Col md={6}>
                  <Field
                    name="email"
                    type="email"
                    label={t("common.labels.company.email")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required, validators.email]}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name="phone"
                    type="text"
                    label={t("common.labels.company.phone")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.phoneNumber, validators.required]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="contactName"
                    type="text"
                    label={t("common.labels.company.contact_name")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required]}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <FormImageField
                src={companyImage}
                onChange={(event) => {
                  setFieldValue(
                    "CompanyForm",
                    "companyLogo",
                    event.currentTarget.files[0]
                  );
                  setCompanyImage(
                    event.target.files[0]
                      ? URL.createObjectURL(event.target.files[0])
                      : IMAGES.noImage
                  );
                }}
              />
            </CardBody>
          </Card>
          {editState && (
            <Card>
              <CardHeader>{t("common.labels.company.admins")}</CardHeader>
              <CardBody>
                <Row form>
                  <Col>
                    <Field
                      name="companyAdmins"
                      component={FormNumericList}
                      items={initialValues.companyAdmins.map((item, index) => {
                        return {
                          index: index,
                          text: item.name + " " + item.surname,
                          url: isSuperAdmin(user.role)
                            ? `/user/edit/${item._id}`
                            : null,
                        };
                      })}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
      <Row form>
        <Col>
          <Card>
            <CardHeader>
              {t("common.labels.company.permissions.name")}
            </CardHeader>
            <CardBody>
              <Row form>
                <Col>
                  <Field
                    name="loginPermitted"
                    label={t("common.labels.company.permissions.login")}
                    component={FormCheckboxField}
                    isEnabled={isSuperAdmin(user.role)}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row form>
        <Col>
          <Card>
            <CardHeader>{t("common.labels.company.address")}</CardHeader>
            <CardBody>
              <Row form>
                <Col>
                  <Field
                    name="address.country"
                    autoComplete="country"
                    type="text"
                    label={t("common.labels.country")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required]}
                  />
                </Col>
                <Col>
                  <Field
                    name="address.region"
                    autoComplete="region"
                    type="text"
                    label={t("common.labels.region")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required]}
                  />
                </Col>
              </Row>
              <Row form>
                <Col>
                  <Field
                    name="address.province"
                    autoComplete="province"
                    type="text"
                    label={t("common.labels.province")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required]}
                  />
                </Col>
                <Col>
                  <Field
                    name="address.zip"
                    autoComplete="zip"
                    type="number"
                    label={t("common.labels.zip")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required, validators.inRange(4, 10)]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="address.address"
                    autoComplete="address"
                    type="textarea"
                    label={t("common.labels.address")}
                    labelClasses="text-muted"
                    component={FormGroupTextField}
                    validate={[validators.required]}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <FormGroup className="d-flex align-items-center justify-content-between m-0">
        <Button
          type="submit"
          color="primary"
          disabled={submitting || pristine || invalid}
        >
          {editState ? t("common.labels.update") : t("common.labels.create")}
        </Button>
      </FormGroup>
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue: (form, field, value) => {
      return dispatch(change(form, field, value));
    },
  };
};

export default reduxForm({
  form: "CompanyForm",
  destroyOnUnmount: false,
})(connect(null, mapDispatchToProps)(CompanyForm));
