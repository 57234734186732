import gql from 'graphql-tag'

export const company = gql`
  query company($id:ID!) {
    company(_id: $id) {
      _id
      name
      address {
        country
        region
        province
        zip
        address
      }
      phone
      email
      contactName
      companyAdmins {
        _id
        name
        surname
        email
        role
      }
      companyLogo
      loginPermitted
    }
  }
`

export const myCompany = gql`
  query myCompany {
    myCompany {
      _id
      name
      address {
        country
        region
        province
        zip
        address
      }
      phone
      email
      contactName
      companyAdmins {
        _id
        name
        surname
        email
        role
      }
      companyLogo
      loginPermitted
    }
  }
`
