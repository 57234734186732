import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'

export default function FormNumericList ({ items }) {
  return (
    <ol className={'pl-3'}>
      {
        items.map((item) => {
          return (
            <li key={`admin-${item.index}`}>
              {item.text}
              {
                item.url ? (
                  <Link to={item.url} className='btn btn-ghost-info'>
                    <FontAwesomeIcon icon={faEdit}/>
                  </Link>
                ) : null
              }
            </li>)
        })
      }
    </ol>
  )
}
