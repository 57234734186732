import React from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { Button, Col, Form, FormGroup, Row } from 'reactstrap'

import { i18n } from 'app-core'
import { validators } from 'app-libs'
import { Heading, Text } from 'app-components'
import { FormTextField } from 'app-components/forms'

const RecoverForm = ({
  handleSubmit,
  pristine,
  invalid,
  submitting
}) => {
  return (
    <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
      <Heading size={3}>{i18n.t('auth.reset_password_text')}</Heading>

      <Row className='mt-2'>
        <Col>
          <Field
            type='email'
            name='email'
            icon='envelope'
            placeholder={i18n.t('screens.login.form.email.placeholder')}
            component={FormTextField}
            validate={[validators.required, validators.email]}
          />

          <FormGroup className='d-flex align-items-center justify-content-between m-0'>
            <Text tag={Link} to='/'>
              &larr;&nbsp;
              {i18n.t('common.labels.back_to_home')}
            </Text>

            <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
              {i18n.t('common.labels.reset_password')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default reduxForm({
  form: 'RecoverForm'
})(RecoverForm)
