import createReducer from './../../../libs/createReducer'
import { ActionTypes } from './actions'

const initialState = {
  foldState: true,
  visible: true
}

export default createReducer(initialState, {
  [ActionTypes.FOLD] (state, action) {
    return {
      ...state,
      foldState: true
    }
  },
  [ActionTypes.UNFOLD] (state, action) {
    return {
      ...state,
      foldState: false
    }
  },
  [ActionTypes.SWITCH_VISIBILITY] (state, action) {
    return {
      ...state,
      visible: !state.visible
    }
  }
})
