import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap'
import { Field, formValueSelector, reduxForm } from 'redux-form'

import { FormSelectField, FormNumericInput } from 'app-components/forms'
import CardHeader from 'reactstrap/lib/CardHeader'
import Card from 'reactstrap/lib/Card'
import { i18n } from 'app-core'
import CardBody from 'reactstrap/es/CardBody'
import { useQuery } from '@apollo/react-hooks'
import { GQL } from 'app-gql'
import { FormRadioButton } from 'app-components/forms/FormRadioButton'
import { validators } from 'app-libs'
import { connect } from 'react-redux'

const AssignCompanyForm = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  error,
  initialValues,
  repeatType
}) => {
  const { t } = useTranslation()

  const { loading, data } = useQuery(GQL.Company.queries.companiesShortInfo)

  const addedCompanies = initialValues.companyIds

  const selectCompanyOptions = useMemo(() => {
    if (!loading && data) {
      let companies = data.companiesList.map(({ _id, name }) => ({ value: _id, label: name }))

      companies = companies.filter(c => {
        const result = addedCompanies.find(addedComp => addedComp.value === c.value)
        return result === null || result === undefined
      })
      return companies.concat(addedCompanies)
    }

    return []
  }, [loading, data, addedCompanies])

  return (
    <>
      <Form className='d-flex flex-column justify-content-between' onSubmit={handleSubmit}>
        {error && <Alert color='danger'>{error.message}</Alert>}
        <Row>
          <Col>
            <h4 className='mb-4'>{i18n.t('tests.assignCompaniesToTest')}</h4>
            <Card>
              <CardHeader>{t('common.labels.company.selectCompany')}</CardHeader>
              <CardBody>
                <Row form>
                  <Col>
                    <Field
                      name='companyIds'
                      labelClasses='text-muted'
                      mapping={selectCompanyOptions || []}
                      component={FormSelectField}
                      isLoading={loading}
                      isMulti
                      validate={[validators.required]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className={'mt-0'}>
              <CardHeader>{t('tests.selectTestRepeatType')}</CardHeader>
              <CardBody>
                <Row form>
                  <Col>
                    <Field
                      name={'repeatType'}
                      type='radio'
                      label={'Infinite'}
                      value={'infinite'}
                      component={FormRadioButton}
                      isEnabled={true}
                      validate={[validators.required]}
                    />
                    <Field
                      name={'repeatType'}
                      type='radio'
                      label={'Max count'}
                      value='maxCount'
                      component={FormRadioButton}
                      isEnabled={true}
                      validate={[validators.required]}
                    />
                    <Field
                      type='number'
                      name='maxCount'
                      component={FormNumericInput}
                      validate={repeatType === 'infinite' ? undefined : [validators.required, validators.number]}
                      disabled={repeatType === 'infinite'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <FormGroup className='d-flex align-items-center justify-content-between m-0'>
          <Button type='submit' color='primary' disabled={submitting || pristine || invalid}>
            {t('common.labels.assign')}
          </Button>
        </FormGroup>
      </Form>
    </>
  )
}

const selector = formValueSelector('AssignCompanyForm')

const mapStateToProps = (state) => {
  return {
    repeatType: selector(state, 'repeatType')
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default reduxForm({
  form: 'AssignCompanyForm'
})(connect(mapStateToProps, mapDispatchToProps)(AssignCompanyForm))
