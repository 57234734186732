import React, { Component, useEffect, useState } from "react";
import { CardBody, Col, Row, Table } from "reactstrap";
import { BasePage } from "app-layouts";
import HandlerComponent from "app-components/HandlerComponent";
import { useQuery } from "@apollo/react-hooks";
import { i18n, restApi } from "app-core";
import { GQL } from "app-gql";
import moment from "moment";
import { BreadcrumbItem } from "reactstrap";
import BreadcrumbCommon from "app-components/BreadcrumbCommon";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";

const TestAggregatedData = ({ history }) => {
  const {
    loading,
    error,
    data: { examTaskAggregationList: examTaskAggregationList } = {}, //: { userExamTaskAggregationList: userExamTaskAggregationList } = {},
  } = useQuery(GQL.User.queries.examTaskAggregationList, {
    fetchPolicy: "no-cache",
  });

  const downloadReport = (fileName) => {
    restApi.ExamAggregateDataApiService.downloadTestReport(fileName).then(
      (response) => {
        saveAs(response.data, fileName);
      }
    );
  };

  const getListItems = (examTaskAggregationList) => {
    return examTaskAggregationList.map((examTaskAggregation) => {
      const createdAt = moment
        .unix(examTaskAggregation.createdAt / 1000)
        .format("DD-MM-YYYY HH:mm:ss");
      return (
        <tr key={examTaskAggregation._id} className="font-sm">
          <td>{examTaskAggregation.fileName}</td>
          <td>{createdAt}</td>
          <td>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-info btn-sm"
                type="button"
                onClick={() => downloadReport(examTaskAggregation.fileName)}
              >
                {i18n.t("tests.aggregateData.labels.download")}
              </button>
            </div>
          </td>
        </tr>
      );
    });
  };

  if (loading) {
    return <HandlerComponent loading />;
  }

  return (
    <div className="mt-3">
      <BasePage title={i18n.t("tests.aggregatedData")}>
        <BreadcrumbCommon>
          <BreadcrumbItem active>{i18n.t("common.page.tests")}</BreadcrumbItem>
          <li className="breadcrumb-menu ml-auto mr-0">
            <div className="btn-group" role="group">
              <Link
                to="/tests/aggregate_data/new"
                className="btn btn-transparent"
              >
                <FontAwesomeIcon className="nav-icon" icon={faUserPlus} />
                <span className="ml-1">
                  {i18n.t("tests.create_aggregate_data")}
                </span>
              </Link>
            </div>
          </li>
        </BreadcrumbCommon>
        <CardBody>
          <Row>
            <Col>
              <Table borderless responsive className="table-hover">
                <thead>
                  <tr>
                    <th>{i18n.t("common.labels.employerResults.title")}</th>
                    {/* <th>
                      {i18n.t("common.labels.employerResults.performedBy")}
                    </th> */}
                    <th>
                      {i18n.t("common.labels.employerResults.performedAt")}
                    </th>
                    <th className="text-center">
                      {i18n.t("common.labels.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>{getListItems(examTaskAggregationList)}</tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </BasePage>
    </div>
  );
};

export default TestAggregatedData;
