import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbItem, CardBody } from 'reactstrap'
import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import BasePage from 'app-layouts/BasePage'
import HandlerComponent from 'app-components/HandlerComponent'
import { i18n } from 'app-core'
import { GQL } from 'app-gql'
import TestForm from 'app-screens/tests/components/TestForm'
import { buildTextLangInputType, extractValuesFromSelect, mapValuesToSelectOptions } from 'app-libs/helpers'

/* EDIT TEST PAGE */
const EditTest = () => {
  const history = useHistory()
  const { id } = useParams()

  const [test, setTest] = useState(null)

  /* FETCH TEST DATA */
  const { loading, error, data } = useQuery(GQL.Test.queries.test, {
    variables: { id: id },
    fetchPolicy: 'no-cache'
  })

  const [updateTest] = useMutation(GQL.Test.mutations.updateTest, {
    onCompleted: ({ updateExamTask }) => {
      if (updateExamTask) {
        const { status } = updateExamTask
        status === 'ok' && (history.push('/tests') || window.location.reload())
      }
    }
  })

  const handleSubmit = useCallback(async (values) => {
    if (updateTest) {
      await updateTest({
        variables: {
          id: values.id,
          shuffle: values.shuffle,
          hideResults: values.hideResults,
          algorithm: extractValuesFromSelect(values.algorithm),
          algorithmAdresses: extractValuesFromSelect(values.algorithmAdresses),
          title: {
            addOrUpdate: buildTextLangInputType(values.title)
          },
          description: {
            addOrUpdate: buildTextLangInputType(values.description)
          }
        }
      })
    }
  }, [updateTest])

  useEffect(() => {
    if (!loading && data) {
      const { examTaskAdmin } = data

      setTest({
        ...examTaskAdmin
      })
    }
  }, [loading, data])

  const formInitialProps = useMemo(() => {
    if (!test) {
      return null
    }

    return {
      id: test._id,
      shuffle: test.shuffle,
      hideResults: test.hideResults,
      algorithm: mapValuesToSelectOptions(test.algorithm),
      algorithmAdresses: mapValuesToSelectOptions(test.algorithmAdresses),
      ...(test.company && {
        companyId: {
          value: test.company._id,
          label: test.company.name
        }
      }),
      title: test.title.reduce((memo, { _id, lang, text }) => {
        memo[lang] = {
          _id,
          text
        }
        return memo
      }, {}),
      description: test.description.reduce((memo, { _id, lang, text }) => {
        memo[lang] = {
          _id,
          text
        }
        return memo
      }, {})
    }
  }, [test])

  return (
    <>
      <BreadcrumbCommon>
        <BreadcrumbItem active>{i18n.t('common.page.tests')}</BreadcrumbItem>
        <BreadcrumbItem active>{i18n.t('common.page.admin.edit')}</BreadcrumbItem>
      </BreadcrumbCommon>
      <BasePage title={i18n.t('tests.editTest')}>
        <CardBody>
          <HandlerComponent loading={loading} error={error} />
          {formInitialProps && <TestForm onSubmit={handleSubmit} initialValues={formInitialProps} enableReinitialize editState />}
        </CardBody>
      </BasePage>
    </>
  )
}

EditTest.propTypes = {}

export default EditTest
