import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AuthorizationActions } from 'app-store'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export default Component =>
  (props) => {
    const [errors, setErrors] = useState(null)
    const dispatch = useDispatch()
    const { search } = useLocation()

    const queryParams = queryString.parse(search)

    const signIn = useCallback(
      async ({ email, password }) => {
        const result = await dispatch(AuthorizationActions.authorization.signIn({ email, password, queryParams }))
        if (result && result.errors) {
          setErrors(result.errors)
        }
      },
      [dispatch]
    )

    return (
      <Component
        {...props}
        signIn={signIn}
        errors={errors}
      />
    )
  }
