import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NavLink as NavLinkRouter } from 'react-router-dom'
import { Badge, NavItem, NavLink } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { faGlobe, faSignOutAlt, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAuthorization } from 'app-hooks'
import UsersType from 'app-models/UsersType'
import { AuthorizationActions, SidebarActions } from 'app-store'
import { ChangeLocaleAlert } from 'app-components/alerts/ChangeLocaleAlert'
import { GQL } from 'app-gql'
import { configuration, i18n } from 'app-core'
import { IMAGES } from 'app-images'

const BaseHeader = ({ signOut, switchSidebarVisibility }) => {
  const { t } = useTranslation()
  const { user: { _id, role } } = useAuthorization()
  const isAdmin = role === UsersType.ADMIN || role === UsersType.COMPANY_ADMIN

  const [changeLocaleMutation] = useMutation(GQL.User.mutations.changeUserLocalization, {
    onCompleted({ changeUserLocalization }) {
      if (changeUserLocalization && changeUserLocalization.status === 'ok') {
        const { localization } = changeUserLocalization.data

        localStorage.setItem('localization', localization)
        i18n.instance.changeLanguage(localization)
      }
    }
  })

  const changeLang = useCallback((locale) => {
    ChangeLocaleAlert(t, locale, async (value) => {
      if (changeLocaleMutation) {
        await changeLocaleMutation({
          variables: {
            localization: value
          }
        })
      }
    })
  }, [changeLocaleMutation, t])

  const { loading, data } = useQuery(GQL.Company.queries.myCompany)

  return (
    <header className='c-header c-header-light c-header-fixed pl-2 pr-2'>
      <img className='c-header-brand' src={IMAGES.mainLogo} alt={'company logo'} height='30px' />
      {!loading && data && data.myCompany && data.myCompany.companyLogo && data.myCompany.companyLogo != 'images/no-image.png' &&
        (<img className='c-header-brand' src={`${configuration.apiUrl}/${data.myCompany.companyLogo}?rnd=${Math.random()}`} alt={'company logo'} height='30px'/>)}
      {/* TOGGLE MD BUTTON */}
      <button
        className='c-header-toggler c-class-toggler mfs-3 d-md-down-none'
        onClick={switchSidebarVisibility}
        type='button'
        data-toggle='sidebar-show'
      >
        <svg className="c-icon c-icon-lg">
          <svg id="cil-menu" viewBox="0 0 24 24">
            <path d="M3.75 4.5h16.5v1.5h-16.5v-1.5z"></path>
            <path d="M3.75 11.25h16.5v1.5h-16.5v-1.5z"></path>
            <path d="M3.75 18h16.5v1.5h-16.5v-1.5z"></path>
          </svg>
        </svg>
      </button>
      {/* TOGGLE LG BUTTON */}
      <button
        className='c-header-toggler c-class-toggler d-lg-none mfe-auto'
        onClick={switchSidebarVisibility}
        type='button'
        data-toggle='sidebar-lg-show'
      >
        <svg className="c-icon c-icon-lg">
          <svg id="cil-menu" viewBox="0 0 24 24">
            <path d="M3.75 4.5h16.5v1.5h-16.5v-1.5z"></path>
            <path d="M3.75 11.25h16.5v1.5h-16.5v-1.5z"></path>
            <path d="M3.75 18h16.5v1.5h-16.5v-1.5z"></path>
          </svg>
        </svg>
      </button>
      {/* BUTTONS MENU */}
      <ul className='c-header-nav ml-auto justify-content-start'>
        <NavItem className="c-header-nav-item">
          <button className='btn btn-link' onClick={() => changeLang(i18n.instance.language)}>
            <FontAwesomeIcon icon={faGlobe} className='info-color'/>
            <Badge color="primary" pill>{i18n.instance.language}</Badge>
          </button>
        </NavItem>
        <NavItem>
          <NavLink to={`/user/edit/${_id}`} tag={NavLinkRouter}>
            <FontAwesomeIcon icon={faUserEdit} className='info-color'/>
          </NavLink>
        </NavItem>
        <NavItem className="c-header-nav-item">
          <NavLink to="javascript:;" className="btn" onClick={signOut}>
            <FontAwesomeIcon icon={faSignOutAlt} className='danger-color'/>
          </NavLink>
        </NavItem>

      </ul>
    </header>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: async () => dispatch(AuthorizationActions.authorization.signOut()),
    switchSidebarVisibility: () => {
      dispatch(SidebarActions.sidebar.switchVisibility())
    }
  }
}

export default connect(null, mapDispatchToProps)(BaseHeader)
