import gql from 'graphql-tag'

export const employeesList = gql`
  query employeesList{
    employeesList {
      _id
      firstName
      lastName
      email,
      companyId
    }
  }
`
