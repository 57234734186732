import gql from 'graphql-tag'

export const editUser = gql`
  mutation updateUser(
    $_id:String!,
    $name:String,
    $surname:String,
    $email:String,
    $role:RoleEnum,
    $localization: String!
    $companyId: ID
  ) {
    updateUser(
      _id:$_id,
      name:$name,
      surname:$surname,
      email:$email,
      role:$role,
      localization: $localization,
      companyId: $companyId
    ) {
      status
    }
  }
`
