import * as React from 'react'
import cx from 'classnames'

import { i18n } from 'app-core'

const MessageAlert = ({ className, message }) => (
  <div
    className={cx('alert alert-success text-center', className)}
    role='alert'
  >{i18n.t(message)}</div>
)

export default MessageAlert
