import { ApolloLink } from 'apollo-link'
import { getAuthorizationToken } from 'app-core/storeProxy'

const initAuthLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      const token = getAuthorizationToken()

      return {
        headers: {
          ...headers,
          ...(token ? { authorization: `Bearer ${token}` } : null)
        }
      }
    })
    return forward(operation)
  })
}

export { initAuthLink }
