import { Actions } from './actions'
import reducer from './reducers'

export const SessionActions = {
  session: Actions
}

export const sessionReducer = {
  session: reducer
}
