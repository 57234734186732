import React from 'react'

import BreadcrumbCommon from 'app-components/BreadcrumbCommon'
import { useAuthorization } from 'app-hooks'
import { isEmployee } from 'app-models/UsersType'
import Tests from 'app-screens/tests'

const Dashboard = () => {
  const { user } = useAuthorization()

  if (isEmployee(user.role)) {
    return <Tests />
  }

  return (
    <>
      <BreadcrumbCommon />
    </>
  )
}

export default Dashboard
