import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const TooltipWrapper = ({ buttonId, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <Tooltip placement="top" isOpen={tooltipOpen} target={buttonId} toggle={toggle}>
      { tooltipText }
    </Tooltip>
  )
}

export default TooltipWrapper
