import gql from 'graphql-tag'

export const generateAndStartQuestionnaire = gql`
  mutation generateAndStartQuestionnaire(
    $examTaskId: ID!
  ) {
    generateAndStartQuestionnaire(
      examTaskId: $examTaskId
    ) {
      questionnaire {
        _id
        answerType
        description {
          lang
          text
        }
        questions {
          _id
          calcType
          answers {
            _id
            description {
              lang
              text
            }
          }
          description {
            lang
            text
          }
          title {
            lang
            text
          }
          type
        }
        title {
          lang
          text
        }
      }
      status
    }
  }
`
