import React, { Component } from "react";
import { connect } from "react-redux";
import { GQL } from "app-gql";
import { Apollo, i18n } from "app-core";
import HandlerComponent from "app-components/HandlerComponent";
import { Button, CardBody } from "reactstrap";
import BasePage from "app-layouts/BasePage";
import TestType from "app-models/TestType";
import { Heading } from "app-components";
import { getTestDescription, getTestTitle } from "app-libs/helpers";
import TestEditorForm from "app-screens/tests/components/TestEditorForm";
import AnswersType from "app-models/AnswersType";
import queryString from "query-string";
import MessageAlert from "app-components/alerts/MessageAlert";
import swal from "@sweetalert/with-react";

class TestsPassing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionnaire: null,
      history: props.history,
      submitTestBtnDisabled: true,
      questions: null,
    };
  }

  async componentDidMount() {
    const { match, location } = this.props;
    const {
      params: { id },
    } = match;
    const { search } = location;

    const queryParams = queryString.parse(search);

    if (search && queryParams.c) {
      await Apollo.mutation({
        query: GQL.Employees.mutations.assignExamTaskById,
        variables: {
          examTaskId: id,
        },
      });
    }

    const { data: generateData } = await Apollo.mutation({
      query: GQL.Test.mutations.generateAndStartQuestionnaire,
      variables: {
        examTaskId: id,
      },
    });

    if (
      generateData.generateAndStartQuestionnaire &&
      generateData.generateAndStartQuestionnaire.status === "ok"
    ) {
      const questions = [];

      generateData.generateAndStartQuestionnaire.questionnaire.questions.forEach(
        (q) => {
          questions.push({
            questionId: q._id,
            answerIds: [],
            text: [],
            questionType: q.type,
          });
        }
      );

      this.setState({
        questionnaire: generateData.generateAndStartQuestionnaire.questionnaire,
        questions: questions,
      });
    } else {
      swal(
        i18n.t("alerts.errors"),
        i18n.t("alerts.cannot_edit_questionnaire"),
        "error"
      ).then((value) => {
        this.state.history.push("/dashboard");
      });
    }
  }

  renderContent() {
    const { questionnaire } = this.state;

    if (!questionnaire) {
      return <HandlerComponent loading />;
    }
    return (
      <Heading className="mb-1">
        <h2>{getTestTitle(questionnaire)}</h2>
        <h4>{getTestDescription(questionnaire)}</h4>
        {questionnaire.answerType === TestType.ALL
          ? this.renderAllQuestions()
          : this.renderOneQuestion()}
      </Heading>
    );
  }

  renderAllQuestions() {
    const { questionnaire, submitTestBtnDisabled } = this.state;

    return (
      <div>
        <TestEditorForm
          initialValues={{
            testId: questionnaire._id,
            questions: questionnaire.questions,
            shuffle: false,
          }}
          onAnswerClicked={this.onAnswerClicked.bind(this)}
          submitTestBtnDisabled={submitTestBtnDisabled}
        />
        <Button
          color="primary"
          disabled={submitTestBtnDisabled}
          onClick={this.handleSubmit.bind(this)}
        >
          {i18n.t("common.submit")}
        </Button>
      </div>
    );
  }

  renderOneQuestion() {
    return <div>renderOneQuestion</div>;
  }

  onAnswerClicked(data) {
    const questions = [...this.state.questions];

    questions.forEach((q) => {
      if (q.questionId === data.questionId) {
        switch (q.questionType.toUpperCase()) {
          case AnswersType.ONE_ANSWER:
            q.answerIds = [data.answerId];
            break;
          case AnswersType.MULTIPLE_ANSWERS:
            if (data.checked) {
              q.answerIds.push(data.answerId);
            } else {
              const index = q.answerIds.indexOf(data.answerId);
              q.answerIds.splice(index, 1);
            }
            break;
          case AnswersType.TEXT_ANSWER:
            q.text = [""];
            break;
        }
      }
    });
    let answersSet = 0;

    questions.forEach((q) => {
      if (q.questionType.toUpperCase() === AnswersType.TEXT_ANSWER) {
        if (q.text.length > 0) {
          answersSet++;
        }
      } else {
        if (q.answerIds.length > 0) {
          answersSet++;
        }
      }
    });

    this.setState({
      questions: questions,
      submitTestBtnDisabled: answersSet < questions.length,
    });
  }

  async handleSubmit(values) {
    const { questionnaire, questions } = this.state;

    const { data, errors } = await Apollo.mutation({
      query: GQL.Test.mutations.answerQuestionnaire,
      variables: {
        id: questionnaire._id,
        questions: questions.map((q) => {
          const question = { ...q };
          delete question.questionType;
          return question;
        }),
      },
    });

    if (errors) {
      swal(
        i18n.t("alerts.warning"),
        i18n.t("alerts.invalid_questionnaire"),
        "warning"
      ).then((value) => {
        this.state.history.push("/dashboard");
      });
    } else {
      if (
        data &&
        data.answerQuestionnaire &&
        data.answerQuestionnaire.status === "finished"
      ) {
        this.state.history.push("/test/result/" + questionnaire._id);
      }
    }
  }

  render() {
    return (
      <div className="mt-3">
        <BasePage title="Test Passing">
          <CardBody>{this.renderContent()}</CardBody>
        </BasePage>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TestsPassing);
