import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import cssModule from './Heading.module.scss'
import HeadingIcon from './HeadingIcon'

const Heading = props => {
  const { tag: Tag, size, className, sub, icon, children, ...attributes } = props
  const headingIcon = icon && (<HeadingIcon icon={icon} classNames={cssModule.HeadingIcon} />)

  const classes = cx(`h${size}`, 'd-block', className)

  return (
    <Tag {...attributes} className={cx(cssModule.Heading, classes)}>
      {headingIcon}
      {children}
    </Tag>
  )
}

Heading.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.string
}

Heading.defaultProps = {
  tag: 'div'
}

export default Heading
