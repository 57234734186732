import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Modal
} from 'reactstrap'
import AssignCompanyForm from 'app-screens/tests/components/AssignCompanyForm'

const AssignCompanyFormModal = (props) => {
  const {
    title,
    isOpen,
    toggle,
    onSubmit,
    onCancel,
    testData
  } = props

  const onClose = () => {
    onCancel && onCancel()
    toggle()
  }
  const [test, setTest] = useState(null)

  useEffect(() => {
    if (testData) {
      const data = { ...testData }
      const { companyIds } = data

      data.companyIds = companyIds.map(({ _id, name }) => ({ value: _id, label: name }))
      data.repeatType = data.infinite ? 'infinite' : 'maxCount'

      if (!data.maxCount) {
        data.maxCount = 1
      }
      setTest(data)
    }
  }, [testData])

  return (
    <Modal isOpen={isOpen} toggle={onClose} style={{ minWidth: '800px' }}>
      <Card className="mb-0">
        <CardHeader className='d-flex align-items-center'>
          <i className='fa fa-align-justify' />
          <span>{title}</span>
          <button className='close ml-auto' onClick={onClose}>
            <span aria-hidden>×</span>
          </button>
        </CardHeader>
        <CardBody>
          {test && <AssignCompanyForm onSubmit={onSubmit} initialValues={test} enableReinitialize editState /> }
        </CardBody>
      </Card>
    </Modal>
  )
}

export default AssignCompanyFormModal
