import gql from 'graphql-tag'

export const companies = gql`
  query companiesList($search: String) {
    companiesList(search: $search) {
      _id
      name
      address {
        country
        region
        province
        zip
        address
      }
      email
      contactName
      companyAdmins {
        _id
        name
        surname
        email
        role
      }
      loginPermitted
    }
  }
`

export const companiesShortInfo = gql`
  query companiesList {
    companiesList {
      _id
      name
    }
  }
`
