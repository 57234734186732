import React from 'react'
import { Redirect, Route, useLocation, withRouter } from 'react-router-dom'
import { useAuthorization } from 'app-hooks'
import { Container, Alert, Row, Col } from 'reactstrap'

// TODO: Refactor: do not nest ternary operator
const PrivateRoute = ({
  component: Component, layout: Layout, container, allowedRoles, privacyObject, privacyAction, ...rest
}) => {
  const { user, isAuthenticated } = useAuthorization()
  const location = useLocation()

  let allowed = isAuthenticated && user && (Array.isArray(allowedRoles)
    ? allowedRoles.includes(user.role)
    : allowedRoles === 'all')

  if (user && privacyObject && privacyAction && !allowed) {
    allowed = user.privacy && user.privacy[privacyObject].includes(privacyAction)
  }

  const queryParams = location.search && (location.search + '&t=' + rest.computedMatch.params.id)

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          // eslint-disable-next-line no-nested-ternary
          !isAuthenticated ? (
            <Redirect to={{
              pathname: '/',
              search: queryParams,
              state: { from: props.location }
            }}
            />
          ) : (
          // eslint-disable-next-line no-nested-ternary
            allowed ? (
              Layout === undefined ? (
                <Component {...props} />
              ) : (
                <Layout container={container}><Component {...props} /></Layout>
              )
            ) : (
              <Layout container={container}>
                <Container>
                  <Row className='justify-content-center'>
                    <Col md='12'>
                      <Alert color='danger'>FORBIDDEN</Alert>
                    </Col>
                  </Row>
                </Container>
              </Layout>
            )
          )
        )
      }}
    />
  )
}

PrivateRoute.defaultProps = {
  allowedRoles: 'all'
}

export default withRouter(PrivateRoute)
