import gql from 'graphql-tag'

export const updateExamTaskGroupAssignment = gql`
  mutation updateExamTaskGroupAssignment(
    $description: UpdateLangInputType,
    $title: UpdateLangInputType,
    $examTaskIds: [ID],
    $userIds: [ID]
    $id: ID
  ) {
    updateExamTaskGroupAssignment(
      _id: $id,
      description: $description,
      title: $title,
      userIds: $userIds,
      examTaskIds: $examTaskIds
    ) {
      status
      groupAssignment {
        _id
      }
    }
  }
`
