import gql from 'graphql-tag'

export const createCompany = gql`
  mutation createCompany(
    $name: String!,
    $address: AddressInputType,
    $email: String!,
    $phone: String!,
    $contactName: String!,
    $companyLogo: Upload,
    $loginPermitted: Boolean
  ) {
    createCompany(
      name: $name,
      address: $address,
      email: $email,
      phone: $phone,
      contactName: $contactName,
      companyLogo: $companyLogo,
      loginPermitted: $loginPermitted
    ) {
      status
    }
  }
`
