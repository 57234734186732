import gql from 'graphql-tag'

export const resetQuestionnaireCounter = gql`
    mutation resetQuestionnaireCounter(
        $userIds: [ID],
        $examTaskIds: [ID],
        $groupAssignmentIds: [ID]
    ) {
        resetQuestionnaireCounter(
            userIds: $userIds,
            examTaskIds: $examTaskIds,
            groupAssignmentIds: $groupAssignmentIds
        ) {
            status
        }
    }
`
