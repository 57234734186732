import React from 'react'
import { Table } from 'reactstrap'
import { i18n } from 'app-core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faEdit, faEject } from '@fortawesome/free-solid-svg-icons'
import TooltipWrapper from 'app-components/TooltipWrapper'

const getListItems = (groups, onDeleteClicked, onResetClicked) => {
  const { language } = i18n.instance

  return groups.map((value) => {
    const titleTranslation = value.title.find(({ lang }) => lang === language)
    const descriptionTranslation = value.description.find(({ lang }) => lang === language)

    return (
      <tr key={value._id} className='font-sm'>
        <td>{titleTranslation && titleTranslation.text}</td>
        <td>{descriptionTranslation && descriptionTranslation.text}</td>
        <td>
          <div className='d-flex justify-content-center'>
            <Link to={`/test/groups/edit/${value._id}`} className='btn btn-ghost-info' >
              <FontAwesomeIcon icon={faEdit}/>
            </Link>
            <button
              className='btn btn-ghost-danger ml-3'
              type='button'
              onClick={ () => onDeleteClicked(value._id) }
              disabled={false}
            >
              <FontAwesomeIcon icon={faTrashAlt}/>
            </button>
            <button
              id='btnResetCountGroup'
              className='btn btn-ghost-danger ml-3'
              type='button'
              onClick={ () => onResetClicked(value._id) }
              disabled={false}
            >
              <FontAwesomeIcon icon={faEject}/>
              <TooltipWrapper buttonId='btnResetCountGroup' tooltipText={i18n.t('tests.resetCount')}/>
            </button>
          </div>
        </td>
      </tr>
    )
  })
}

const TestGroupsList = ({ groups, onDeleteClicked, onResetClicked }) => {
  return (
    <Table borderless responsive className='table-hover'>
      <thead>
        <tr>
          <th>Title</th>
          <th>Description</th>
          <th className='text-center'>Action</th>
        </tr>
      </thead>
      <tbody>
        { getListItems(groups, onDeleteClicked, onResetClicked) }
      </tbody>
    </Table>
  )
}

export default TestGroupsList
