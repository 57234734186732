import React, { useCallback, useMemo } from 'react'
import { Card, CardBody, CardHeader, Modal } from 'reactstrap'
import QuestionForm from 'app-screens/tests/components/QuestionForm'
import { langReducer } from 'app-libs/helpers'

const QuestionFormModal = (props) => {
  const {
    title,
    isOpen,
    toggle,
    onSubmit,
    onCancel,
    addressees,
    initialValues
  } = props

  const onClose = useCallback(() => {
    onCancel && onCancel()
    toggle()
  }, [onCancel, toggle])

  const formInitialProps = useMemo(() => {
    if (!initialValues) {
      return null
    }

    return {
      ...initialValues,
      objective: { value: initialValues ? initialValues.calcType : '' },
      questionType: { value: initialValues ? initialValues.type : '' },
      title: langReducer(initialValues.title),
      resultExplanation: langReducer(initialValues.resultExplanation)
    }
  }, [initialValues])

  return (
    <Modal isOpen={isOpen} toggle={onClose} style={{ minWidth: '800px' }}>
      <Card className="mb-0">
        <CardHeader className='d-flex align-items-center'>
          <span>{title}</span>
          <button className='close ml-auto' onClick={onClose}>
            <span aria-hidden>×</span>
          </button>
        </CardHeader>
        <CardBody>
          <QuestionForm
            addressees={addressees}
            initialValues={formInitialProps}
            editState={initialValues && initialValues._id}
            onSubmit={onSubmit}/>
        </CardBody>
      </Card>
    </Modal>
  )
}

export default QuestionFormModal
