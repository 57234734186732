import gql from 'graphql-tag'

export const signIn = gql`
  mutation signIn($email: String!, $password: String! ) {
    signIn(email: $email, password: $password) {
      token
      refreshToken
      localization
    }
  }
`
