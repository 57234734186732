import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { ConnectedRouter } from 'connected-react-router'
import { any } from 'prop-types'

import ApplicationProvider from './ApplicationProvider'
import Application from './Application'
import { useTranslation } from 'react-i18next'

const ApplicationPropTypes = {
  history: any.isRequired,
  store: any.isRequired,
  client: any.isRequired
}

export default function Main (props) {
  const { history, client, store } = props
  useTranslation()

  return (
    <ApolloProvider client={client}>
      <ApplicationProvider store={store}>
        <ConnectedRouter history={history}>
          <Application />
        </ConnectedRouter>
      </ApplicationProvider>
    </ApolloProvider>
  )
}

Main.propTypes = ApplicationPropTypes
